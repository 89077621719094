import React, { useState } from "react";
import Header from "./elements/header";
import Footer from "./elements/footer";
import SidebarMenu from "./elements/sidebar-menu";
import BoxRecords from "../pages/home/box-records";

function Layout(props: any) {
  const [searchTerm,      setSearchTerm]      = useState('');
  const [isSearch,        setIsSearch]        = useState<boolean>(false);
  const [isSearchClicked, setIsSearchClicked] = useState(0);
  let   [classNameValue,  setClassNameValue]  = useState<boolean>(false);
  let userData                                = localStorage.getItem('profileData');

  const handleDrawerOpen    = ()                 => { setClassNameValue(!classNameValue); }
  const SearchBoxRecords    = (keyword    : any) => { setSearchTerm(keyword); }
  const searching           = (isSearching: any) => { setIsSearch(isSearching); }
  const isSearchIconClicked = (isClicked  : any) => { 
    if(isClicked === true){
      setIsSearchClicked(isSearchClicked+1);
    }else{ 
      setIsSearchClicked(0);
    } 
  }

//-----------------------------------------------------------------//  

return (
  <>
    <div className={!userData ? `landing-page-container` : `inner-page-container`}>
      <Header handleDrawerOpen={handleDrawerOpen} search={SearchBoxRecords} searching={searching} isSearchIconClicked={isSearchIconClicked}/>
      <div className={!userData ? `landingPage` : `homepage innerpage pb-5`}>
        {userData ? <SidebarMenu classNameValue={classNameValue} handleDrawerOpen={handleDrawerOpen} searching={searching} isSearchIconClicked={isSearchIconClicked}/> : <></>}
        <div className={userData ? `rightWrap landingPage` : ``}>
          {
            isSearchClicked !== 0 ?
              <BoxRecords keyword={searchTerm} isClicked={isSearchClicked}/>  
          :
            isSearch ?
              <BoxRecords keyword={searchTerm} searching={searching}/>
          : 
            <>
              {props.children}
            </>  
          }
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default (Layout);
