import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Form, Modal, Row, Stack, Table, } from "react-bootstrap";
import { BsEyeFill, BsPencilFill, BsTrashFill } from "react-icons/bs";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Layout from "../../../layout/Admin/layout";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertFromRaw } from 'draft-js'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { blogAdminValidation } from "../../../validations/adminPage/blogAdmin-form";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { stateToHTML } from "draft-js-export-html";
import { addAdminBlog, deleteAdminBlog, getBlogDetailById, getBlogList, updateAdminBlog } from "../../../redux/actions/admin/adminBlogAction";
import { BASE_URLS } from "../../../redux/constant";
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';

const AdminBlog = (props: any) => {
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [updateimage, setUpdateImage] = useState<any>({ preview: "", raw: "" });
  const [profileImage, setProfileImage] = React.useState("");
  const[updateBlogImage,setUpdateBlogImage]=useState({ preview: "", raw: "" });
  const [updateprofileImage, setUpdateProfileImage] = React.useState("");
  const navTo = useNavigate();
  const [adminDetail, setAdminDetail] = useState([]);
  const [dropdownSelected, setDropdownSelected] = useState('Select');
  const [updateDropdownSelected, setUpdateDropdownSelected] = useState('');
  const [upDropdownSelected, setUpDropdownSelected] = useState('Select');
  const [show, setShow] = useState(false);
  const [showPc, setShowPc] = useState(false);
  const [promocode, setPromocode] = useState('');
  const [loader, setLoader] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [editorState, setEditorState] = useState<any>('');
  const [updateeditorState, setUpdateEditorState] = useState<any>(EditorState.createEmpty());
  const [descriptionText, setDescriptionText] = useState<any>('');
  const [updatedescriptionText, setUpdateDescriptionText] = useState<any>('');
  const [readMoreContent_forHeading, setReadMoreContent_forHeading] = useState('');
  const[readMoreContent_forDescription,setReadMoreContent_forDescription]=useState('')
  const[blogIndex,setBlogIndex]=useState(null);
  const handleClose = () => {
    setShow(false);
    setNewPlan({ blogHeading: "", blogOrder: ""});
    setDropdownSelected("Select");
    setImage({ preview: "", raw: "" })
    setProfileImage('')
    setEditorState("")
    setDescriptionText("")
    setCheckValidation({ blogHeading: false ,blogOrder: false });
  };

  
  const readMore = (type:any,value:any,index:any) =>{
    setReadMoreContent_forHeading("")
    setReadMoreContent_forDescription("")
    setBlogIndex(null);
    if(type === 'heading'){ setReadMoreContent_forHeading(value);setBlogIndex(index)}
    if(type==='description'){setReadMoreContent_forDescription(value );setBlogIndex(index)}
    
  }

// READ LESS ACTION -----------------------  
  const readLess = (type:any,index:any) => {
    setBlogIndex(null);
    //setBlogIndex(index)
    if(type === 'heading'){ setReadMoreContent_forHeading(''); setBlogIndex(index) }
    if(type==='description'){setReadMoreContent_forDescription(''); setBlogIndex(index)}
  }
  const handleClosePc = () => {
    setPromocode('')
    setShowPc(false)
    setCopySuccess('')
  }
  const handleShow = () => {
    setIsValid(false)
    setShow(true);
    //setChecked(false);
  };

  const handleEditorChange = (newEditorState: any) => {
    setEditorState(newEditorState);
    const htmlContent = convertToHTML(newEditorState.getCurrentContent());

    //setDescriptionText(newEditorState.getCurrentContent().getPlainText()); // Assuming you want plain text content
    setDescriptionText(htmlContent)
  }
  const handleUpdateEditorChange = (newEditorState: any) => {
    setUpdateEditorState(newEditorState);
     const htmlContent = convertToHTML(newEditorState.getCurrentContent());
     setUpdateDescriptionText(htmlContent)
    //setUpdateDescriptionText(newEditorState.getCurrentContent().getPlainText()); // Assuming you want plain text content
  }

  //const handleShow = () => {setShow(true)};
  let [isValid, setIsValid] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [disabledCheck, setDisabledCheck] = useState(true);
  const [addedFolderResult, setAddedFolderResult] = useState(false);
  const [folderDetailResult, setFolderDetailResult] = useState(false);
  const [updatedFolderResult, setUpdatedFolderResult] = useState(false);
  const [deleteFolderResult, setDeleteFolderResult] = useState(false);
  const [newPlan, setNewPlan] = useState({ blogHeading: "",blogOrder: ""});
  const [updatePlan, setUpdatePlan] = useState({ blogHeading: "", blogOrder: ""});
  const [viewModalDetails, setViewModalDetails] = useState<any>({ marketerName: "", marketerPassphrase: "", marketerPhone: "" });
  let [errors, setErrors] = useState<any>({ blogHeading: "",blogOrder: ""});
  let [checkValidation, setCheckValidation] = useState<any>({ blogHeading: false, blogOrder: false });

  useEffect(() => {
    props.getBlogList();
  }, []);

  useEffect(() => {
    if (props.blogListData) {
      setAdminDetail(props.blogListData.blogs);
    }
  }, [props.blogListData]);

  useEffect(() => {
    if (props.addAdminBlogData) {
      handleAddPopup();
    }
  }, [props.addAdminBlogData]);



  const handleAddPopup = () => {
    if (addedFolderResult) {
      setLoader(false);
      Swal.fire({
        title: props.addAdminBlogData.message? props.addAdminBlogData.message:"Blog Added Successfully",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "green",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setShow(false);
          props.getBlogList();
          setAddedFolderResult(false);
          setNewPlan({ blogHeading: "", blogOrder: "" });
          setDropdownSelected("Select");
          setImage({ preview: "", raw: "" })
          setProfileImage("")
          setEditorState("")
          setDescriptionText("")
          setCheckValidation({
            blogHeading: false,
            blogOrder: false,
          });
        }
      });
      setAdminDetail(props.blogListData.blogs);
    }
  };
  const handleUpdateChange = (event: any) => {
    if (event.target.innerHTML) {
      setUpdateDropdownSelected(event.target.innerHTML)
    }
    setCheckValidation({ ...checkValidation, [event.target.name]: true, });
    setUpdatePlan({ ...updatePlan, [event.target.name]: event.target.value, });
  };

  const handleChange = (event: any) => {
    if (event.target.innerHTML) {
      setDropdownSelected(event.target.innerHTML);
    }

    if (event.target.name == 'mAccountType') {
      setCheckValidation({ ...checkValidation, [event.target.name]: true, });
      setNewPlan({
        ...newPlan, [event.target.name]: event.target.innerHTML,
      });
    }
    else {
      setCheckValidation({ ...checkValidation, [event.target.name]: true, });
      setNewPlan({ ...newPlan, [event.target.name]: event.target.value, });
    }
  };

  // useEffect(() => {
  //   const validationErrors = blogAdminValidation(newPlan);
  //   isValid = Object.keys(validationErrors).length === 0;
  //   if (newPlan.mPayfastPassphrase) {
  //     if (parseInt(newPlan.mPayfastPassphrase) > 0 && newPlan.mPayfastPassphrase <= (formDataArray.length + 1)) {
  //       setIsValid(true);
  //     }
  //     else {
  //       setCheckValidation((prevCheck: any) => ({
  //         ...prevCheck,
  //         mPayfastPassphrase: true,
  //         blogHeading: false,
  //       }));

  //       setBlogError(true)
  //       setIsValid(false);
  //     }
  //   }
  //   else {
  //     setBlogError(false)
  //     setIsValid(isValid);
  //   }

  //   setErrors(validationErrors);
  // }, [newPlan]);

  useEffect(() => {
    const validationErrors = blogAdminValidation(newPlan);
    isValid = Object.keys(validationErrors).length === 0;
  
    setIsValid(isValid);
    setErrors(validationErrors);
  }, [newPlan]);
  useEffect(() => {
    const updateValidationErrors = blogAdminValidation(updatePlan);
    isValid = Object.keys(updateValidationErrors).length === 0;
    
    setIsValid(isValid);
    setErrors(updateValidationErrors);
  }, [updatePlan, upDropdownSelected]);

  const handleViewModalClose = () => {
    setCheckValidation({ blogHeading: false,  blogOrder: false });
    setFolderDetailResult(false);
    setViewModalShow(false);
    setViewModalOpen(false);
  };

  const handleViewModalShow = (e: any) => {
    setDisabledCheck(true);
    openModal(e);
  };

  const handleUpdatePlanShow = (e: any) => {
     setDisabledCheck(false);
    openModal(e);
  };



  useEffect(() => {
    if (props.getBlogDetailByIdData) {
      const BlogDetail=props.getBlogDetailByIdData?props.getBlogDetailByIdData:''
      // view
      viewModalDetails.blogHeading = BlogDetail.heading ? BlogDetail.heading : "";
      viewModalDetails.blogOrder= BlogDetail.order ? BlogDetail.order : "";
      viewModalDetails.description=BlogDetail.description
  
        updatePlan.blogHeading = BlogDetail.heading ? BlogDetail.heading : "";
        updatePlan.blogOrder = BlogDetail.order ? BlogDetail.order : "";
        const contentBlock = htmlToDraft( BlogDetail.description);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        // const htmlContent = convertToHTML(BlogDetail.description.getCurrentContent());
        // console.log(htmlContent)
      // if(BlogDetail.description){  const editorState=EditorState.createWithContent(ContentState.createFromText(
      //     BlogDetail.description
      //   ))}
        setUpdateEditorState(editorState);
        setUpdateDescriptionText(BlogDetail.description); // Assuming you want plain text content

        setUpdateDropdownSelected(BlogDetail.category)
        setUpdateProfileImage(BlogDetail.image)
      setIsValid(true)
      if (folderDetailResult ) {
       
        setViewModalOpen(true);
        setViewModalShow(true);
      }
    }

  }, [props.getBlogDetailByIdData]);

  const openModal = (e: any) => {
    let marketerId = e;

    // api call for particular marketer id
    props.getBlogDetailById(marketerId);
    setFolderDetailResult(true);
 
  };

  const handleUpdateSubmit = (e: any) => {
    console.log(updatePlan)
    e.preventDefault();
    const currentDate = new Date();
    const createdDateText = currentDate.toISOString(); 
    let formUpdateData=new FormData()
    

      let updateId = (props.getBlogDetailByIdData.id);
    
    formUpdateData.append("heading", updatePlan.blogHeading)
    formUpdateData.append("order", updatePlan.blogOrder)
    formUpdateData.append("category", updateDropdownSelected)
    formUpdateData.append("description", updatedescriptionText);
    formUpdateData.append("created_date", createdDateText);
    formUpdateData.append("updated_date", createdDateText);
    formUpdateData.append("is_active",String(true));
    formUpdateData.append("id",updateId);
    if(updateBlogImage.raw!="")
    {
     
      formUpdateData.append("image", updateBlogImage.raw);
    }
   
   
     props.updateAdminBlog(formUpdateData);
     setUpdateBlogImage({ preview: "", raw: "" })
     setUpdatedFolderResult(true);
    //setChecked(false)
    setLoader(true);
  
  };

  useEffect(() => {
    if (props.updateAdminBlogData && updatedFolderResult) {
      setLoader(false);
      Swal.fire({
        title: props.updateAdminBlogData.message,
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "green",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          props.getBlogList();
          setUpdatedFolderResult(false);
          handleViewModalClose();
        }
      });
    }
  }, [props.updateAdminBlogData]);

  const handleRemoveFolder = (e: any) => {
    let deleteMarketerId = e;
    // let removeMarketerName = name;
    Swal.fire({
      title: `Do you want to remove this Blog? `,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
      confirmButtonColor: "green",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteAdminBlog({ id: deleteMarketerId });
        setLoader(true);
        setDeleteFolderResult(true);
      }
    });
  };


  useEffect(() => {
    if (props.deleteAdminBlogData && deleteFolderResult) {
      setLoader(false);
      let deleteResult = props.deleteAdminBlogData.message;
      Swal.fire({
        title: deleteResult,
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "green",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          props.getBlogList();
          setDeleteFolderResult(false);
        }
      });
    }
  }, [props.deleteAdminBlogData]);


  let result: any;

  const handleSubmit = async (e: any) => {
    const currentDate = new Date();
const createdDateText = currentDate.toISOString(); 
    e.preventDefault();
    const formData = new FormData();
    formData.append("heading", newPlan.blogHeading)
    formData.append("order", newPlan.blogOrder)
    formData.append("category", dropdownSelected)
    formData.append("description", descriptionText);
    formData.append("image", image.raw);
    formData.append("is_active",String(true));
    formData.append("created_date", createdDateText);
formData.append("updated_date", createdDateText);
  

    if (true) {
      setUpdateImage({
        preview: image.preview,
        raw: image.raw,
      })
      props.addAdminBlog(formData);
      setLoader(true);
      // setDiscountApplied(false)
      // setChecked(false)
      setAddedFolderResult(true);
    }
  };

  const modalDetail = [
   
    {
      field1: "Blog Order",
      field2: "blogOrder",
    },
    {
      field1: "Blog Categories",
      field2: "mEmail",
    },

    {
      field1: "Blog Image",
      field2: "mMerchantId",
    },
  ];
 
  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      setProfileImage(URL.createObjectURL(e.target.files[0]));
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleUpdateImageChange = (e: any) => {
   
    if (e.target.files.length) {
      //setUpdateProfileImage(URL.createObjectURL(e.target.files[0]));
     setUpdateImage(URL.createObjectURL(e.target.files[0]))
     setUpdateBlogImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
      // setUpdateImage({
      //   preview: URL.createObjectURL(e.target.files[0]),
      //   raw: e.target.files[0],
      // });
      
    }
  };

  const handleClearImg = (e: any) => {
  
    setProfileImage("");
    //changes
    setUpdateImage("");
    setUpdateProfileImage("");
    setUpdateBlogImage({raw:"",preview:""})
  };
  //------------------------------------------------------------------------------------------------//
  return (
    <>
      <Layout>
        {loader ?
          (
            <div className="adminLoader">
              <span className="text-warning text-center lds-dual-ring"></span>
            </div>
          )
          :
          ("")
        }

     

        <Stack direction="horizontal" gap={2}>
          {/* <Button variant="info" type="submit" onClick={handleAllUserList}>All User List</Button>{" "} */}
          <Button variant="info" className="ms-auto" onClick={handleShow}>{" "}Add{" "}</Button>{" "}
        </Stack>
        <div className="usersManageFolders">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-warning text-center" style={{width:'20%'}}>Blog Heading</th>
                <th className="text-warning text-center">Blog Description</th>
                <th className="text-warning text-center">Blog Image</th>
                <th className="text-warning text-center">Category</th>
                <th className="text-warning text-center">Action</th>
              </tr>
            </thead>
            <tbody>

              {adminDetail &&
                adminDetail.map((list: any, index: number) => {
                
                  return (
                    <>
                      <tr>
                        <td>
                          <div> 
                            {readMoreContent_forHeading && blogIndex===list.id?
                              <span className="text-light"><p className="break-spaces">{readMoreContent_forHeading} {list.heading.length > 35 && <small className="text-warning pointer read_more_or_less mt-3" onClick={() => readLess('heading',list.id)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Read Less</u> </small>} </p> </span>
                              :
                              <span className="text-light"> {list.heading.length > 35  ? list.heading.substring(0, 35) + '...' : list.heading} {list.heading.length > 35 && <small className="text-warning pointer read_more_or_less mt-3" onClick={() => readMore('heading', list.heading,list.id)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u></small>}</span>
                            }
                          </div>

                        </td>
                        {/* <td className="text-center">{index + 1}</td> */}
                        {/* <td className="text-center">{list.blogHeading}</td> */}
                        <td>
        <div>
          {readMoreContent_forDescription && blogIndex === list.id ? (
            <span className="text-light">
              <p className="break-spaces" dangerouslySetInnerHTML={{ __html: readMoreContent_forDescription }}></p>
              {list.description.length > 50 && (
                <small className="text-warning pointer read_more_or_less" onClick={() => readLess('description', list.id)}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Read Less</u>
                </small>
              )}
            </span>
          ) : (
            <span className="text-light">
              {list.description.length > 50 ? 
                <span dangerouslySetInnerHTML={{ __html: list.description.substring(0, 50) + '...' }}></span> : 
                <span dangerouslySetInnerHTML={{ __html: list.description }} />
              }
              {list.description.length > 50 && (
                <small className="text-warning pointer read_more_or_less" onClick={() => readMore('description', list.description, list.id)}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u>
                </small>
              )}
            </span>
          )}
        </div>
      </td>
                        {/* <td className="text-center">{list.blogDescription}</td> */}
                        <td className="text-center">
                          <img src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.image}`} alt="" width={150} height={150} style={{ borderRadius: "10px" }} />
                        </td>

                        <td className="text-center">{list.category}</td>
                        <td className="text-center">
                          <button type="submit" className="btn btn-outline-success" value={list.id} onClick={() => handleViewModalShow(list.id)}><BsEyeFill /></button>
                          <button type="submit" className="btn btn-outline-warning m-2" value={list.id} onClick={() => handleUpdatePlanShow(list.id)} > <BsPencilFill /> </button>
                          <button type="submit" className="btn btn-outline-danger" value={list.id} id={list.planType} onClick={() => handleRemoveFolder(list.id)} > <BsTrashFill /> </button>
                        </td>
                        {/* <td className="text-center">
                          <button type="submit" className="btn btn-outline-success m-2" value={list.id} onClick={() => handleGeneratePC(list.id)} ><RiCoupon2Line /></button>
                        </td> */}
                        {/* <td className="text-center">
                          <button type="submit" className="btn btn-outline-warning m-2" value={list.id} onClick={() => handleUserList(list.id)} > < FaUsers /> </button>
                        </td> */}
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </Table>
        </div>

        {/* -------------ADD FOLDER MODAL---------------  */}
        <Modal show={show} size="xl" backdrop="static" onHide={handleClose} className="adminModal addBlogModal">
  <Modal.Header closeButton>
    <Modal.Title style={{ color: "#E79E1F" }}>Add Blog</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={handleSubmit}>
      <Container>
        <Row>
          {/* First Column */}
          <Col lg={3}>
            {modalDetail && modalDetail.map((data: any, index) => {
              return (
                <Form.Group className="mb-3 mt-3" key={index}>
                  <Form.Label style={{ color: "whitesmoke" }}>
                    {data.field1}
                  </Form.Label>
                  {data.field1 === "Blog Categories" ? (
                    <Dropdown align="start">
                      <Dropdown.Toggle id="multi-dropdown-basic" className="w-100">
                        <span className="m-auto ">{dropdownSelected}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleChange} name="mAccountType">BeyondBox</Dropdown.Item>
                        <Dropdown.Item onClick={handleChange} name="mAccountType">Travel</Dropdown.Item>
                        <Dropdown.Item onClick={handleChange} name="mAccountType">Entertainment</Dropdown.Item>
                        <Dropdown.Item onClick={handleChange} name="mAccountType"> Wellness</Dropdown.Item>
                        <Dropdown.Item onClick={handleChange} name="mAccountType"> Bucket List</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : data.field1 === "Blog Image" ? (
                    <Form.Group className="d-flex flex-column justify-content-center align-items-center">
                      <div className="imageInput">
                        <label htmlFor="upload-button">{" "}
                          {profileImage ? (
                            <img src={profileImage} alt="" style={{ borderRadius: "10px" }} />
                          ) : (
                            <div className="image"><img /></div>
                          )}
                        </label>
                      </div>
                      <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleImageChange} />
                      <br />
                      <Button style={{ marginLeft: "1rem", color: "#fff" }} className="clearBtn" size="sm" onClick={handleClearImg} type="button">  Clear </Button>
                      {props.message && (
                        <div className={props.message && props.message.status === "success" ? "text-success" : "text-danger"} >
                          {props.message.data}
                        </div>
                      )}
                    </Form.Group>
                  ) : (
                    <Form.Control type={data.field1 === "Contact Number" || data.field1 === "Bank Account Number" || data.field1 === "Blog Order" ? "number" : "text"} name={data.field2} onInput={(e: React.ChangeEvent<HTMLInputElement>) => { e.target.value = e.target.value.trimStart(); }} onChange={handleChange} autoFocus />
                  )}
                  {(checkValidation[data.field2] && errors[data.field2]) && (<p className="text-danger"> {errors[data.field2]} </p>)}
                </Form.Group>
              );
            })}
          </Col>
          {/* Second Column */}
          <Col lg={9}>
          <Col className="col-md-auto" md={12}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke"}}>
                        Blog Heading
                      </Form.Label>
                      <Form.Control type= "text" name="blogHeading" onInput={(e: React.ChangeEvent<HTMLInputElement>) => { e.target.value = e.target.value.trimStart(); }} onChange={handleChange} autoFocus />
                    </Form.Group>
                    {checkValidation["blogHeading"] && errors["blogHeading"] && (<p className="text-danger">{errors["blogHeading"]}</p>)}
                  </Col>
            <Form.Group className="mb-3 mt-3">
              <Form.Label style={{ color: "whitesmoke" }}>Blog Description</Form.Label>
              <div style={{ height: 'calc(100% - 2.5rem)', backgroundColor: 'white' }}>
                <div style={{ height: '100%', width: '100%'}}>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={handleEditorChange}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        {loader ?
          (
            <div className="adminLoader">
              <span className="text-warning text-center lds-dual-ring"></span>
            </div>
          )
          : ("")
        }
      </Container>
      <Row className="justify-content-md-center mt-10">
        <Col className="" md="auto" style={!isValid ? { cursor: "not-allowed" } : {}}>
          <Button className="backBtn mx-auto" type="submit" disabled={isValid && dropdownSelected !== 'Select' && profileImage !== "" && descriptionText !== '' && descriptionText !== '<p></p>'  ? false : true} > Submit </Button>
        </Col>
      </Row>
    </Form>
  </Modal.Body>
</Modal>



        {/* ACTION - VIEW ,UPDATE AND DELETE */}
        <Modal show={viewModalShow} size="xl" backdrop="static" onHide={handleViewModalClose} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>
              {disabledCheck ? "Blog View Details" : "Blog Update Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUpdateSubmit}>
              <Container>
                <Row>
                  <Col  lg={3}>         
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Blog Order
                      </Form.Label>
                      <Form.Control type="number" name="blogOrder" onChange={handleUpdateChange} value={disabledCheck ? viewModalDetails.blogOrder : updatePlan.blogOrder} autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["blogOrder"] && errors["blogOrder"] && (<p className="text-danger"> {errors["blogOrder"]} </p>)}
                 

                 
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}> Blog Categories</Form.Label>
                      {!disabledCheck ? <Dropdown align="start">
                        <Dropdown.Toggle id="multi-dropdown-basic" className="w-100">
                          <span className="m-auto ">{updateDropdownSelected}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={handleUpdateChange} name="mAccountType">BeyondBox</Dropdown.Item>
                          <Dropdown.Item onClick={handleUpdateChange} name="mAccountType">Travel</Dropdown.Item>
                          <Dropdown.Item onClick={handleUpdateChange} name="mAccountType">Entertainment</Dropdown.Item>
                          <Dropdown.Item onClick={handleUpdateChange} name="mAccountType"> Wellness</Dropdown.Item>
                          <Dropdown.Item onClick={handleUpdateChange} name="mAccountType"> Bucket List</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> :
                        <Form.Control type="text" name="mEmail" onChange={handleUpdateChange} value={updateDropdownSelected} autoFocus disabled={disabledCheck} />
                      }
                      {/* <Form.Control type="text" name="mEmail" onChange={handleUpdateChange} value={disabledCheck ? viewModalDetails.marketerEmail : updatePlan.mEmail} autoFocus disabled={disabledCheck} /> */}
                    </Form.Group>
                    {checkValidation["mEmail"] && errors["mEmail"] && (<p className="text-danger">{errors["mEmail"]}</p>)}
                 

                
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Blog Image</Form.Label>
                      
                        <Form.Group className="d-flex flex-column justify-content-center align-items-center">
                          <div className={updateprofileImage!=""||updateimage!="" ? "imageInput disabled" : "imageInput "}>
                            <label htmlFor="upload-button">{" "}
                              {updateprofileImage==="" &&updateimage!="" ?
                             
                                (<img src={updateimage} alt="" style={{ borderRadius: "10px" }} />)
                                :
                                updateprofileImage!="" ?(<img src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${updateprofileImage}`}  alt="" style={{ borderRadius: "10px" }} />)
                                :(<div className="image"><img /></div>)
                              }
                            </label>
                          </div>
                          <input disabled={disabledCheck} type="file" id="upload-button" style={{ display: "none" }} onChange={handleUpdateImageChange} />
                          <br />
                          {/* <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button" > Upload </Button> */}
                          {!disabledCheck && <Button style={{ marginLeft: "1rem", color: "#fff" }} className="clearBtn" size="sm" onClick={handleClearImg} type="button">  Clear </Button>}
                          {props.message && (
                            <div className={props.message && props.message.status === "success" ? "text-success" : "text-danger"} >
                              {props.message.data}
                            </div>
                          )}
                        </Form.Group>
                    
                      {/* <Form.Control type="text" name="mPayfastPassphrase" onChange={handleUpdateChange} value={disabledCheck ? viewModalDetails.marketerPassphrase : updatePlan.mPayfastPassphrase} autoFocus disabled={disabledCheck} /> */}
                    </Form.Group>
                    {/* {checkValidation["blogOrder"] && errors["blogOrder"] && (<p className="text-danger"> {errors["blogOrder"]} </p>)} */}
                    </Col>
                            {/* Second Column */}
                  <Col lg={9}>
                  <Col className="col-md-auto" md={12}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke"}}>
                        Blog Heading
                      </Form.Label>
                      <Form.Control type="text" name="blogHeading" onChange={handleUpdateChange} value={disabledCheck ? viewModalDetails.blogHeading : updatePlan.blogHeading} autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["blogHeading"] && errors["blogHeading"] && (<p className="text-danger">{errors["blogHeading"]}</p>)}
                    </Col>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label style={{ color: "whitesmoke" }}>Blog Description </Form.Label>
  
                      {!disabledCheck ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center",  height: 'calc(100% - 2.5rem)', backgroundColor: 'white' }}>
                        <div style={{ width: '100%' }}> {/* Adjust maxWidth as needed */}
                        
              
                          <Editor
                            editorState={updateeditorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={handleUpdateEditorChange}
                          />
                        </div>
                      </div> : <div className="scrollData"> <p  style={{ wordWrap: 'break-word', color: '#ccc' }} >
                        {viewModalDetails.description ? (
                          <span dangerouslySetInnerHTML={{ __html: viewModalDetails.description }} />
                        ) : null}
                      </p> </div>}
                      {/* <Form.Control type="number" name="mMerchantId" onChange={handleUpdateChange} value={disabledCheck ? viewModalDetails.marketerMerchantId : updatePlan.mMerchantId} autoFocus disabled={disabledCheck} /> */}
                    </Form.Group>
                    {/* {checkValidation["mMerchantId"] && errors["mMerchantId"] && (<p className="text-danger"> {errors["mMerchantId"]} </p>)} */}
                  </Col>

                  {/* <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}> Merchant Key </Form.Label>
                      <Form.Control type="text" name="mMerchantKey" onChange={handleUpdateChange} value={disabledCheck ? viewModalDetails.marketerMerchantKey : updatePlan.mMerchantKey} autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["mMerchantKey"] && errors["mMerchantKey"] && (<p className="text-danger">{errors["mMerchantKey"]}</p>)}
                  </Col> */}

                  {/* {!disabledCheck ? <>

                    <Form.Label style={{ color: "whitesmoke" }}> 100% Discount </Form.Label>
                    {!discountUpdateApplied ? <Form.Check
                      checked={updateChecked}
                      type="checkbox"
                      label="Add Users"
                      onChange={handleUpdateCheckboxChange}
                      style={{ color: 'white' }}
                    /> : <>
                      {<div>
                        <span style={{ color: "whitesmoke" }}> Applicable Users: {updateDiscountAmount}</span>
                        <Button onClick={handleUpdateDiscountChange} style={{ marginLeft: '10px' }}>Edit</Button>
                      </div>}
                    </>}
                    {showUpdateDiscountPopup && (
                      <div>
                        <Form.Control
                          style={{ width: '48%' }}
                          type="number"
                          placeholder="Enter Number of Users"
                          value={updateDiscountAmount}
                          onChange={handleUpdateDiscountAmountChange}
                          inputMode="numeric"
                        />
                        <Button onClick={handleUpdateDiscountApply} disabled={!updateDiscountAmount}>Apply</Button>
                      </div>
                    )}
                  </> : <>
                    {viewModalDetails.fullDiscountNoOfPer !== 0 && <Col className="col-md-auto" md={6}>
                      <Form.Group className="mb-3 mt-3 "  >
                        <Form.Label style={{ color: "whitesmoke" }}>100 % Discounted Users</Form.Label>
                        <Form.Control type="text" name="mMerchantKey" onChange={handleUpdateChange} value={viewModalDetails.fullDiscountNoOfPer} autoFocus disabled={disabledCheck} />
                      </Form.Group>
                      {checkValidation["mMerchantKey"] && errors["mMerchantKey"] && (<p className="text-danger">{errors["mMerchantKey"]}</p>)}
                    </Col>}

                  </>} */}


                </Row>
                {loader ?
                  (
                    <div className="adminLoader">
                      <span className="text-warning text-center lds-dual-ring"></span>
                    </div>
                  )
                  :
                  ("")}
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  {!disabledCheck ? (<Button className="backBtn" type="submit" disabled={((updatedescriptionText===''  )||!isValid) ||(updateprofileImage===""&&updateBlogImage.raw==="")}> Update </Button>) : ("")}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getBlogList: (data: any) => dispatch(getBlogList(data)),
  addAdminBlog: (data: any) => dispatch(addAdminBlog(data)),
  deleteAdminBlog: (data: any) => dispatch(deleteAdminBlog(data)),
  getBlogDetailById: (data: any) => dispatch(getBlogDetailById(data)),
  updateAdminBlog: (data: any) => dispatch(updateAdminBlog(data)),
});
const mapStateToProps = (state: any) => {
  return {
    blogListData: state.AdminBlogReducer && state.AdminBlogReducer.blogListData ? state.AdminBlogReducer.blogListData : null,
    addAdminBlogData: state.AdminBlogReducer && state.AdminBlogReducer.addAdminBlogData ? state.AdminBlogReducer.addAdminBlogData : null,
    deleteAdminBlogData: state.AdminBlogReducer && state.AdminBlogReducer.deleteAdminBlogData ? state.AdminBlogReducer.deleteAdminBlogData : null,
    getBlogDetailByIdData: state.AdminBlogReducer && state.AdminBlogReducer.getBlogDetailByIdData ? state.AdminBlogReducer.getBlogDetailByIdData : null,
    updateAdminBlogData: state.AdminBlogReducer && state.AdminBlogReducer.updateAdminBlogData ? state.AdminBlogReducer.updateAdminBlogData : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBlog);

