import React, { useState } from 'react';
import { Nav, Navbar, Button, Accordion, Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

import users from '../../assets/Admin/images/menu-users.png';
import rBox from '../../assets/Admin/images/releaseBox.png';
import marketerIcon from '../../assets/Admin/images/marketer.png';
import photos from '../../assets/Admin/images/menu-photos.png';
import misc from '../../assets/Admin/images/menu-misc.svg';
import plan from '../../assets/Admin/images/menu-plan.png';
import foundationIcon from '../../assets/Admin/images/foundation.png';
import payments from '../../assets/Admin/images/terms.png';
import folder from '../../assets/Admin/images/menu-folder.png';
import logo from '../../assets/Admin/images/beyondBoxLogo.svg';
import cross from '../../assets/Admin/images/cross.png';

interface MyProps {
  classNameValue?: boolean;
  clearLogin?: () => void;
  handleDrawerOpen?: (open: boolean) => void;
  history?: any;
}

const AdminSidebarMenu = (props: MyProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    // isOpen === true ? setIsOpen(false) : setIsOpen(true);
    props.handleDrawerOpen && props.handleDrawerOpen(false)
    
  }

  function handleLogout() {
    localStorage.setItem('userData', '');
    props.clearLogin && props.clearLogin();
    props.history.push("/");
    return;
  }

  React.useEffect(() => {
    (props.classNameValue) && setIsOpen(props.classNameValue)
  }, [props.classNameValue])

  return (
    // <div className={`leftWrap ${isOpen == true ? 'showNav' : ''} `}>
    <div  className={`leftWrap ${props.classNameValue == true ? 'showNav' : ''}`}>
      <Button className="closeNav" onClick={toggleSidebar}><img src={cross} alt="" /></Button>
      <Navbar.Brand href="#"><img src={logo} className="App-logo admin_logo" alt="logo" /></Navbar.Brand>
      <div className="navigation">
        <ul>
          <li><Link to="/admin-user-dashboard"><img src={users} /> Users</Link></li>
          <li><Link to="/admin-cover-photos"><img src={photos} />Cover photos</Link></li>
          <li><Link to="/admin"><img src={plan} alt="" />Box Plans</Link></li>
          <li><Link to="/admin-foundation"><img src={foundationIcon} alt="" />Foundation</Link></li>
          <li><Link to="/admin-manage-folders"><img src={folder} alt="" />Manage Folders</Link></li>
          <li><Link to="/admin-marketer"><img src={marketerIcon} /> Marketers</Link></li>
          <li>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic"><img src={misc} alt="" />Country-State-City Listing</Dropdown.Toggle>
              <Dropdown.Menu>
                <ul>
                  <li><Link to="/admin-countries">Country</Link></li>
                  <li><Link to="/admin-states">State</Link></li>
                  <li><Link to="/admin-cities">City</Link></li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li><Link to="/admin-release-box"><img src={rBox} alt="" />Release Box </Link></li>
          <li><Link to="/admin-blog"><img src={photos} alt="" />Blog</Link></li>
        </ul>
      </div>
    </div>
  )
};

export default (AdminSidebarMenu);