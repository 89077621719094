
import {USER_PROFILE} from '../constant/index';

export const getProfile = data => {
  console.log(data)
  return {
    type: USER_PROFILE.GET_PROFILE_PAGE,
    payload: data,
  };
};

export const getEditProfile = data => {
  return {
    type: USER_PROFILE.GET_EDIT_PROFILE,
    payload: data,
  };
};

export const uploadProfileImage = data => {
  return {
    type: USER_PROFILE.UPLOAD_PROFILE_IMAGE,
    payload: data,
  };
};

export const updateUserProfile= data => {
  return {
    type: USER_PROFILE.UPDATE_USER_PROFILE,
    payload: data,
  };
};

export const getKinDetails= data => {
  return {
    type: USER_PROFILE.GET_KIN_DETAILS,
    payload: data,
  };
};

export const changePassword = data => {
  return {
    type: USER_PROFILE.REQUEST_CHANGE_PASSWORD,
    payload: data,
  };
};

export const getUserStorage = data =>{
  return {
    type:USER_PROFILE.GET_STORAGE,
  }
}

export const getOTP_ToEditPhone = data =>{
  return {
    type:USER_PROFILE.GET_OTP_TO_EDIT_PHONE_NO,
    payload:data
  }
}

export const verifyOTP_ToEditPhoneNo = data =>{
  return {
    type:USER_PROFILE.GET_OTP_VERIFICATION_TO_EDIT_PHONE_NO,
    payload:data
  }
}