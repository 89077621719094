import { useState,useEffect }     from "react";
import { Button, Col, Container, Row }      from "react-bootstrap";
import { connect }                from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getPublicBoxContent }    from "../../redux/actions/boxAction";
import { BASE_URLS }              from "../../redux/constant";
import { GoLocation }             from "react-icons/go";
import user_Img                   from "../../assets/images/user.png"
import Layout                     from "../../layout/Layout";
import quote                      from "../../assets/images/quote.png";
import { getLocation,getUserState,getUserCountry } from "../../redux/actions/countryApiAction";

function BoxRecordUser(props:any) {
  let userData                                                                         = localStorage.getItem("profileData");
  const { id }                                                                         = useParams();
  const [loader,                               setLoader]                              = useState(false);
  const [usrLocation,                          setUsrLocation]                         = useState({city:'',state:'',country:''});
  const [readMoreContent_forQuote,             setReadMoreContent_forQuote]            = useState('');
  const [readMoreContent_forLife_Work,         setReadMoreContent_forLife_Work]        = useState('');
  const [readMoreContent_forWhat_I_Lived_For,  setReadMoreContent_forWhat_I_Lived_For] = useState('');
  const navigate                                                                       = useNavigate();

  useEffect(() =>{
    setLoader(true)
    props.getPublicBoxContent({'id':Number(id)})
  },[])

  useEffect(() =>{
    if(props.getPublicBoxContent_res){
      if(props.getPublicBoxContent_res[0] && props.getPublicBoxContent_res[0].UserboxesBTuser && props.getPublicBoxContent_res[0].UserboxesBTuser.id){
        let userId = props.getPublicBoxContent_res[0].UserboxesBTuser.id;
        props.getLocation(userId);//for city
        props.getUserState(userId);//for state
        props.getUserCountry(userId);//for country
      }
      setLoader(false);
    }
  },[props.getPublicBoxContent_res])
  
  useEffect(() =>{
    
    let country = '';
    let state   = '';
    let city    = '';

    if(props.getUserCountryLocation && props.getUserCountryLocation.name){
      country = (props.getUserCountryLocation.name ? props.getUserCountryLocation.name : 'N/A')
      if(props.getUserStateLocation && props.getUserStateLocation.name){
        state = (props.getUserStateLocation.name ? props.getUserStateLocation.name : 'N/A')
        if(props.getUserLocation && props.getUserLocation.name){
          city = (props.getUserLocation.name ? props.getUserLocation.name :'N/A')
        }
      }
    }
    setUsrLocation({...usrLocation,country,state,city});
  },[props.getUserLocation,props.getUserStateLocation,props.getUserCountryLocation])

// READ MORE ACTION -----------------------  
  const readMore = (type:any,value:any) =>{
    if(type === 'quote'){ setReadMoreContent_forQuote(value); }
    if(type === 'life_work'){ setReadMoreContent_forLife_Work(value); }
    if(type === 'what_i_lived_for'){ setReadMoreContent_forWhat_I_Lived_For(value); }
  }

// READ LESS ACTION -----------------------  
  const readLess = (type:any) => {
    if(type === 'quote'){ setReadMoreContent_forQuote(''); }
    if(type === 'life_work'){ setReadMoreContent_forLife_Work(''); }
    if(type === 'what_i_lived_for'){ setReadMoreContent_forWhat_I_Lived_For(''); }
  }
//--------------------------------------------------------------//  
 
  return (
    <Layout>
    <div className={`boxes ${ !userData ? `openDiscoverBox_publicPage` : `` }`}>
      <Container>
        <div className="boxListFolder">
          <div className="userDetails publicBox_userDetails">
            <div className="img boxRecods-image">
              {props.getPublicBoxContent_res && props.getPublicBoxContent_res[0] && props.getPublicBoxContent_res[0].UserboxesBTuser && props.getPublicBoxContent_res[0].UserboxesBTuser.userImg   ?
                <img src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${props.getPublicBoxContent_res[0].UserboxesBTuser.userImg}`} alt="" style={{borderRadius:"50px"}} onError={({ currentTarget } ) => {currentTarget.onerror = null; currentTarget.src=user_Img; }} />
              :
                <img src={user_Img} alt="" style={{borderRadius:"50px"}} />
              }
            </div>
            <div className="publicBox_userName text-warning">
              {props.getPublicBoxContent_res && props.getPublicBoxContent_res[0] && props.getPublicBoxContent_res[0].UserboxesBTuser && props.getPublicBoxContent_res[0].UserboxesBTuser.lastName && props.getPublicBoxContent_res[0].UserboxesBTuser.lastName ?
                (props.getPublicBoxContent_res[0].UserboxesBTuser.firstName.charAt(0).toUpperCase() + props.getPublicBoxContent_res[0].UserboxesBTuser.firstName.slice(1)+' '+props.getPublicBoxContent_res[0].UserboxesBTuser.lastName.charAt(0).toUpperCase() + props.getPublicBoxContent_res[0].UserboxesBTuser.lastName.slice(1)) 
              :
                ''
              }
            </div>
            <div className="publicBox_boxName text-light"><i>{props.getPublicBoxContent_res && props.getPublicBoxContent_res[0] && props.getPublicBoxContent_res[0].boxName ? props.getPublicBoxContent_res[0].boxName :''}</i></div>
          </div>
          <div className="row justify-content-end floatingBtnBlock">
            <div className="col-auto">
                <Button className='backBtn' onClick={() => navigate('/box_records')}>Back</Button>
            </div>
          </div>

          {loader ?
            <div className="text-center">
              <div className="lds-dual-ring text-warning">Loading... please wait!</div>
            </div>
          :   
            <div>
              {props.getPublicBoxContent_res && props.getPublicBoxContent_res.map((boxItemContent:any)=>{
          
              return(
                <div>
                  <div className="p-3" key={boxItemContent.id}>
                    {boxItemContent.UserboxesBTuser &&
                      <div>
                        <div className="row">
                          <div className="col-md-9 pt-3">
                            { boxItemContent.quote &&
                              <div>
                                <label className="text-warning">Quote &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</label>
                                {readMoreContent_forQuote ?  
                                  <span className="text-light"><p>{readMoreContent_forQuote} {boxItemContent.quote.length > 50 && <small className="text-warning pointer read_more_or_less max-width-60 text-end" onClick={() => readLess('quote')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Read Less</u> </small> } </p> </span>
                                :
                                  <span className="text-light"> {boxItemContent.quote.length > 50 ? boxItemContent.quote.substring(0,50)+'...' : boxItemContent.quote} {boxItemContent.quote.length > 50 && <small className="text-warning pointer read_more_or_less max-width-60 text-end" onClick={() => readMore('quote',boxItemContent.quote)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u></small> }</span>
                                }
                              </div>
                            }
                      
                            {boxItemContent.life_work &&
                              <div>
                                <label className="text-warning">Life's work &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</label>
                                {readMoreContent_forLife_Work ?  
                                  <span className="text-light"><p>{readMoreContent_forLife_Work} {boxItemContent.quote.length > 50 && <small className="text-warning pointer read_more_or_less max-width-60 text-end" onClick={() => readLess('life_work')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read Less</u></small> } </p> </span>
                                :
                                  <span className="text-light"> {boxItemContent.life_work.length > 50 ? boxItemContent.life_work.substring(0,50)+'...' : boxItemContent.life_work} {boxItemContent.life_work.length > 50 && <small className="text-warning pointer read_more_or_less max-width-60 text-end" onClick={() => readMore('life_work',boxItemContent.life_work)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u></small> }</span>
                                }
                              </div>
                            }
                                  
                            {boxItemContent.what_i_lived_for &&
                              <div>
                                <label className="text-warning">What I lived for :&nbsp;&nbsp;</label>
                                {readMoreContent_forWhat_I_Lived_For ?  
                                  <span className="text-light"><p>{readMoreContent_forWhat_I_Lived_For} {boxItemContent.quote.length > 50 && <small className="text-warning pointer read_more_or_less max-width-60 text-end" onClick={() => readLess('what_i_lived_for')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read Less</u></small> } </p> </span>
                                :
                                  <span className="text-light"> {boxItemContent.what_i_lived_for.length > 50 ? boxItemContent.what_i_lived_for.substring(0,50)+'...' : boxItemContent.what_i_lived_for} {boxItemContent.what_i_lived_for.length > 50 && <small className="text-warning pointer read_more_or_less max-width-60 text-end" onClick={() => readMore('what_i_lived_for',boxItemContent.what_i_lived_for)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u></small> }</span>
                                }
                              </div>
                            }
                          </div>
                          <div className="col-md-3 text-light mt-3 mt-md-0">
                            <GoLocation className="text-warning text-right ms-auto"/>
                            <div>
                              <label className="text-warning">City&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</label>
                              <span title={usrLocation.city}>{usrLocation.city ? (usrLocation.city.length > 20 ? usrLocation.city.substring(0,20) + '...' : usrLocation.city) : <small className="text-warning">Loading..<span className="blink">.</span></small> }</span>
                            </div>
                            <div>
                              <label className="text-warning">State&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</label>
                              <span title={usrLocation.state}>{usrLocation.state ? (usrLocation.state.length > 20 ? usrLocation.state.substring(0,20) + '...' : usrLocation.state) : <small className="text-warning">Loading..<span className="blink">.</span></small> }</span>
                            </div>
                            <div>
                              <label className="text-warning">Country :&nbsp;&nbsp;</label>
                              <span title={usrLocation.country}>{usrLocation.country ? (usrLocation.country.length > 20 ? usrLocation.country.substring(0,20) + '...' : usrLocation.country) : <small className="text-warning">Loading..<span className="blink">.</span></small> }</span>
                            </div>
                          </div>
                        </div>
                        {boxItemContent && boxItemContent.userBoxFiles && boxItemContent.userBoxFiles.length !== 0 && boxItemContent.userBoxFiles.map((item:any) =>{
                          return(
                            <div className="publicMessage"><br/>
                              <img src={quote} alt="" style={{marginBottom:"30px"}}/> 
                                <p className="text-light" style={{opacity:"0.65",marginLeft:'0.5%', display: 'inline',wordWrap:'break-word'}}>{item.boxMessage && item.boxMessage}</p>
                              <img src={quote} alt="" style={{transform:"scaleX(-1)",marginBottom:"30px",marginLeft:'0.5%'}}/>
                            </div>
                          )})
                        }
                      </div>
                    }
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    </Container>
  </div>
</Layout>
)}

const mapDispatchToProps = (dispatch: any) => ({
  getPublicBoxContent : (data: any) => dispatch(getPublicBoxContent(data)),
  getLocation         : (data: any) => dispatch(getLocation(data)),
  getUserState        : (data: any) => dispatch(getUserState(data)),
  getUserCountry      : (data: any) => dispatch(getUserCountry(data)),
});

const mapStateToProps = (state: any) => {
  return {
    getPublicBoxContent_res : state.BoxReducer     && state.BoxReducer.publicBoxDetails    ? state.BoxReducer.publicBoxDetails    : null,
    getUserLocation         : state.CountryReducer && state.CountryReducer.Location        ? state.CountryReducer.Location        : null,
    getUserStateLocation    : state.CountryReducer && state.CountryReducer.stateLocation   ? state.CountryReducer.stateLocation   : null,
    getUserCountryLocation  : state.CountryReducer && state.CountryReducer.countryLocation ? state.CountryReducer.countryLocation : null,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BoxRecordUser);