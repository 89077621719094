import React, { useState, useEffect } from "react";
import { connect }                    from "react-redux";
import { Button, Col, Form, Row }               from "react-bootstrap";
import { Link, useLocation, useNavigate }          from "react-router-dom";
import logo                           from '../../assets/images/BB.png';
import eyeLogo                        from '../../assets/images/eye.png';
import slashedEye                     from '../../assets/images/unseen.png';
import { signupFormValidation }       from "../../validations/signup-form";
import { registerUser }               from "../../redux/actions/authAction";
import { countryList, stateList }     from "../../redux/actions/countryApiAction";
import { AUTH }                       from '../../redux/constant';
import Footer                         from "../../layout/elements/footer";
import { commonMessageAction }        from "../../redux/actions/messageAction";
import { getCountryDialCode }         from "../../redux/actions/webAction";
import { log } from "console";
var CryptoJS = require("crypto-js");

const Signup = (props: any) => {
    //const [routeState, setRouteState] = useState<any>(null);
    const {state}=useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // useEffect(()=>{
    //     if (state) {
    //         setRouteState(state);
    //     }
    //     console.log(state)
    // },[])
    
    localStorage.clear();
    const redirectTo                                    = useNavigate();
    const url                                           = window.location.pathname.split('/');
    const [passwordType,        setPasswordType]        = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [countryValue,        setCountryValue]        = useState(0 as any);
    const [stateValue,          setStateValue]          = useState(0 as any);
    const [countryID,           setCountryID]           = useState([]);
    const [stateID,             setStateID]             = useState([]);
    const [signupForm,          setSignupValue]         = useState({username: state&&state.name?state.name:'',email:state&&state.email?state.email: '',password: '', confirm_password: '',countryDialCode:'',mobile: '',country: '',state: ''});
    let   [errors,              setErrors]              = useState({email: '', password: '', confirm_password: '', username: '',countryDialCode:'', mobile: '', country: '', state: '' });
    let   [checkValidation,     setCheckValidation]     = useState({ email: false, password: false, confirm_password: false, username: false,countryDialCode:false, mobile: false, country: false, state: false });
    let   [isSubmitted,         setIsSubmitted]         = useState(false);
    let   [isValid,             setIsValid]             = useState(true);
    let   [eyeImg1,             seteyeImg1]             = useState(<img src={slashedEye} alt="" />);
    let   [eyeImg2,             seteyeImg2]             = useState(<img src={slashedEye} alt="" />);
    let   [continueBtn,         setcontinueBtn]         = useState('darkBtn');
    let   [tnc ,                setTnc]                 = useState(false);
    let   [tncError ,           setTncError]            = useState(false);
    let   [sExclusion,          setSExclusion]          = useState(true);
    let   [sExclusionError,     setSExclusionError]     = useState(false);
    let   [years18,             setYears18]             = useState(false);
    let   [years18Error ,       setYears18Error]        = useState(false);

    useEffect(()=>{
        var ciphertext = (url && url[2] ? url[2] : '');
        if(url[1] === 'signup' && ciphertext){
            var bytes  = CryptoJS.AES.decrypt(decodeURIComponent(ciphertext), 'BB Dev9930');
            var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            let uName = (originalText.recipientFname+' '+originalText.recipientLname);
            let e_mail = (originalText.recipientEmail);
            setSignupValue({...signupForm,username:uName,email:e_mail});
            if(url[1] === 'signup' && ciphertext && (uName.includes('undefined'))){
               redirectTo('*') 
            }
        }
        props.commonMessageAction();
        props.countryList();
        props.getCountryDialCode();
    },[])

    const togglePassword = () => {
        if (passwordType === "password") {
            seteyeImg1(<img src={eyeLogo} alt="" />);
            setPasswordType("text")
            return;
        }else{
            seteyeImg1(<img src={slashedEye} alt="" />);
        }
        setPasswordType("password")
    }
    
    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            seteyeImg2(<img src={eyeLogo} alt="" />);
            setConfirmPasswordType("text")
            return;
        }else{
            seteyeImg2(<img src={slashedEye} alt="" />);
        }
        setConfirmPasswordType("password")
    }

    const handleChange = (event: any) => {
        setCheckValidation({ ...checkValidation, [event.target.name]: true });
        setSignupValue({ ...signupForm, [event.target.name]: event.target.value })
    };
    
    const selectCountry = (e: any) => {
        e.preventDefault();
        setCheckValidation({ ...checkValidation, 'country': true });
        setSignupValue({ ...signupForm, 'country': e.target.value })
        setCountryValue(e.target.value);
        props.stateList({ id: e.target.value })
    }

    const selectState = (e: any) => {
        e.preventDefault();
        setCheckValidation({ ...checkValidation, 'state': true });
        setSignupValue({ ...signupForm, 'state': e.target.value })
        setStateValue(e.target.value)
    }

    useEffect(() => {
        const validationErrors = signupFormValidation(signupForm);
        isValid = Object.keys(validationErrors).length === 0;
        setIsValid(isValid);
        setErrors(validationErrors);
    }, [signupForm]);

    const handleSubmit  = () => {
        
        if(isValid && tnc && years18){
            let fullName    = (signupForm && signupForm.username) ? signupForm.username : '';
            fullName        = fullName.trim();
            const lastIndex = fullName.lastIndexOf(' ');
            let firstName   = '';
            let lastName    = '';
            let userData:any= {};
            
            if (lastIndex < 0) {
                firstName   = fullName;
                lastName    = '';
            } else {
                firstName   = fullName.slice(0, lastIndex);
                lastName    = fullName.slice(lastIndex + 1);
            }
            
            userData    = {
                "username"  : firstName+' '+lastName,
                "email"     : signupForm.email,
                "password"  : signupForm.password,
                "firstName" : firstName,
                "lastName"  : lastName,
                "mobile"    : signupForm.countryDialCode+signupForm.mobile,
                "countryId" : parseInt(countryValue),
                "stateId"   : parseInt(stateValue),
                'termsAndConditions':(tnc && 1),
                'sucideExlude':(sExclusion && 0),
                'eighteenYears':(years18 && 1),
                'is2FAEnabled':true,
                'isSMSEnabled':true,
                'isEmailEnabled':true
            }
            
            userData = {
                ...(url[1] === 'signup' && {...userData,"marketerBonusStatus":0 }),
                ...(url[1] === 'signup-with-promocode' && {...userData, "marketerBonusStatus":0, "promoCode":url[2]}),
            };

            setIsSubmitted(true);
            props.registerUser(userData);
        }else{
            setTncError(true);
            setSExclusionError(true);
            setYears18Error(true);
            setCheckValidation({ ...checkValidation, email: true, password: true, confirm_password: true, username: true,countryDialCode:true, mobile: true, country: true, state: true});
        }
    }

    useEffect(() => {
        setCountryID(props.countryData);
    }, [props.countryData])

    useEffect(() => {
        setStateID(props.stateData);
    }, [props.stateData])

    useEffect(() => {
        if (props.registerData) {
            props.cleanRegister();
            if(props.registerData.isActive === 0){
                setTimeout(() => {
                    if(!localStorage.getItem('email')){ localStorage.setItem('email',signupForm.email); }
                    if(!localStorage.getItem('id')){ localStorage.setItem('id',props.registerData.id); }
                    redirectTo('/user-verification',{state : {id:props.registerData.id}});
                }, 500)
            }
        }
    }, [props.registerData])

    const handleCheckboxTnC= (e:any)=>{
      if (e.target.checked){
        setTnc(true)
        setTncError(false);
      }else{
        setTnc(false)
        setTncError(true);
      }
    }

    // const handleCheckboxSE= (e:any)=>{
    //     if (e.target.checked){
    //       setSExclusion(true)
    //       setSExclusionError(false);
    //     }else{
    //         setSExclusion(false)
    //         setSExclusionError(true);
    //     }
    // }

    const handleCheckbox18Y= (e:any)=>{
        if (e.target.checked){
          setYears18(true)
          setYears18Error(false);
        }else{
          setYears18(false)
          setYears18Error(true);
        }
    }
    
//----------------------------------------------------------------------------//

    return (
        <>
            <div className="createAccount">
                <div className="userDetailBlock">
                    <div className="formWrap">
                        <div className="text-center">
                            <Link to="/"><img src={logo} className="App-logo" alt="logo" /> </Link>
                        </div><br/>
                        <h2 className="text-center">Create Account</h2>
                        <Form>
                            <Form.Group className="formRow">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" id="fullName" placeholder="Name and Surname" name='username' value={(signupForm && signupForm.username) ? signupForm.username : ''} onChange={handleChange} disabled={url[1] === 'signup' && url[2] ? true : false} style={url[1] === 'signup' && url[2] ? {cursor:"not-allowed"} : {}}/>
                                {(checkValidation.username && errors.username) && <p className="text-danger">{errors.username}</p>}
                            </Form.Group>
                            
                            <Form.Group className="formRow">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="text" id="emailSignup" placeholder="Email" name='email' value={(signupForm && signupForm.email) ? signupForm.email : ''} onChange={handleChange} disabled={url[1] === 'signup' && url[2] ? true : false} style={url[1] === 'signup' && url[2] ? {cursor:"not-allowed"} : {}}/>
                                {(checkValidation.email && errors.email) && <p className="text-danger">{errors.email}</p>}
                            </Form.Group>

                            <Form.Group className="formRow">
                                <Form.Label>Phone Number</Form.Label>
                                <Row className="pt-1">
                                    <Col className="mb-4 mb-md-0" md={5}>
                                        <Form.Select name="countryDialCode" className="rounded form-control" onChange={handleChange} value={(signupForm && signupForm.countryDialCode) ? signupForm.countryDialCode : ''}>
                                            <option hidden>Select</option>
                                            {props.countryDialCodeList && props.countryDialCodeList.map((dailCode: any) => (
                                                <option value={dailCode.phonecode} title={dailCode.nicename} key={dailCode.id}> {dailCode.nicename && dailCode.nicename.length > 25 ? dailCode.nicename.substring(0,25)+'...' : dailCode.nicename} ({dailCode.phonecode && dailCode.phonecode}) </option>
                                            ))}
                                        </Form.Select>
                                        {(checkValidation.countryDialCode && errors.countryDialCode) && <p className="text-danger">{errors.countryDialCode}</p>}
                                    </Col>
                                    <Col md={7}>
                                        <Form.Control type="number" autoComplete="off" id="mobileSignup" placeholder="Enter phone number" name='mobile' value={(signupForm && signupForm.mobile) ? signupForm.mobile : ''} onChange={handleChange} />
                                        {(checkValidation.mobile && errors.mobile) && <p className="text-danger">{errors.mobile}</p>}
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group className="formRow passwordHide">
                                {((checkValidation.password && errors.password) || (checkValidation.confirm_password && errors.confirm_password)) && 
                                    <div className="text-success">
                                        {/* Password must be eight characters including one uppercase letter, one special character (@#$%^&+=) and alphanumeric characters */}
                                        Your password must contain a minimum of:
                                        <ul >
                                            <li style={{listStyleType:"disc"}}>Eight characters</li>
                                            <li style={{listStyleType:"disc"}}>One uppercase letter</li>
                                            <li style={{listStyleType:"disc"}}>One special character (@#%^&+)</li>
                                            <li style={{listStyleType:"disc"}}>One number</li>
                                        </ul>
                                    </div>}
                                <Form.Label>Password</Form.Label>
                                <Form.Control type={passwordType} id="passwordSignup" placeholder="Enter password" name='password' value={(signupForm && signupForm.password) ? signupForm.password : ''} onChange={handleChange} />
                                <Button className="hideIcon" onClick={togglePassword} >{eyeImg1}</Button>
                            </Form.Group>
                            {(checkValidation.password && errors.password) && <p className="text-danger">{errors.password}</p>}

                            <Form.Group className="formRow passwordHide">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type={confirmPasswordType} id="confirmPasswordSignup" placeholder="Enter password" name="confirm_password" value={(signupForm && signupForm.confirm_password) ? signupForm.confirm_password : ''} onChange={handleChange} />
                                <Button className="hideIcon" onClick={toggleConfirmPassword} >{eyeImg2}</Button>
                            </Form.Group>
                            {(checkValidation.confirm_password && errors.confirm_password) && <p className="text-danger">{errors.confirm_password}</p>}
                        
                            <div className="row">
                                <div className="col-6">
                                    <Form.Group className="formRow">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Select name="country" id="countrySignup" aria-label="Default select example" className='rounded form-control' onChange={selectCountry}>
                                            <option value="0">Select Country</option>
                                            {countryID && countryID.map((country: any) => (
                                                <option value={country.id} key={country.id}>{country.name} </option>
                                            ))}
                                        </Form.Select>
                                        {(checkValidation.country && errors.country) && <p className="text-danger">{errors.country}</p>}
                                    </Form.Group>
                                </div>
                                <div className="col-6">
                                    <Form.Group className="formRow">
                                        <Form.Label>State</Form.Label>
                                        <Form.Select name="state" aria-label="Default select example" className="rounded form-control" onChange={selectState}>
                                            <option value="0">Select State</option>
                                            {stateID && stateID.map((state: any) => (
                                                <option value={state.id} key={state.id}>{state.name}</option>
                                            ))}
                                        </Form.Select>
                                        {(checkValidation.state && errors.state) && <p className="text-danger">{errors.state}</p>}
                                    </Form.Group>
                                </div>
                            </div>
                            
                            {/* TERM & CONDITION */}
                            <Form.Group className="inputCheck">
                                <Form.Check type="checkbox" id="check1" defaultChecked={tnc} onClick={(e:any)=>{ handleCheckboxTnC(e)}} />
                                <label htmlFor="check1">Agree to  <u><Link target={"_blank"} to="/terms-and-condition">Terms and Conditions</Link></u></label>
                            </Form.Group>
                            {(!tnc && tncError) && <p className="text-danger"> Terms and condition is required</p>}
                            
                            {/* SUICIDE EXCLUSION */}
                            {/* <Form.Group className="inputCheck">
                                <Form.Check type="checkbox" id="check2" defaultChecked={sExclusion} onClick={(e:any)=>{ handleCheckboxSE(e)}} />
                                <label htmlFor="check2">Accept <u><Link target={"_blank"} to="/suicide-exclusion">Suicide Exclusion</Link></u></label>
                            </Form.Group>
                            {(!sExclusion && sExclusionError) && <p className="text-danger">Suicide Exclusion is required</p>} */}
                            
                            {/* ABOVE 18 YEARS */}
                            <Form.Group className="inputCheck">
                                <Form.Check type="checkbox" id="check3" defaultChecked={years18} onClick={(e:any)=>{ handleCheckbox18Y(e)}} />
                                <label htmlFor="check3">I am over the age of 18 years</label>
                            </Form.Group>
                            {(!years18 && years18Error) && <p className="text-danger">You must be over 18 years old to visit this site</p>}
                            
                            {props.message && props.message.status !== 'success' && <div className={(props.message && props.message.status === 'success') ? 'text-success' : 'text-danger'}>{props.message.message ? props.message.message:props.message.data}</div>}
                            <div className={`d-flex justify-content-center ${isValid == true ? 'cursorStatus' : ''}`}>
                                <Button onClick={()=>{redirectTo('/login')}} className={'font-regular button-secondary'}  variant="primary" type="button">CANCEL</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button onClick={()=>handleSubmit()} className={'font-regular button-secondary'} type="button">CONTINUE</Button>
                                {/* disabled={continueBtn == 'darkBtn' ? true : false} style={continueBtn == 'darkBtn' ? {color:"#E79E1F",background: 'rgba(62, 62, 62, 0.9)',cursor:"not-allowed"}:{}} */}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        <Footer/>
    </>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    registerUser       : (data: any) => dispatch(registerUser(data)),
    countryList        : (data: any) => dispatch(countryList(data)),
    stateList          : (data: any) => dispatch(stateList(data)),
    cleanRegister      : (data: any) => dispatch({ type: AUTH.REGISTER_SUCCESS, data: null }),
    commonMessageAction: (data: any) => dispatch(commonMessageAction(data)),
    getCountryDialCode : (data: any) => dispatch(getCountryDialCode(data)),
});

const mapStateToProps = (state: any) => {
    return {
        registerData: (state.AuthReducer          && state.AuthReducer.registerData)     ? state.AuthReducer.registerData     : null,
        countryData : (state.CountryReducer       && state.CountryReducer.countryData)   ? state.CountryReducer.countryData   : null,
        message     : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
        stateData   : (state.CountryReducer       && state.CountryReducer.stateData)     ? state.CountryReducer.stateData     : null,
        countryDialCodeList : state.webReducer    && state.webReducer.countryDialCodeList? state.webReducer.countryDialCodeList: null,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

