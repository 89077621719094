import React, { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";
import img from '../../assets/images/blog-img1.webp';
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getBlogDetailById, getBlogFilterList} from "../../redux/actions/admin/adminBlogAction";
import { BASE_URLS } from "../../redux/constant";
import share from '../../assets/images/share.png';
import instagram from '../../assets/images/instagram.png';
import facebook from '../../assets/images/facebook_2.png';
import whatsapp from '../../assets/images/whatsapp.png';
import x from '../../assets/images/x.png';
import { FacebookIcon, FacebookShareButton, InstapaperIcon, InstapaperShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { InstagramEmbed } from "react-social-media-embed";
// import { Link } from 'react-router-dom';

const BlogDetail = (props: any) => {
    const currentUrl = window.location.href;
   
    const [isExpanded, setIsExpanded] = useState(true);

    const handleShareToggle = () => {
        setIsExpanded(prevState => !prevState);
    };
    const[blogData,setBlogData]=useState([])
    const[category,setCategory]=useState()
    const { id }                        = useParams<any>();
    const [Id,setId]=useState(id)
    useEffect(()=>{
        window.scrollTo(0, 0); 
    },[Id])
    const[BlogDetail,setBlodDetail]=useState<any>({})
    useEffect(()=>{
        if(Id)
        {
            props.getBlogDetailById(Id)  
        }
        },[Id])
    useEffect(()=>{
        if(props.getBlogDetailByIdData){
            window.scrollTo(0, 0); 
            setBlodDetail(props.getBlogDetailByIdData)
             props.getBlogFilterList(props.getBlogDetailByIdData.category)
            
            
        }
    },[props.getBlogDetailByIdData])
  
    useEffect(() => {
        if (props.filterListData) {
           
           let Blog=[]
            Blog= props.filterListData.filter((blog: any) => blog.id !== BlogDetail.id);
            
            setBlogData(() => props.filterListData.filter((blog: any) => blog.id !== BlogDetail.id));
        }
    }, [props.filterListData]);
    

  return (
    <Layout>
        <div className="blog-container">

            <div className="background-img align-items-start pt-4">
            
                <div className="container-width mt-3 mb-3">
                    {/* <div className="col-xl-6 col-lg-12 text-center">
                    <img className="blog-detail-image mb-2" src={img} alt="about us" />
                    </div> */}
                    <div className="container-width-80 wm-100">
                        <h2 className="theme-color mb-4 blog-title">{BlogDetail.heading} </h2>
                         <div className="social-sahre">
                        <ul>
                        <li> 
                    <a href="#" className="share-toggle" onClick={handleShareToggle}> <img src={share} alt="share" /> </a>
                </li>
                            {isExpanded && (<>
                            <li>
                            <FacebookShareButton url={currentUrl}>
                            <img src={facebook} alt="Twitter Icon" />

                            </FacebookShareButton>
                            </li>
                          
                            {/* <li> 
                                <a href='https://www.facebook.com/profile.php?id=100089157814393&mibextid=ZbWKwL'> <img src={facebook} alt="sahre" /> </a>
                            </li> */}
                            <li> 
                             <TwitterShareButton url={currentUrl}>
                             <img src={x} alt="Twitter Icon" />
                                {/* <TwitterIcon round={true}/> */}
                             </TwitterShareButton>
                                {/* <a href='https://instagram.com/beyond_box?igshid=ZDdkNTZiNTM='> <img src={instagram} alt="sahre" /> </a> */}
                            </li>
                            <li> 
                            <WhatsappShareButton url={currentUrl}>
                            <img src={whatsapp} alt="Twitter Icon" />

                            </WhatsappShareButton>
                                {/* <Link to=""> <img src={whatsapp} alt="sahre" /> </Link> */}
                            </li>
                            <li> 
                            <li>
 
</li>
  {/* <a href='http://www.twitter.com/BeyondBox_'> <img src={x} alt="sahre" /> </a> */}
                            </li>
                            </>)}
                        </ul>
                    </div>   
    
                        <img className="blog-detail-image mb-2" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${BlogDetail.image}`} alt="about us" />
                        <div className="aboutUsMainContent text-white mt-3">
                            <div className="blog-details" >
                                {BlogDetail.description ? (
                                    <span style={{wordWrap:"break-word"}} dangerouslySetInnerHTML={{ __html: BlogDetail.description }} />
                                ) : null}
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-img">
                <div className="container-width">
                    <div className="container-width-80 wm-100 py-container">
                    {blogData.length > 0 && <h2 className="theme-color">
                        Related Posts</h2>}
                        {blogData.length > 0?(blogData.map((blog:any )=> (
                            <div key={blog.id} className="bg-Main-Content text-white mb-5">
                                <div className="mobile-card-box">
                                    <div className="row">
                                        <div className="col-lg-3 blog-img-cover">
                                            <img  src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${blog.image}`} alt="" />
                                        </div>
                                        <div className="col-lg-9 pl-50 plx-0 mtx-15">
                                            <h3 className="theme-color"  style={{wordWrap:'break-word'}}>{blog.heading}</h3>
                                            {/* <p>{blog.description.length <= 300 ? blog.description : blog.description.slice(0, 300) + '...'}</p> */}
                                            <p style={{wordWrap:"break-word"}}>
                                                {blog.description.length <= 300 ? (
                                                <span dangerouslySetInnerHTML={{ __html: blog.description}}></span>
                                                ) : (
                                                    <span dangerouslySetInnerHTML={{ __html: blog.description.slice(0, 300) + '...' }}></span>
                                                )}
                                            </p>
                                            
                                            <Link to={`/admin-blog-detail/${blog.id}`}onClick={() => setId(blog.id)} className="view-post-link">
                                                View Post
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        )):
                        ("")
                        //       (<div className="bg-Main-Content text-white mb-5">
                        //           <h3 className="theme-color">  Sorry no related post found with this category</h3>
                        //       </div>
                        //   )
                        }

                    </div>
                </div>
            </div>
        </div>
  </Layout>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
    getBlogFilterList: (data: any) => dispatch(getBlogFilterList(data)),
    getBlogDetailById: (data: any) => dispatch(getBlogDetailById(data)),

  });
  const mapStateToProps = (state: any) => {
    return {
        filterListData: state.AdminBlogReducer && state.AdminBlogReducer.filterListData ? state.AdminBlogReducer.filterListData : null,
      getBlogDetailByIdData: state.AdminBlogReducer && state.AdminBlogReducer.getBlogDetailByIdData ? state.AdminBlogReducer.getBlogDetailByIdData : null,
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail);
