
export function blogAdminValidation(value: any) {
    const nameRegex = /^[a-zA-Z\s]*$/; // Only alphabets and spaces
    const keyRegex = /^[a-zA-Z0-9]*$/; // Alphanumeric characters only
  
    let errors: any = {};
    if (!value.blogHeading) {
        errors["blogHeading"] = "Blog Heading is required";
    }
    // } else if (!nameRegex.test(value.blogHeading)) {
    //     errors["blogHeading"] = "Enter a valid heading with alphabets only";
    // }
  
    if (!value.blogOrder) {
        errors["blogOrder"] = "Blog Order is required";
    }
 else if (/^0+$/.test(value.blogOrder)) {
    errors["blogOrder"] = "Blog Order cannot be zero";
} else if (!keyRegex.test(value.blogOrder)) {
        errors["blogOrder"] =  "Enter a valid key with alphabets and numbers only";
    }
else if (!/^\d{1,4}$/.test(value.blogOrder)) {
    errors["blogOrder"] = "Blog Order cannot be more than 4 digits";
}
  
    return errors;
  }
  