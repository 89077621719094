import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { getPlans } from "../../redux/actions/plansAction";


var converter = require('number-to-words');

const ViewPlans = (props: any) => {
  const[plans,setPlans]=useState<any>([])

  const [filteredData, setFilteredData] = useState([]);
let arr :any = [];



const updatePlanPrice = (id:any, newPriced:any, newCurrd:any) => {
  
  setPlans((prevPlans:any) => {
    if(prevPlans)
      {
        return prevPlans.map((plan:any )=> {
          if (plan.id === id) {
            return { ...plan, planPrice: newPriced, planCurr: newCurrd };
          }
          return plan;
        });
      }
    
  });

};



  
function filterDataForCountry(data:any, countryIn:any) {
 
  data.forEach((item:any) => {
    const currencyData=item.currencyCode;
   
    if(currencyData)
      {    
        const jsonData =JSON.parse(currencyData)
        if(jsonData)
          {
            
            jsonData.forEach((value:any) => {
              if(value.country===countryIn.trim().toUpperCase())
              arr.push({id :item.id,amount:value.amount,curr:value.curr,country:value.country})
            });
          }
      }
     
  });

 
  setFilteredData(arr);
 
}



  const [planDetails, setPlanDetails] = useState([]);
  const [userDetails, setUserDetails] = useState<any>({});
  const[country,setCountry]=useState<any>('');
 let nav=useNavigate();
  useEffect(()=>{
if(props.setCountryFromApi!=null)
  {
    setCountry(props.setCountryFromApi)
  }
  else
  {
    setCountry('UNITED STATES')
  }

  },[props.setCountryFromApi])
  useEffect(() => {
    setUserDetails(props.profileData);
   
  }, [props.profileData]);
  
  useEffect(() => {
    props.getPlans();
  }, []);
  

  useEffect(() => {
    const filteredPlanData = props.planDetailsData?.filter(
      ( plan:any )=> plan.planCode !== "100-PER-DISCOUNT"
     );
    setPlanDetails(filteredPlanData);
    if (country &&props.planDetailsData.length!=0){
      
      filterDataForCountry(filteredPlanData,country)
    }
  }, [props.planDetailsData]);
   
  const navToSignup=()=>{
    nav('/signup')
  }

  useEffect(()=>{
    const filteredPlanData = props.planDetailsData?.filter(
      ( plan:any )=> plan.planCode !== "100-PER-DISCOUNT"
     );
          setPlans(filteredPlanData)
      
        if(filteredData &&filteredData.length!=0)
          {
          
            filteredData.forEach((item:any )=> {
         
              updatePlanPrice(item.id, item.amount,item.curr);
            });
          }
      
      
    //
  
   
  },[  filteredData,props.planDetailsData])

// -----------------------------------------------------// 
 
  return (
   <>
     <Layout>
      <div className={localStorage.length ? "" : "charity_publicBox planListSpace"} style={{paddingLeft: "0", paddingRight: "0",}}>
        <div className="planContent">  
          <div className="welcome">
            <div className="plans">
              <Container>
                <div className="planList">
                  {plans && plans.map((plan: any) => {
                    return (
                      // <div key={plan.id} className="listItem" style={{border: "2px solid grey",borderRadius:"20px"}}>
                      //   <div className="listHead">
                      //     <h3>{plan.planType}</h3>
                      //     <p><span>Membership</span></p>
                      //     <div className="rate " style={{paddingTop:'80px'}}>
                      //     <b className="text-warning">{plan.planCurr}</b>{Number(plan.planPrice).toFixed(2)}
                      //       <span>{plan.planDuration !== 'Unlimited' && plan.planDuration}</span>
                      //     </div>
                      //   </div>
                      //   <ul>
                      //     <li>{converter.toWords(plan.publicBoxes).charAt(0).toUpperCase() + converter.toWords(plan.publicBoxes).slice(1)} public boxes</li>
                      //     <li style={{lineHeight:"15px"}}>{(plan.privateBoxes !== 'Unlimited') ? converter.toWords(plan.privateBoxes).charAt(0).toUpperCase() + converter.toWords(plan.privateBoxes).slice(1) : plan.privateBoxes } private boxes
                      //       <br/><small style={{fontSize:"13px",color:"#E79E1F"}}>{plan.planCode === 'FREE' ? '( One recipient per box )' :'( Up to five recipients per box )'}</small>                      
                      //     </li>
                      //     <li style={{lineHeight:"15px"}}>{plan.recievedBoxes} received boxes
                      //       <br/><small style={{fontSize:"13px",color:"#E79E1F"}}>{plan.planCode === 'FREE' ? '( Access received box content for 12 months )' : '( Unlimited access to received box content )' }</small>
                      //     </li>
                      //     {plan.planCode !== 'FREE' && <li>{`${(Number(plan.planStorage) / 1000000000)} GB storage plan`}</li>}
                      //     {plan.planCode === 'FREE' ? <li>Box Content – Text Messages</li> : <li>Box Content – Text Messages, Video, Audio, Documents, Images</li>}   
                      //     {plan.planCode !== "FREE" && <li>Cancel Any Time</li>} 
                      //   </ul>
                      //   <div className="text-center mt-auto">
                      //     <Button className="chooseBtn" onClick={navToSignup}>SELECT </Button>
                      //   </div>
                      // </div>
                      <div key={plan.id} className="listItem" style={{ border: "2px solid grey", borderRadius: "20px" }}>
                        <div className="listHead">
                          <h3>{plan.planType}</h3>
                          {plan.planCode !== "FREE" ? (
                            <p>
                              <span>Billed {plan.planCode === "FAMILY-PLUS-M" ? "Monthly" : "Annually"}</span> 
                            </p>):<p>
                              <br/>
                            </p>
                          }

                          <div className="rate " style={{paddingTop:'50px'}}>
                          {plan.planPrice === "0" ? 'No Cost' : (<><b>{plan.planCurr}</b>{Number(plan.planPrice).toFixed(2)}</>)}


                      {/* <span>{plan.planDuration !== 'Unlimited' && plan.planDuration}</span> */}
                         </div>
                         </div>
                          <div className="listBody">
                            <div className="d-flex justify-content-between">
                              <p>Create a Box</p>
                              <p>{plan.privateBoxes==='1'?'One':plan.privateBoxes}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Recipients</p>
                              <p>{plan.privateRecipients} per box</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Receive a Box</p>
                              <p>{plan.recievedBoxes} </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>View Received</p>
                              <p>{plan.recievedBoxAccessTime==='12 Months'?'1 Year':plan.recievedBoxAccessTime}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Plan Storage</p>
                            <p>
                              {`${(Number(plan.planStorage) / 1000000000) >= 1
                                ? (Number(plan.planStorage) / 1000000000).toFixed(2) + ' GB'
                                : (Number(plan.planStorage) / 1000000).toFixed(2) + ' MB'}`}
                            </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>In-App Ads</p>
                              <p>{plan.inAppAds}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Box Types</p>
                              <p>{plan.boxType}</p>
                            </div>
                          </div>
                          <div className="text-center mt-auto mb-3">
                            <Button className="chooseBtn" onClick={navToSignup}>Select </Button>
                          </div>
                        </div>

                    );
                  })}
                </div>
              </Container>
            </div>
          </div>
        </div> 
      </div>
    </Layout>
  </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  // getProfile     : (data: any) => dispatch(getProfile(data)),
  getPlans       : (data: any) => dispatch(getPlans(data)),
  // selectFreePlan : (data: any) => dispatch(selectFreePlan(data)),
  // getCurrentPlan : (data: any) => dispatch(getCurrentPlan(data)),
  // cancelPlan     : (data: any) => dispatch(cancelPlan(data)),
  // getAllPlan     : (data: any) => dispatch(getAllPlan(data))
});

const mapStateToProps = (state: any) => {
  return {
    planDetailsData       : state.PlansReducer       && state.PlansReducer.planDetailsData ? state.PlansReducer.planDetailsData : null,
    profileData           : state.ProfilePageReducer && state.ProfilePageReducer.profileData ? state.ProfilePageReducer.profileData : null,
    setCountryFromApi : state.CountryReducer && state.CountryReducer.setCountryFromApi ? state.CountryReducer.setCountryFromApi : null,
    // selectPlanDetailsData : state.PlansReducer       && state.PlansReducer.selectPlanDetailsData ? state.PlansReducer.selectPlanDetailsData : null,
    // message               : state.CommonMessageReducer && state.CommonMessageReducer.message ? state.CommonMessageReducer.message : null,
    // currentPlanDetailsData: state.PlansReducer && state.PlansReducer.currentPlanDetailsData ? state.PlansReducer.currentPlanDetailsData : null,
    // cancelPlanDetailsData : state.PlansReducer && state.PlansReducer.cancelPlanDetailsData ? state.PlansReducer.cancelPlanDetailsData : null,
    // allPlanDetail         : state.PlansReducer && state.PlansReducer.allPlanDetail ? state.PlansReducer.allPlanDetail : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPlans);
