import {Col, Container, Row } from 'react-bootstrap';
import logo from '../../assets/images/BB-F.png';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import instagram from "../../assets/images/insta.png";

export const Footer = (props: any) => {
    let userData                      = localStorage.getItem('profileData');
    const [showTopBtn, setShowTopBtn] = useState(false);
    const dte = new Date();
    const getCurrYr = dte.getFullYear();
    const loginData    = localStorage.getItem('userData');

    const scrollTop = () => {   window.scrollTo({ top: 0,behavior: "smooth" }); }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
   
//-------------------------------------------------------------------//

return (
    <footer>
        <Container>
            {showTopBtn &&
                <a className="scroll-to-top" onClick={scrollTop}>
                    <div className='icon-position icon-style' style={{fontSize:"35px",fontWeight:'800'}}>&#8673;
                        <div style={{fontSize:"13px",fontWeight:'800',margin:"-70px 0px 0px 0px"}}>TOP</div>
                    </div>
                </a>
            }
            <div className="row footer-main-row" style={userData ? {paddingLeft:"10%", marginBottom:"0px !important"}:{}}>
                <div className="col-lg-4">
                    <div className="footerlogo">
                        <img src={logo} className="App-logo" alt="logo" width="99"/>
                    </div>
                    <div className="footerContact">
                        <p>A sanctuary for all where life, love, and legacy meet technology, creating connections that transcend time.</p>
                        <p><a href="mailto:Info@beyondbox.org">Info@beyondbox.org</a></p>
                        <p>Copyright &copy; BeyondBox {getCurrYr}
                            {/* {loginData &&
                                <>
                                    <br/>
                                    <small className='text-light' style={{fontSize:'12px'}}>Box Cover Image Credit&nbsp;:&nbsp; <span className='text-warning'>NASA, STScl</span></small>
                                </>
                            } */}
                        </p>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-md-3">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><Link to="/privacy_Policy">Privacy Policy</Link></li>
                                <li><Link to="/terms-and-condition">Terms &amp; Conditions</Link></li>
                                <li><Link to="/frequently-asked-questions">FAQ's</Link></li>
                                <li><Link to="/blog">Blogs</Link></li>
                            </ul>
                        </div>
                      
                        <div className="col-md-3">
                            <h4>Social Media</h4>
                            <ul className='footerSocial'>
                                <li className='text-light' style={{fontSize:'20px'}}>
                                    <Row>
                                        <Col className='col-auto px-1 ms-1'>
                                            <a href='https://www.facebook.com/profile.php?id=100089157814393&mibextid=ZbWKwL'><img style={{height:'30px'}} src={facebook}/></a>
                                        </Col>
                                        <Col className='col-auto px-1'>
                                            <a href='http://www.twitter.com/BeyondBox_'><img style={{height:'30px'}} src={twitter}/></a>
                                        </Col>
                                        <Col className='col-auto px-1'>
                                            <a href='https://instagram.com/beyond_box?igshid=ZDdkNTZiNTM='><img style={{height:'30px'}} src={instagram} /></a>
                                        </Col>
                                    </Row>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h4>About</h4>
                            <ul>
                                <li><Link to="/about_us">About Us</Link></li>
                                <li><Link to="/contact_us">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h4>Sitemap</h4>
                            <ul>
                                <li><Link to="/login">Sign In</Link></li>
                                <li><Link to="/foundation">Foundation</Link></li>
                                <li><Link to="/box_records">Box Records</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </footer>
    );
}

export default (Footer);
