import Api from "../apiCall";
import { API_URLS } from "../../constant/index";

export const getAdminBoxCoverPhotoService= async (params) => {
  const catagory=params;
  //let url = API_URLS.GET_ADMIN_COVER_PHOTOS_URL;
  const result = await Api.get(API_URLS.GET_ADMIN_COVER_PHOTOS_URL);
  // let newurl = url.replace("{id}", catagory);
  // const result = await Api.get(newurl, params);
  return result;
};

export const getAddAdminBoxCoverPhotoService= async (params) => {
  const result = await Api.post(API_URLS.ADD_ADMIN_COVER_PHOTOS_URL, params);
  return result;
};
export const adminReleaseBox= async (params) => {
  const userId = params._parts.userId;
  let url = API_URLS.PUT_ADMIN_RELEASE_BOX_URL;
  let newurl = url.replace("{userId}", userId);
  const result = await Api.put(newurl, params);
  return result;
};


export const getAdminBoxCoverPhotoByIdService = async (params) => {
  const photoId = params.id;
  let url = API_URLS.GET_ADMIN_COVER_PHOTO_BY_ID_URL;
  let newurl = url.replace("{id}", photoId);
  const result = await Api.get(newurl, params);
  return result;
}; 

export const removeAdminBoxCoverPhotoService = async (params) => {
  const photoId = params.id;
  let url = API_URLS.REMOVE_ADMIN_COVER_PHOTO_URL;
  let newurl = url.replace("{id}", photoId);
  const result = await Api.delete(newurl, params);
  return result;
}; 

