// export function addMarketerValidation(value: any) {
//     const emregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//     const phoneRegex = /^((?:\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;
//     let errors: any = {};
//     if (!value.mName) {
//       errors["mName"] = "Marketer name is required";
//     }
//     if (!value.mPayfastPassphrase) {
//       errors["mPayfastPassphrase"] = "Passphrase  is required";
//     }
   
//     if (!value.mContactNumber) {
//       errors["mContactNumber"] = "Marketer Phone is required";
//     } else if (!phoneRegex.test(value.mContactNumber)) {
//       errors["mContactNumber"] = "Enter a valid  mobile number";
//     }
//     //
//     if (!value.mMerchantId) {
//         errors["mMerchantId"] = "Marketer Merchant Id is required";
//       } 
//       if (!value.mMerchantKey) {
//         errors["mMerchantKey"] = "Marketer Merchant Key is required";
//       } 

//     if (!value.mEmail) {
//         errors["mEmail"] = "Marketer email is required";
//       } else if (!emregex.test(value.mEmail)) {
//         errors["mEmail"] = "Enter a valid email";
//       }
//     // if (!value.mBankName) {
//     //   errors["mBankName"] = "Marketer bank name is required";
//     // }
//     // if (!value.mIntcode) {
//     //     errors["mIntcode"] = "Marketer Int code is required";
//     //   }
//       // if (!value.mAccountType) {
//       //   errors["mAccountType"] = "Account type is required";
//       // }
//       // if (!value.mBranchCode) {
//       //   errors["mBranchCode"] = "Branch code is required";
//       // }
   
  
//     return errors;
//   }
export function addMarketerValidation(value: any) {
  const emregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneRegex = /^((?:\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;
  const nameRegex = /^[a-zA-Z\s]*$/; // Only alphabets and spaces
  const keyRegex = /^[a-zA-Z0-9]*$/; // Alphanumeric characters only

  let errors: any = {};
  if (!value.mName) {
      errors["mName"] = "Marketer name is required";
  } else if (!nameRegex.test(value.mName)) {
      errors["mName"] = "Enter a valid name with alphabets only";
  }

  if (!value.mPayfastPassphrase) {
    errors["mPayfastPassphrase"] = "Passphrase is required";
} else if (!keyRegex.test(value.mPayfastPassphrase)) {
    errors["mPayfastPassphrase"] = "Enter a valid key with alphabets and numbers only";
}
  if (!value.mContactNumber) {
      errors["mContactNumber"] = "Marketer Phone is required";
  } else if (!phoneRegex.test(value.mContactNumber)) {
      errors["mContactNumber"] = "Enter a valid mobile number";
  }

  if (!value.mMerchantId) {
    errors["mMerchantId"] = "Marketer Merchant Id is required";
} else if (!keyRegex.test(value.mMerchantId)) {
    errors["mMerchantId"] = "Enter a valid key with alphabets and numbers only";
}

  if (!value.mMerchantKey) {
      errors["mMerchantKey"] = "Marketer Merchant Key is required";
  } else if (!keyRegex.test(value.mMerchantKey)) {
      errors["mMerchantKey"] = "Enter a valid key with alphabets and numbers only";
  }

  if (!value.mEmail) {
      errors["mEmail"] = "Marketer email is required";
  } else if (!emregex.test(value.mEmail)) {
      errors["mEmail"] = "Enter a valid email";
  }

  return errors;
}
