import React, {  useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Form } from 'react-bootstrap';
import { authoriseUserByOTP, clearCommoneMessage, loginUser,verifyUserForBoxReleaseFromDifferentEmail } from "../../redux/actions/authAction";
import logo from '../../assets/images/BB.png';
import eyeLogo from '../../assets/images/eye.png';
import slashedEye from '../../assets/images/unseen.png';
import { Link, useNavigate } from "react-router-dom";
import { loginFormValidation } from '../../validations/login-form';
import Footer from "../../layout/elements/footer";
import { commonMessageAction } from "../../redux/actions/messageAction";
import { getProfile } from "../../redux/actions/profileAction";
var CryptoJS = require("crypto-js");

const Login = (props: any) => {
  let   [loginForm,       setLoginForm]       = React.useState({ email: '', password: '', });
  let   [errors,          setErrors]          = React.useState({ email: '', password: '', });
  let   [checkValidation, setCheckValidation] = React.useState({ email: false, password: false, });
  let   [eyeImg,          seteyeImg]          = React.useState(<img src={slashedEye} alt="" />);
  let   [isValid,         setIsValid]         = React.useState(false);
  const [passwordType,    setPasswordType]    = useState("password");
  const redirectTo                            = useNavigate();
  const url                                   = window.location.pathname.split('/');
  const [loginData,       setLoginData]       = useState({email:'',boxId:0}); 
  const [formData,            setFormData]            = useState({OTP:''});
 // const [errors,              setErrors]              = useState({OTPErr:'',responseError:''});
  const [successMessage,      setSuccessMessage]      = useState({responseSuccess:''});
  const [loading,             setLoading]             = useState(false);
  useEffect(()=>{
    localStorage.clear();
    props.commonMessageAction();
    var ciphertext = (url && url[2] ? url[2] : '');
        if(url[1] === 'login' && ciphertext){
            var bytes  = CryptoJS.AES.decrypt(decodeURIComponent(ciphertext), 'BB Dev9930');
            var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setLoginData({email:(originalText && originalText.recipientEmail ? originalText.recipientEmail : ''),boxId:(originalText && originalText.recipientboxID ? originalText.recipientboxID : 0)})
            
            if(url[1] === 'login' && ciphertext && !originalText && originalText.recipientEmail){
              redirectTo('*') 
           }
        }
  },[])

  const togglePassword = () => {
    if (passwordType === "password") {
      seteyeImg(<img src={eyeLogo} alt="" />);
      setPasswordType("text")
      return;
    }else{
      seteyeImg(<img src={slashedEye} alt="" />);
    }
    setPasswordType("password")
  }

  const handleChange = (event: any) => {
    setCheckValidation({ ...checkValidation, [event.target.name]: true });
    setLoginForm({ ...loginForm, [event.target.name]: event.target.value })
  };

 useEffect(() => {
    const validationErrors = loginFormValidation(loginForm);
    isValid = Object.keys(validationErrors).length === 0;
    setIsValid(isValid);
    setErrors(validationErrors);
  }, [loginForm]);


  const handleSubmit = (e:any) => {
    e.preventDefault();
    const validationErrors = loginFormValidation(loginForm);
    isValid = Object.keys(validationErrors).length === 0;
    setIsValid(isValid);
    setErrors(validationErrors);
    if(isValid)
    {
      setLoading(true)
    }
    if(validationErrors){
      setCheckValidation({email:true,password:true})
    }
    if (isValid && !url[2]) {
      props.loginUser(loginForm);
    }else if(isValid && url[2]){
      props.verifyUser(loginForm);
    }
  }

  useEffect(() =>{
    if(props.message && !url[2]){
      if(props.message.isActive === 0){
        localStorage.setItem('email',loginForm.email);
        localStorage.setItem('id',props.message.userId);
        redirectTo('/user-verification',{state : {id:props.message.userId}});
      }else if(props.message.isActive === 1){

        let loginFormData = JSON.stringify(loginForm);
        const loginFormEncodedData = encodeURIComponent(
          CryptoJS.AES.encrypt(loginFormData, "Bey0ndB0xL0g!n@123").toString()
        );
        localStorage.setItem('encryptedData',loginFormEncodedData)
        if(!props.message.is2FAEnabled)
        {
         let payLoad = {
           userId   : props.message.userId,
           OTP      : formData.OTP,
            is2FAEnabled:props.message.is2FAEnabled,
            isEmailEnabled:props.message.isEmailEnabled,
           isSMSEnabled:props.message.isSMSEnabled
           }
         props.authoriseUserByOTP(payLoad);
        
        }    else
        {
          redirectTo('/user-authorisation',{state : {id:props.message.userId,is2FAEnabled:props.message.is2FAEnabled,
            isEmailEnabled:props.message.isEmailEnabled,
           isSMSEnabled:props.message.isSMSEnabled}});
        }
      }
    }
  },[props.message])

  useEffect(() =>{
    if(props.verifyUserData && url[2]){
      if(props.verifyUserData.user_id){
        redirectTo('/user-box-release', 
          {state : 
            {
              userId : props.verifyUserData.user_id,
              email  : loginData.email,
              boxId  : loginData.boxId
            }
          })
      }
    }
  },[props.verifyUserData])
// changes for 2fa 
useEffect(() =>{

  if(props.message){
      // if(props.message.code === 200 && props.message.status === 'success'){
      //     // setTimeout(() =>{
      //     //     setLoading(false);
      //     //     //setErrors({...errors,responseError:''});
      //     //     // setSuccessMessage({responseSuccess:props.message.message})
      //     //     setSuccessMessage({responseSuccess:'OTP sent to your registered e-mail and mobile number'});
      //     //     props.clearMessageProps(null);
      //     // },1000);
      // }
      if(props.message.Token){
        setTimeout(() =>{
          setLoading(false);
         // setErrors({...errors,responseError:''});
          setFormData({OTP:''})
          setSuccessMessage({responseSuccess:'Loggin-In... please wait!'})
          props.getProfile();
      },1000)
      }
      else if(props.message.code === 404 && props.message.status === 'error' ){
          setTimeout(() =>{
              setLoading(false);
              setSuccessMessage({responseSuccess:''})
              //setErrors({...errors,responseError:props.message.message})
              props.clearMessageProps(null);
          },1000)
      }
      else if(props.message.status==='error' ){

        setLoading(false);
      }
  }
},[props.message])

useEffect(() => {
  const userData     = JSON.parse(localStorage.getItem('userData')||'{}');
  
  const access_token = (userData && userData.Token)?userData.Token:'';
  if(access_token){
    if (props.profileData) {
      localStorage.setItem('profileData', JSON.stringify(props.profileData));
      if(props.profileData.roles && props.profileData.roles[0] === 'Admin'){
        setTimeout(() =>{
          redirectTo('/admin')    
        },800)
      }else if (props.profileData.verified) {
        setTimeout(() =>{
          redirectTo('/homepage');    
        },800)
      } else {
        setTimeout(() =>{
          redirectTo('/edit-profile');    
        },800)
      }
    }
  }
}, [props.profileData])

//
//-------------------------------------------------------------------------------------------//

  return (
    <>
      <div className="createAccount">
        <div className="userDetailBlock">
          <div className="formWrap align-items-center d-md-flex flex-column h-100 justify-content-center">
            <div className="text-center">
              <Link to="/">
                <img src={logo} className="App-logo" alt="logo" />
              </Link>
            </div><br/>
            <h2 className="text-center">Welcome to <br/><span style={{color:'#E79E1F'}}>Beyond</span><span>Box</span></h2>
            {props.message && <div className={(props.message && props.message.status === 'success') ? 'text-success' : 'text-danger'}>{props.message.data === 'Error verifying token : invalid signature' ? '' : props.message.data}</div>}
            <Form className=" w-100">
            {loading ? <p className="lds-dual-ring d-block" ></p>
            :
              <>
                {(errors && !successMessage.responseSuccess )}
                {successMessage && successMessage.responseSuccess && <p className="text-success">{successMessage.responseSuccess}</p>}
              </>
            }
              <Form.Group className="formRow">
                <Form.Label>Email</Form.Label>
                <Form.Control name='email' id="login_email" type="email" value={(loginForm && loginForm.email) ? loginForm.email : ''} onChange={handleChange} placeholder="Email" />
                {(checkValidation.email && errors.email) && <p className="text-danger">{errors.email}</p>}
              </Form.Group>
              
              <Form.Group className="formRow mb-0 passwordHide">
                <Form.Label>Password</Form.Label>
                <Form.Control name="password" id="login_password" type={passwordType} value={loginForm.password} onChange={handleChange} placeholder="Password" />
                <Button className="hideIcon" onClick={togglePassword} >{eyeImg}</Button>
              </Form.Group>
              {(checkValidation.password && errors.password && !loginForm.password) && <p className="text-danger">{errors.password}</p>}
           
              <Form.Group className="formRow inputCheck justify-content-end pt-1">
                <Link to="/forgot-password" className="forgetPass" > Forgot Password</Link>
              </Form.Group>
              <div className="d-flex justify-content-between">
                <Button onClick={handleSubmit} className="font-regular button-secondary me-4" type="submit"> LOGIN </Button>
                <Link to="/signup" style={{ textDecoration: 'none' }}>
                  <Button className="font-regular button-secondary" type="submit"> CREATE PROFILE </Button>
                </Link>
           
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

//-------------------------------------------------------------------------//

const mapDispatchToProps = (dispatch: any) => ({
  authoriseUserByOTP : (data: any) => dispatch(authoriseUserByOTP(data)),
  getProfile         : (data: any) => dispatch(getProfile(data)),
  loginUser           : (data: any) => dispatch(loginUser(data)),
  commonMessageAction : (data: any) => dispatch(commonMessageAction(data)),
  verifyUser          : (data: any) => dispatch(verifyUserForBoxReleaseFromDifferentEmail(data)),
  clearMessageProps  : (data: any) => dispatch(clearCommoneMessage(data)),
});

const mapStateToProps = (state: any) => {
  return {
    message        : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
    profileData : (state.ProfilePageReducer && state.ProfilePageReducer.profileData) ? state.ProfilePageReducer.profileData : null,
    verifyUserData : (state.AuthReducer && state.AuthReducer.verifyUserData) ? state.AuthReducer.verifyUserData : null,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
