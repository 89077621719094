import { ADMIN_BLOG } from "../../constant/index";
const initialState = {
    loading: false,
    addAdminBlogData:null,
    blogListData:null,
    filterListData:null,
  }
  const AdminBlogReducer = (state= initialState, action)=>{
   
    switch (action.type){
        
        case ADMIN_BLOG.SET_ADD_ADMIN_BLOG_TO_STATE:
          
          return {
            ...state,
            addAdminBlogData: action.data,
            loading: false,
            
          };
          case ADMIN_BLOG.SET_GET_BLOG_LIST_TO_STATE:
          
          return {
            ...state,
            blogListData: action.data,
            loading: false,
            
          };
          //filter

          case ADMIN_BLOG.SET_GET_BLOG_FILTER_LIST_TO_STATE:
          
          return {
            ...state,
            filterListData: action.data,
            loading: false,
            
          };
          //DELETE_ADMIN_BLOG,
          case ADMIN_BLOG.SET_DELETE_ADMIN_BLOG_TO_STATE:
          
            return {
              ...state,
              deleteAdminBlogData: action.data,
              loading: false,
              
            };
            //GET_BLOG_DETAIL_BY_ID
            case ADMIN_BLOG.SET_GET_BLOG_DETAIL_BY_ID_TO_STATE:
          
            return {
              ...state,
              getBlogDetailByIdData: action.data,
              loading: false,
              
            };
            //UPDATE_ADMIN_BLOG
            case ADMIN_BLOG.SET_UPDATE_ADMIN_BLOG_TO_STATE:
          
            return {
              ...state,
              updateAdminBlogData: action.data,
              loading: false,
              
            };
  
   

            
          default:
            return state;
    }

  }

  export default AdminBlogReducer;