import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { Container, Card } from 'react-bootstrap';
// import img1 from '../../assets/images/img1.png';
import img2 from '../../assets/images/img2.png';
import img3 from '../../assets/images/bbheart.webp';
import img4 from '../../assets/images/home-banner.webp';
import img5 from '../../assets/images/bbapp.svg';
import img6 from '../../assets/images/hero-mobile.webp';
import blogimg1 from '../../assets/images/blog-image1.svg';
import blogimg2 from '../../assets/images/blog-image2.svg';
import blogimg3 from '../../assets/images/blog-img3.svg';
import blogimg4 from '../../assets/images/blog-image4.svg';
import appstore from '../../assets/images/appstore.png';
import googleplay from '../../assets/images/googleplay.png';
import img from '../../assets/images/blog-banner.svg';
import space from '../../assets/images/space.png';
import beyondbox from '../../assets/images/beyondbox.png';
import infobox from '../../assets/images/infobox.png';
import visionbox from '../../assets/images/visionbox.png';
import familybox from '../../assets/images/familybox.png';
import { Carousel } from 'react-bootstrap';

const Landing = () => {
  const redirectTo = useNavigate()
  const [email, setEmail] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);

  const [name, setName] = useState('');
  const handleSubmit = (event:any) => {
   event.preventDefault()
    if(name && email)
      {
        
        redirectTo('/signup', { state: { name, email } });
      }
      else{
        setButtonClicked(true);
      }
   
  }
  localStorage.clear();
  const vId: any = "SiRiNSBSOdw";

  return (
    <Layout>
      <div className="intro">
        <div className="text-center half-section">
          <div className="center-content sm-mw-100">
            <h1 className="heading-title">Welcome to <span className="theme-color">Beyond</span>Box</h1>
            <h2 className="heading-title fw-bolder"> Where love lives forever</h2>
            {/* <p>Create digital boxes filled with treasured messages, memories, media and more, to be released after you have moved on from this world.</p> */}
            {/* <Link to="">Read more</Link> */}
            {/* <div className="d-flex justify-content-center">
              <Link to="/login" className="yellowBtn btn btn-primary me-4">Sign in </Link>
              <Link to="/signup" className="yellowBtn btn btn-primary">Create Profile</Link>
            </div> */}
            <div className="d-none d-xl-block"> 
              <div className="text-start hero-content-para-cover mb-4">
                <h5 className="theme-color">Preserve Your Legacy</h5>
                <p className="text-white">
                  Craft personalized digital treasure troves filled with your final
                  words of love, timeless memories, life stories and guidance for
                  your family and friends.
                </p>
              </div>
              <div className="text-start hero-content-para-cover mb-4">
                <h5 className="theme-color">Secure & Private</h5>
                <p className="text-white">
                End-to-end encryption ensures your most intimate memories and messages are securely preserved for your chosen recipients.
                </p>
              </div>
              <div className="text-start hero-content-para-cover mb-4">
                <h5 className="theme-color">Your Ultimate Gift</h5>
                <p className="text-white">
                Your treasured gifts will have an immense impact on your loved ones, aiding them in grieving, healing, and celebrating your life, guiding them through a future without you.
                </p>
              </div>
              <div className="text-start hero-content-para-cover mb-4">
                <h5 className="theme-color">A Platform For Good</h5>
                <p className="text-white">
                Join our mission to support charitable causes worldwide through the BeyondBox Foundation, extending your legacy beyond personal connections to global impact.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="img-Cover landing-banner">
          <img className="img-fluid for-lg" src={img4} alt="" />
          <img className="img-fluid for-sm" src={img6} alt="" />
        </div>
        <div className='form-landing'>
          <form onSubmit={handleSubmit}>
            <h4 style={{ color: "white", fontWeight: 'bold' }} className="mt-2">Create timeless treasures with the <span className='theme-color'>Beyond</span> Box App</h4>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Enter your name</label>
              <input
                type="text"
                className="form-control"

                placeholder="Name and Surname"
                value={name}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const regex = /^[A-Za-z\s]*$/;
                  if (regex.test(inputValue)) {
                    setName(inputValue);
                  }
                }}
              />

            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Enter your email</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button type="submit" className="mt-3 button-secondary font-regular btn btn-primary">
              Create Profile

            </button>

            <div className="mt-2">
              {((name === '' || email === '') && buttonClicked)  && (
                      <small className="form-text text-danger">
                        Please enter name and email to create profile.
                      </small>
                    )}
              <p style={{ color: 'whitesmoke' }}>
                {/* Already have an account? <Link to="/login">Log in</Link> */}
              </p>
            </div>
          </form>
        </div>
      </div>

      <div className="howItWorks">
        <div className="container-width">
          <h2 className="text-center heading-title fw-bolder">Discover The Art Of Legacy Crafting</h2>
          <div style={{marginBottom:'100px' }}className="visionWrap">
    <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6 less-width">
            <div className="card-blog card">
                <img className="card-img-top" src={beyondbox}/>
                <div className="card-body text-center">
                    <h3 className="theme-color">Beyond box</h3>
                    <p className="card-text">Capture your final goodbyes, boundless love, timeless wisdom, and heartfelt guidance. Fill this box with precious memories, favorite quotes, songs, and messages that will comfort and inspire your loved ones forever.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 less-width">
            <div className="card-blog card">
                <img className="card-img-top" src={infobox}/>
                <div className="card-body text-center">
                    <h3 className="theme-color">Info-box</h3>
                    <p className="card-text">Empower your loved ones with vital information to seamlessly manage your estate and affairs. Include the location of your will, financial advisor details, and crucial guidelines to ease their journey after your passing.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 less-width">
            <div className="card-blog card">
                <img className="card-img-top" src={visionbox} />
                <div className="card-body text-center">
                    <h3 className="theme-color">Vision-box</h3>
                    <p className="card-text">Celebrate your family’s rich tapestry, honoring members both past and present. Share stories, cherished values, heritage, and photos of beloved family members, creating a legacy that will endure through generations.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 less-width">
            <div className="card-blog card">
                <img className="card-img-top" src={familybox}/>
                <div className="card-body text-center">
                    <h3 className="theme-color">Family-box</h3>
                    <p className="card-text">Illuminate your life's dreams and aspirations. Document your goals, hopes, and achievements, creating a beacon of inspiration and motivation for yourself and those who follow in your footsteps.</p>
                </div>
            </div>
        </div>
        <div className="col-sm-12 mt-3 text-center">
            <a className="button-secondary" href="">
                <span> Join BeyondBox</span>
            </a>
        </div>
    </div>
</div>
          <div className="videoContainer" style={{ justifyContent: "center" }}>
            <div className="">
              {/* <img src={youtubePlaceholder} className="App-logo" alt="logo" /> */}
              <iframe id="youtube-iframe" height='600px' src={`https://youtube.com/embed/${vId}?controls=0&showinfo=0&title=0`} allowFullScreen></iframe>
            </div>
            {/* <div className="videoBlock">
              <img src={youtubePlaceholder} className="App-logo" alt="logo" /> 
            </div> */}
          </div>
          <div className='d-block d-lg-none'>
            <div className='text-start hero-content-para-cover mb-lg-4'>
              <div className='mobile-card-box'>
                <h5 className='theme-color'>Preserve Your Legacy</h5>
                <p className="text-white">Craft personalized digital treasure troves filled with your final words of love, timeless memories, life stories and guidance for your family and friends.</p>
              </div>
            </div>
            <div className='text-start hero-content-para-cover mb-lg-4'>
              <div className='mobile-card-box'>
                <h5 className='theme-color'>Secure & Private</h5>
                <p className="text-white">End-to-end encryption ensures your most intimate memories and messages are securely preserved for your chosen recipients.</p>
              </div>
            </div>
            <div className='text-start hero-content-para-cover mb-lg-4'>
              <div className='mobile-card-box'>
                <h5 className='theme-color'>Your Ultimate Gift</h5>
                <p className="text-white">Your treasured gifts will have an immense impact on your loved ones, aiding them in grieving, healing, and celebrating your life, guiding them through a future without you.</p>
              </div>
            </div>
            <div className='text-start hero-content-para-cover mb-lg-4'>
              <div className='mobile-card-box'>
                <h5 className='theme-color'>A Platform For Good</h5>
                <p className="text-white">Join our mission to support charitable causes worldwide through the BeyondBox Foundation, extending your legacy beyond personal connections to global impact.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gift text-center text-lg-start">
        <div className="container-width">
          <div className="row flex-md-row-reverse">
            <div className="col-xl-6 pe-lg-5 text-center">
            <h2 className="heading-title fw-bolder mb-4 helvetica-world-bold mobile-landing">Get the <span className="theme-color">Beyond</span>Box App</h2>
              <img className="app-img" src={img5} alt="" />
              <div className="btnBlock mt-5 mobile-landing">
                <a href="https://apps.apple.com/us/app/beyondbox-messenger/id6448447137" className="appstore pointer col-auto">
                  <img src={googleplay} alt="appleplay" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.beyondboxapp" className="googleplay">
                  <img src={appstore} alt="googleplay" />
                </a>
              </div>
            </div>
            <div className="col-xl-6">
            <h2 className="heading-title fw-bolder mb-4 helvetica-world-bold web-landing">Get the <span className="theme-color">Beyond</span>Box App</h2>
              <p className="checkBefore mobile-card-box">Access and manage your timeless treasure boxes anytime, anywhere, right from your mobile device.</p>
              <p className="checkBefore mobile-card-box text-warning-mobile">Create and view boxes discreetly, ensuring your moments and messages remain private and protected.</p>
              <p className="checkBefore mobile-card-box">Easily record and upload videos, snap photos, and record audio messages discretely within the app, capturing your legacy in real-time.</p>
              <p className="checkBefore mobile-card-box text-warning-mobile">Navigate through a user-friendly interface to effortlessly add content, manage boxes, and connect with loved ones.</p>
              {/* <Link to="/view-plans" className="planBtn"><span>View Plans</span></Link> */}
              <div className="btnBlock mt-5 web-landing ">
                <a href="https://apps.apple.com/us/app/beyondbox-messenger/id6448447137" className="appstore pointer col-auto">
                  <img src={googleplay} alt="appleplay" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.beyondboxapp" className="googleplay">
                  <img src={appstore} alt="googleplay" />
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="gift text-center text-lg-start">
          <div className="container-width">
            <div className="row flex-md-row-reverse">
              <div className="col-xl-6 pe-lg-5 text-center">
              <h2 className="heading-title fw-bolder mb-4 helvetica-world-bold mobile-landing"style={{ textAlign: 'center'}}>A Safe Space Philosophy</h2>
                  <img className="img-fluid my-3 mx-auto radiusImg d-block" src={space} alt="" />
              </div>
              
              <div className="col-xl-6">
                <h2 className="heading-title fw-bolder mb-4 helvetica-world-bold web-landing " style={{ textAlign: 'center'}}>A Safe Space Philosophy
</h2>
                <p className="para-heart-img mobile-card-box mb-lg-4">At BeyondBox, our mission is to preserve your cherished memories and personal legacy in a secure environment. This commitment is embodied in our Safe Space Philosophy, a unique approach to data security that prioritizes your peace of mind. By promoting the storage of non-sensitive information and designing the platform in a way that enables this practice, we reduce the risk of cyber threats and make BeyondBox less attractive to potential attackers. This innovative approach, together with robust security measures like end-to-end encryption and multi-factor authentication, aims to provide a sanctuary for you to confidently create, preserve, and share your legacy with the ones who matter most.</p>
                <div className="text-center mt-3"><Link to="/blog"className="button-secondary" ><span>Learn More</span></Link></div>
              </div>

            </div>
          </div>
        </div>
            {/*  */}
        <div className="gift text-center text-lg-start">
          <div className="container-width">
            <div className="row flex-md-row-reverse">
              <div className="col-xl-6 pe-lg-5 text-center">
              <h2 className="heading-title fw-bolder mb-4 helvetica-world-bold mobile-landing"style={{ textAlign: 'center'}}>"Live Before You Leave"<br></br><span >The<span className="theme-color">Beyond</span>Box<b>Blog</b></span></h2>
                  <img className="img-fluid my-3 mx-auto radiusImg d-block  w-100" src={img} alt="" />
              </div>
              <div className="col-xl-6">
                <h2 className="heading-title fw-bolder mb-4 helvetica-world-bold web-landing" style={{ textAlign: 'center'}}>"Live Before You Leave"<br></br><span >The<span className="theme-color">Beyond</span>Box<b>Blog</b>
</span></h2>
                <p className="para-heart-img mobile-card-box mb-lg-4">Live Before You Leave captures the essence of BeyondBox's mission to foster a community united by a shared appreciation for the preciousness of life.On this blog,you will find content crafted to educate,enlighten, and inspire you to live joyfully and create memories and stories that will shape the legacy you choose to leave behind.</p>
                <div className="text-center mt-3"><Link to="/blog"className="button-secondary" ><span>Explore</span></Link></div>
              </div>

            </div>
          </div>
        </div>

            {/*  */}

      <div className="download" style={{ display: 'none' }}>
        <Container>
          <h2>Get the BeyondBox App</h2>
          <div className="image">
            <img className="img-fluid" src={img2} alt="" />
          </div>
          <div className="btnBlock text-center">
            <Link to="https://apps.apple.com/us/app/beyondbox-messenger/id6448447137" className="appstore pointer col-auto" >
              <img src={appstore} alt="" />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.beyondboxapp" className="googleplay">
              <img src={googleplay} alt="" />
            </Link>
          </div>
        </Container>
      </div>

      <div className="gift text-center text-lg-start">
        <div className="container-width">
          <div className="row align-items-center flex-md-row-reverse">
            <div className="col-lg-6">
            <h2 className="heading-sub-title fw-bolder mobile-landing">The Heart of <span className="theme-color">Beyond</span>Box</h2>
              <img className="img-fluid radiusImg" src={img3} alt="" />
            </div>
            <div className="col-lg-6">
              <h2 className="heading-sub-title fw-bolder web-landing">The Heart of <span className="theme-color">Beyond</span>Box</h2>
              <h2 className="heading-sub-title mb-4">Our commitment to making a difference</h2>
              <p className="para-heart-img mobile-card-box mb-lg-4">At BeyondBox, our commitment goes beyond helping
                you leave a legacy; it's about making a tangible
                difference in the world. We proudly allocate a third of
                our net earnings to the BeyondBox Foundation,
                directly linking the success of the BeyondBox
                platform to the support of vital humanitarian causes.
                With every BeyondBox you create, you're not just
                crafting memories for your loved ones; you're also
                empowering a network of partner charities to make
                impactful strides in their communities. Explore the
                stories of change on our Foundation page and join us
                in our journey to create a better world, one
                BeyondBox at a time.</p>
              <div className="text-center mt-3">
                <Link to="/foundation" className="button-secondary"><span> Learn More</span></Link>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="howItWorks">
        <div className="container-width">
          <h2 className="text-center heading-title fw-bolder">The opportunity to create  <span className="theme-color">YOUR ULTIMATE GIFT</span></h2>

          {/* desktop view */}
          <div className='d-none d-lg-block'>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 ">
                <Card className="card-blog">
                  <Card.Img variant="top" src={blogimg1} />
                  <Card.Body>
                    <Card.Text>
                      BeyondBox empowers you to create a lasting legacy, motivating you to cherish every moment and share your life's wisdom and love
                      with your loved ones, even after you're gone.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 ">
                <Card className="card-blog">
                  <Card.Img variant="top" src={blogimg2} />
                  <Card.Body>
                    <Card.Text>
                      Leave personalized, encrypted digital boxes filled with your messages, guiding and comforting your loved ones through their life's journey after your passing
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 ">
                <Card className="card-blog">
                  <Card.Img variant="top" src={blogimg3} />
                  <Card.Body>
                    <Card.Text> Make use of our unique 'Public Box' feature to leave a final message to the world, celebrating your life and sharing your story on a global stage.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 ">
                <Card className="card-blog">
                  <Card.Img variant="top" src={blogimg4} />
                  <Card.Body>
                    <Card.Text>
                      BeyondBox is not just about the end; it's a tool to remind us of the preciousness of life, encouraging us to live fully and leave a meaningful impact on those we care about..
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          {/* mobile view */}
          <Carousel 
          slide={true}
          interval={5000}
          controls={true}
          indicators={false}
          className='d-block d-lg-none carousel-slider'>
            <Carousel.Item>
              <Card className="card-blog">
                  <Card.Img variant="top" src={blogimg1} />
                  <Card.Body>
                    <Card.Text>
                      BeyondBox empowers you to create a lasting legacy, motivating you to cherish every moment and share your life's wisdom and love
                      with your loved ones, even after you're gone.
                    </Card.Text>
                  </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
                <Card className="card-blog">
                  <Card.Img variant="top" src={blogimg2} />
                  <Card.Body>
                    <Card.Text>
                      Leave personalized, encrypted digital boxes filled with your messages, guiding and comforting your loved ones through their life's journey after your passing
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Carousel.Item>
            <Carousel.Item>
                <Card className="card-blog">
                  <Card.Img variant="top" src={blogimg3} />
                  <Card.Body>
                    <Card.Text> Make use of our unique 'Public Box' feature to leave a final message to the world, celebrating your life and sharing your story on a global stage.
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="card-blog">
                  <Card.Img variant="top" src={blogimg4} />
                  <Card.Body>
                    <Card.Text>
                      BeyondBox is not just about the end; it's a tool to remind us of the preciousness of life, encouraging us to live fully and leave a meaningful impact on those we care about..
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Carousel.Item>
          </Carousel>
          <div className="text-center">
            <Link to="/signup" className="button-secondary mt-3"><span> Sign Up Free</span></Link>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default Landing;