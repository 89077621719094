import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import worldWideWeb from "../../assets/images/world-wide-web.png";
import phoneIcon from "../../assets/images/phone.png";
import img from '../../assets/images/foundation.webp';
// import bushSchool from "../../assets/images/bush-school.png";
// import hand from "../../assets/images/hand.png";
import Layout from "../../layout/Layout";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFoundationPage } from "../../redux/actions/foundationAction";
import { BASE_URLS } from "../../redux/constant";
import React from "react";

const Foundation = (props: any) => {
  let userData = localStorage.getItem("profileData");
  const [foundationData, setFoundationData] = useState<any>([]);
  const [visibleFoundationBoxes, setvisibleFoundationBoxes] = useState(4);
  // const [readMore, setReadMore] = useState("");
  // const [readMoreId, setReadMoreId] = useState(0);

  useEffect(() => {
    props.getFoundationPage();
  }, []);

  useEffect(() => {
    if (props.getFoundationPageData) {
      setFoundationData(props.getFoundationPageData);
    }
  }, [props.getFoundationPageData]);

  const LoadMoreFoundationBoxes = () => {
    setvisibleFoundationBoxes(visibleFoundationBoxes + 4);
  };
  
  // READ MORE ACTION -----------------------
  // const readMoreFunction = (value: any,id:any) => {
  //   setReadMore(value);
  //   setReadMoreId(id);
  // };

  // READ LESS ACTION -----------------------
  // const readLessFunction = () => {
  //   setReadMore("");
  // };
const Foundation = () => {
  return (
    <>
      <div className="intro">
            <div className="img-Cover d-none d-xl-block">
              {/* desktop only */}
               <img className="img-fluid" src={img} alt="" />
            </div>
            <div className="half-section">
              <div className="foundation-content center-content">
                  <h2 className="heading-title text-center text-xl-start"> BeyondBox <b>Foundation</b>  </h2>
                  {/* ipad/mobile only */}
                  <img className="img-fluid my-3 mx-auto rounded-circle d-block d-xl-none" src={img} alt="" />

                  <div className="bg-Main-Content text-white ">
                    <div className="mb-lg-2 mobile-card-box">
                        <h5> <span className="theme-color">Empowering Change</span> </h5>
                        <p> At the heart of BeyondBox lies a vision far greater than the innovative digital communication
                            platform we've created. The BeyondBox Foundation is our commitment to leaving a lasting legacy
                            of hope, support, and empowerment for those who need it most. With every member that joins the
                            BeyondBox community, we move one step closer to realizing a dream that extends beyond the
                            digital realm. A dream to help millions across the globe.

                        </p>
                    </div>
                    <div className="mb-lg-2 mobile-card-box">
                        <h5> <span className="theme-color">Our Mission</span> </h5>
                        <p> The mission of the BeyondBox Foundation is simple yet profound: to harness the collective power
                                of our community to provide financial assistance to existing charities and humanitarian efforts
                                worldwide. We believe in the strength of unity and the impact of collective action. Therefore, a
                                third of all net revenue generated from the BeyondBox platform is pledged to the Foundation,
                                ensuring that every subscription not only connects you with your loved ones but also contributes
                                to a greater cause.
                        </p>
                    </div>
                    <div className="mb-lg-2 mobile-card-box">
                        <h5> <span className="theme-color">How you Help  </span> </h5>
                        <p> Every person who chooses BeyondBox becomes a part of something much bigger. You're not just
                            preserving memories or leaving messages for the future; you're actively participating in a
                            movement to alleviate suffering, enhance lives, and support the vital work of charities across the
                            globe. It's a powerful way to make your mark on the world, knowing that your choice to join
                            BeyondBox directly contributes to our mission of helping as many people in need as possible.
                        </p>
                    </div>
                    <div className="mb-lg-2 mobile-card-box">
                        <h5> <span className="theme-color">Join Us in Making a Difference  </span> </h5>
                        <p> The BeyondBox Foundation is not just our legacy—it's an opportunity for each of us to leave a
                          positive imprint on the world. By choosing BeyondBox, you're choosing to be part of a global effort
                          to spread kindness, support, and philanthropy. Together, we can transform lives, uplift
                          communities, and create a wave of change that resonates for generations to come. As we
                          continue to grow and reach new milestones, the BeyondBox Foundation remains at the core of
                          what we do. It's a reflection of our values, our hopes, and our commitment to making the world a
                          better place. We invite you to join us on this journey, where your digital legacy becomes a beacon
                          of hope and a source of tangible aid for those in need. Together, let's build a legacy that
                          transcends time, creating a better tomorrow for those around the world who need it most.
                        </p>
                    </div>
                  </div>
                  
              </div>
            </div>
            
        </div>
    </>
  )
}
  return (
    <Layout>
      <Foundation />
      <div className="boxes pb-0">
        <div className={`charity ${!userData ? `charity_publicBox` : `mt-5`}`}>
          <Container className="foundationPage ">
            {/* <div className="mobile-card-box">
              <h2 className="theme-color text-center"> The BeyondBox Foundation </h2>
              
              <div className="text-light">
                <p>Our purpose is to provide financial assistance to existing charities so they can continue their vital humanitarian and philanthropic work. We are proud to donate one-third of our net earnings from the BeyondBox Messaging Platform to our expanding network of partner charities. We feel privileged for the opportunity to collaborate with organizations that do incredible work in their communities and are grateful to our subscribers for supporting our initiative. With your help, we can make a significant difference and empower these charities to continue making a positive impact on the lives of those they serve. We thank you for being a part of the BeyondBox family and for helping us support these deserving causes. Together, we can build a brighter future for all.</p>
              </div>
            </div> */}
            {foundationData && foundationData.length !== 0 ? 
              (foundationData.slice(0, visibleFoundationBoxes).map((list: any) => {
                return (
                  <div className="foundationList">
                    <div className="contentImg">
                      <img src={ list && list.foundationProfilePhoto && `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.foundationProfilePhoto}` } alt="" />
                    </div>
                    <div className="contentDesc">
                      <h5>{list.foundationName}</h5>
                        <div className="desc-data">
                          <span className="text-light">
                            {list && list.foundationDetails}
                          </span>
                          {/* {readMore && readMoreId == list.id ? 
                            (<span className="text-light">
                              <p>{readMore}{" "}{list.foundationDetails.length > 50 && (
                                  <small className="text-warning pointer read_more_or_less" onClick={() => readLessFunction()} >
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read Less</u>
                                  </small>
                                )}
                              </p>
                            </span>) 
                          : 
                            (<span className="text-light">
                              {list.foundationDetails.length > 50 ? list.foundationDetails.substring(0, 50) + "..." : list.foundationDetails}{" "}
                              {list.foundationDetails.length > 50 && 
                                (<small className="text-warning pointer read_more_or_less" onClick={() => readMoreFunction(list.foundationDetails, list.id) }>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u>
                                </small>
                              )}
                            </span>
                          )} */}
                        </div>
                        <div className="callus">
                          <div className="mb-3">
                            <Link to="#">
                              <img className="align-middle" src={phoneIcon} alt="" />
                              {list.foundationPhone}
                            </Link>
                          </div>
                          <a href={list.foundationWebsite}  target="_blank" >
                            <img className="align-middle" src={worldWideWeb} alt="" />
                            {list.foundationWebsite}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="text-warning text-center">
                {/* No Foundation Yet... */}
              </div>
            )}
            <div className="text-center" style={ visibleFoundationBoxes > Object.keys(foundationData).length || visibleFoundationBoxes == Object.keys(foundationData).length ? { display: "none" } : { display: "block" } } >
              <Button className="btn btn-primary" onClick={() => LoadMoreFoundationBoxes()} > LOAD MORE </Button>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getFoundationPage: (data: any) => dispatch(getFoundationPage(data)),
});

const mapStateToProps = (state: any) => {
  return {
    getFoundationPageData:
      state.FoundationReducer && state.FoundationReducer.getFoundationPageData
        ? state.FoundationReducer.getFoundationPageData
        : null,
  };
};
// export default connect(mapStateToProps, mapDispatchToProps)(AdminFoundation);
export default connect(mapStateToProps, mapDispatchToProps)(Foundation);