import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Container,
  Modal,
  Row,
  Col,
  Stack,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { updateAdminPlan } from "../../../redux/actions/admin/adminBoxPlanAction";
const UpdatePlanModal = (props: any) => {
//
const countries = [
  {
    "id": 13,
    "iso": "AU",
    "name": "AUSTRALIA",
    "nicename": "Australia",
    "iso3": "AUS",
    "numcode": "36",
    "phonecode": "+61",
    "currencyCode": "AUD",
    "currencyCodeActive": true
  },
  {
    "id": 30,
    "iso": "BR",
    "name": "BRAZIL",
    "nicename": "Brazil",
    "iso3": "BRA",
    "numcode": "76",
    "phonecode": "+55",
    "currencyCode": "BRL",
    "currencyCodeActive": true
  },
  {
    "id": 38,
    "iso": "CA",
    "name": "CANADA",
    "nicename": "Canada",
    "iso3": "CAN",
    "numcode": "124",
    "phonecode": "1",
    "currencyCode": "CAD",
    "currencyCodeActive": true
  },
  {
    "id": 99,
    "iso": "IN",
    "name": "INDIA",
    "nicename": "India",
    "iso3": "IND",
    "numcode": "356",
    "phonecode": "+91",
    "currencyCode": "Rs",
    "currencyCodeActive": true
  },
  {
    "id": 153,
    "iso": "NZ",
    "name": "NEW ZEALAND",
    "nicename": "New Zealand",
    "iso3": "NZL",
    "numcode": "554",
    "phonecode": "+64",
    "currencyCode": "NZD",
    "currencyCodeActive": true
  },
  {
    "id": 156,
    "iso": "NG",
    "name": "NIGERIA",
    "nicename": "Nigeria",
    "iso3": "NGA",
    "numcode": "566",
    "phonecode": "+234",
    "currencyCode": "NGN",
    "currencyCodeActive": true
  },
  {
    "id": 225,
    "iso": "GB",
    "name": "UNITED KINGDOM",
    "nicename": "United Kingdom",
    "iso3": "GBR",
    "numcode": "826",
    "phonecode": "+44",
    "currencyCode": "GBP",
    "currencyCodeActive": true
  },
  {
    "id": 226,
    "iso": "US",
    "name": "UNITED STATES",
    "nicename": "United States",
    "iso3": "USA",
    "numcode": "840",
    "phonecode": "+1",
    "currencyCode": "$",
    "currencyCodeActive": true
  },
  {
    "id": 100,
    "iso": "ZA",
    "name": "SOUTH AFRICA",
    "nicename": "South Africa",
    "iso3": "ZAF",
    "numcode": "710",
    "phonecode": "+27",
    "currencyCode": "R",
    "currencyCodeActive": true
  }
  // Add more countries here
];
// const [inputFields, setInputFields] = useState([
//   { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '' }
// ]);
const [inputFields, setInputFields] = useState([
  { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '', curr: '' }
]);

// const handleFormChange = (index:any, event:any) => {
//   const { name, value } = event.target;
//   setInputFields(prevInputs => {
//     const updatedInputs: any = [...prevInputs];
//     updatedInputs[index][name] = value;
    
//     // Calculate discounted amount if discount and original amount exist
//     if ((name === 'discount' || name === 'amount') && updatedInputs[index].amount && updatedInputs[index].discount) {
//       const discount = parseFloat(updatedInputs[index].discount);
//       const originalAmount = parseFloat(updatedInputs[index].amount);
//       const discountedAmount = originalAmount - (originalAmount * discount / 100);
//       updatedInputs[index].discountedAmount = discountedAmount.toFixed(2);
//     }
    
//     // Calculate discounted usd amount if discount and usd amount exist
//     if ((name === 'discount' || name === 'usdAmount') && updatedInputs[index].usdAmount && updatedInputs[index].discount) {
//       const discount = parseFloat(updatedInputs[index].discount);
//       const originalUsdAmount = parseFloat(updatedInputs[index].usdAmount);
//       const discountedUsdAmount = originalUsdAmount - (originalUsdAmount * discount / 100);
//       updatedInputs[index].discountedUsdAmount = discountedUsdAmount.toFixed(2);
//     }
    
//     return updatedInputs;
//   });
// }

// const handleCheckboxChange = (index:any) => {
//   // setInputFields(prevInputs => {
//   //   const updatedInputs:any = [...prevInputs];
//   //   updatedInputs[index].showUsdInput = !updatedInputs[index].showUsdInput;
//   //   return updatedInputs;
//   // });
//   setInputFields(prevInputs => {
//     const updatedInputs = [...prevInputs];
//     updatedInputs[index].showUsdInput = !updatedInputs[index].showUsdInput;
    
//     // Reset USD amount and related fields if checkbox is unchecked
//     if (!updatedInputs[index].showUsdInput) {
//       updatedInputs[index].usdAmount = '';
//       updatedInputs[index].discountedUsdAmount = '';
//     }
    
//     return updatedInputs;
//   });
 
// }

const handleFormChange = (index: number, event: any) => {
  const { name, value } = event.target;
  setInputFields(prevInputs => {
    const updatedInputs: any = [...prevInputs];
    updatedInputs[index][name] = value;
    
    // Calculate discounted amount if discount and original amount exist
    if (( name === 'amount') && updatedInputs[index].amount) {
      const discount = parseFloat(updatedInputs[index].discount);
      const originalAmount = parseFloat(updatedInputs[index].amount);
      const discountedAmount = originalAmount - (originalAmount * 20 / 100);
      updatedInputs[index].discountedAmount = discountedAmount.toFixed(2);
    }
    
    // Calculate discounted usd amount if discount and usd amount exist
    if (( name === 'usdAmount') && updatedInputs[index].usdAmount) {
      const discount = parseFloat(updatedInputs[index].discount);
      const originalUsdAmount = parseFloat(updatedInputs[index].usdAmount);
      const discountedUsdAmount = originalUsdAmount - (originalUsdAmount * 20 / 100);
      updatedInputs[index].discountedUsdAmount = discountedUsdAmount.toFixed(2);
    }
    
    // Extract and assign currency code
    const selectedCountry = countries.find(country => country.name === updatedInputs[index].country);
    if (selectedCountry) {
      updatedInputs[index].curr = selectedCountry.currencyCode;
    }

    return updatedInputs;
  });
}

const handleCheckboxChange = (index: number) => {
  setInputFields(prevInputs => {
    const updatedInputs: any = [...prevInputs];
    updatedInputs[index].showUsdInput = !updatedInputs[index].showUsdInput;
    
    // Reset USD amount and related fields if checkbox is unchecked
    if (!updatedInputs[index].showUsdInput) {
      updatedInputs[index].usdAmount = '';
      updatedInputs[index].discountedUsdAmount = '';
      // Reset currency code when USD input is hidden
      updatedInputs[index].curr = '';
    } else {
      // Extract and assign currency code if checkbox is checked
      const selectedCountry = countries.find(country => country.iso === updatedInputs[index].country);
      if (selectedCountry) {
        updatedInputs[index].curr = selectedCountry.currencyCode;
      }
    }
    
    return updatedInputs;
  });
}

const addFields = () => {
  const newField = { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '' ,curr:''};
  setInputFields(prevInputs => [...prevInputs, newField]);
  console.log(inputFields);
}

const removeFields = (index:any) => {
  setInputFields(prevInputs => {
    const updatedInputs = [...prevInputs];
    updatedInputs.splice(index, 1);
    return updatedInputs;
  });
}

  //
  let boxPlanId = parseInt(
    props && props.boxPlanIdProp ? props.boxPlanIdProp : ""
  );
  const [discountedValue, setDiscountedValue] = useState("");
  const [pPrice1, setPPrice1] = useState(0);
  const [pDiscount1, setPDiscount1] = useState(0);
 
  const [updatePlan, setUpdatePlan] = useState({
    form_field_Type: "",
    form_field_Code: "",
    form_field_Price: "",
    form_field_privateBoxes: "",
    form_field_publicBoxes: "",
    form_field_recievedBoxes: "",
    form_field_totalBoxes: "",
    form_field_Duration: "",
    form_field_Storage: "",
    form_field_recievedBoxAccessTime: "",
    // form_field_paypal_id: "",
    form_field_privateRecipients: 0,
    form_field_marketerPlanDiscount:"",
    form_field_marketerCommission:"",
    form_field_priceAfterDiscount: "",
    
  });
  let newResult: any;

  useEffect(() => {
    if (props.adminBoxPlanDataById) {
      const result = props.adminBoxPlanDataById
        ? props.adminBoxPlanDataById
        : "";
     if(props.adminBoxPlanDataById &&props.adminBoxPlanDataById.currencyCode!=""&&props.adminBoxPlanDataById.currencyCode!="null")
      {
        setInputFields(JSON.parse(props.adminBoxPlanDataById.currencyCode))
      }
      else
      {
        setInputFields([
          { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '', curr: '' }
        ]);
      }
       
        
      updatePlan.form_field_Type =
        (result && result.planType) ? result.planType : "";
      updatePlan.form_field_Code =
       ( result && result.planCode) ? result.planCode : "";
      updatePlan.form_field_Price =
       ( result && result.planPrice) ? result.planPrice : "";
      updatePlan.form_field_privateBoxes =
       ( result && result.privateBoxes) ? result.privateBoxes : "";
      updatePlan.form_field_publicBoxes =
        (result && result.publicBoxes )? result.publicBoxes : "";
      updatePlan.form_field_recievedBoxes =
       ( result && result.recievedBoxes )? result.recievedBoxes : "";
      updatePlan.form_field_recievedBoxAccessTime =
        (result && result.recievedBoxAccessTime)
          ? result.recievedBoxAccessTime
          : "";
      updatePlan.form_field_Duration =
        (result && result.planDuration) ? result.planDuration : "";
      updatePlan.form_field_Storage =
        (result && result.planStorage) ? result.planStorage : "";
      // updatePlan.form_field_paypal_id =
      //  ( result && result.plan_id )? result.plan_id : "";
      updatePlan.form_field_privateRecipients =
        (result && result.privateRecipients) ? /* parseInt(result.privateRecipients )*/(result.privateRecipients): 0;

      //   updatePlan.form_field_priceAfterDiscount =
      //  ( result && result.discountedPlanPrice )? result.discountedPlanPrice : ""; // change plan_id
        updatePlan.form_field_marketerPlanDiscount =
        ( result && result.marketerPlanDiscount )? result.marketerPlanDiscount : "";
        updatePlan.form_field_marketerCommission =
       ( result && result.marketerCommission )? result.marketerCommission : "";

       setDiscountedValue(result.discountedPlanPrice);
       
    }
  }, [props.adminBoxPlanDataById]);

  // var pPrice2=0;
  var pDiscount2=0;
  const handleChange = (event: any) => {
    
    if(event.target.name == 'form_field_Price' ){
      
        if(event.target.name == 'form_field_marketerPlanDiscount'){
          event.target.value ='';
        }
     
        
      setPPrice1(event.target.value)

    }
    if(event.target.name == 'form_field_marketerPlanDiscount'){ // new
      setPDiscount1(event.target.value)
      pDiscount2= event.target.value;
    }
    if(pPrice1 && pDiscount2 ){
      
      let disPrice = pPrice1 - ((pPrice1 * pDiscount2)/100);
      let finalValue = '' + disPrice
      
      setDiscountedValue(finalValue);
    }
   
    setUpdatePlan({
      ...updatePlan,
      [event.target.name]: event.target.value,
    });
    //console.warn('-----------resultArray-----------------',resultArray)
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    let payload = {
      id: 0,
      planType: "",
      planCode: "",
      planPrice: "",
      privateBoxes: "",
      publicBoxes: "",
      recievedBoxes: "",
      totalBoxes: "",
      planDuration: "",
      planStorage: "",
      recievedBoxAccessTime: "",
      plan_id: "",
      privateRecipients: 0,
      marketerPlanDiscount:"",
      marketerCommission:0,
      discountedPlanPrice:"",
      currencyCode:"",
    };
    payload.id = boxPlanId;
    payload.planType = updatePlan.form_field_Type;
    payload.planCode = updatePlan.form_field_Code;
    payload.planPrice = updatePlan.form_field_Price;
    payload.privateBoxes = updatePlan.form_field_privateBoxes;
    payload.publicBoxes = updatePlan.form_field_publicBoxes;
    payload.recievedBoxes = updatePlan.form_field_recievedBoxes;
    /* payload.totalBoxes= updatePlan.pPrivateBox+updatePlan.pPublicBox; */
    payload.planDuration = updatePlan.form_field_Duration;
    payload.planStorage = updatePlan.form_field_Storage;
    payload.recievedBoxAccessTime = updatePlan.form_field_recievedBoxAccessTime;
    // payload.plan_id = updatePlan.form_field_paypal_id;
    payload.privateRecipients= ~~updatePlan.form_field_privateRecipients;

    payload.marketerPlanDiscount = updatePlan.form_field_marketerPlanDiscount;
    payload.marketerCommission = parseInt(updatePlan.form_field_marketerCommission?updatePlan.form_field_marketerCommission:"0");
    payload.discountedPlanPrice =String( discountedValue);
    payload.currencyCode=JSON.stringify(inputFields);
   

    props.updateAdminPlan(payload);
    setInputFields([
      { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '', curr: '' }
    ])
  };
  
 /*  useEffect(() => {
    if (props.updateAdminBoxPlanData) {
      alert("plan updated");
    }
  }, [props.updateAdminBoxPlanData]); */
  return (
    <div>
      <Modal {...props} show={props && props.show} size="lg" backdrop="static" scrollable="true" className="adminModal">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#E79E1F" }}>Update Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_Type"
                      value={updatePlan.form_field_Type}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Code
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_Code"
                      value={updatePlan.form_field_Code}
                      onChange={handleChange}
                      autoFocus
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Price (R)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="form_field_Price"
                      value={updatePlan.form_field_Price}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col> */}
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Duration
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_Duration"
                      value={updatePlan.form_field_Duration}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Storage
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_Storage"
                      value={updatePlan.form_field_Storage}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Private Boxes
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_privateBoxes"
                      value={updatePlan.form_field_privateBoxes}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Public Boxes
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_publicBoxes"
                      value={updatePlan.form_field_publicBoxes}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Recieved Boxes
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_recievedBoxes"
                      value={updatePlan.form_field_recievedBoxes}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Private Box Recipients
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_privateRecipients"
                      value={updatePlan.form_field_privateRecipients}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Recieved Boxes Duration
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_recievedBoxAccessTime"
                      value={updatePlan.form_field_recievedBoxAccessTime}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                
                 {/* marketer  */}
                 {/* <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                    Marketer Plan Discount(%)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="form_field_marketerPlanDiscount"
                      value={updatePlan.form_field_marketerPlanDiscount}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                 <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Price After Discount (R)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="discountedValue"
                      value={ discountedValue} // this or that
                      // onChange={handleChange}
                      disabled
                      autoFocus
                    />
                  </Form.Group>
                </Col> */}
                 <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                    Marketer Commission
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_marketerCommission"
                      value={updatePlan.form_field_marketerCommission}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                   Marketer Plan Discount %
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_marketerCommission"
                      value={20}
                     disabled
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                 
                
                {/* marketer  */}
                {/* <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Paypal Plan Subscription Id
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_paypal_id"
                      value={updatePlan.form_field_paypal_id}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col> */}
               <Col md={12}>
               <Form.Group className="mb-3 mt-3 " >
                          <Form.Label style={{ color: "whitesmoke" }}> Plan Amount </Form.Label>
                        
        {inputFields &&inputFields.map((input, index) => (
        <div key={index}>
          <div className="row">
          <Form.Group className="mb-3">
        <Form.Label style={{ color: "whitesmoke" }}>
              Country
            </Form.Label>
          <Form.Select
            name='country'
            value={input.country}
            onChange={(event) => handleFormChange(index, event)}
          >
            <option value="">Select Country</option>
            {countries.map(country => (
              <option key={country.id} value={country.name}>{country.name} - {country.currencyCode}</option>
            ))}
          </Form.Select>
        </Form.Group>
            <div className="col-lg-6">
  
        <Form.Group className="mb-3">
        <Form.Label style={{ color: "whitesmoke" }}>
              Actual Price
            </Form.Label>
          <Form.Control
            name='amount'
            placeholder='Amount'
            value={input.amount}
            onChange={(event) => handleFormChange(index, event)}
          />
        </Form.Group>
        {input.showUsdInput && (
          <Form.Group className="mb-3 ">
                <Form.Label  style={{ color: "whitesmoke" }}>
              USD Amount
            </Form.Label>
            <Form.Control
              name='usdAmount'
              placeholder='Enter USD Amount'
              value={input.usdAmount}
              onChange={(event) => handleFormChange(index, event)}
            />
          </Form.Group>
        )}
            </div>
            <div className="col-lg-6">
            <Form.Group className="mb-3 " style={{ color: "whitesmoke" }}>
          <Form.Label>
            Price After Discount
          </Form.Label>
          <Form.Control
            type="text"
            value={input.discountedAmount}
            disabled
          />
          {input.showUsdInput && input.usdAmount && (
            <div>
              <Form.Label  className="mt-3">
                Discounted USD Amount
              </Form.Label>
              <Form.Control
                type="text"
                value={input.discountedUsdAmount}
                disabled
              />
            </div>
          )}
        </Form.Group>
            </div>
          </div>
   
        <Form.Group className="mb-3" style={{ color: "whitesmoke" }}>
          <Form.Check
            type="checkbox"
            label="Show USD amount"
            checked={input.showUsdInput}
            onChange={() => handleCheckboxChange(index)}
          />
        </Form.Group>
      
        {/* <Form.Group className="mb-3" style={{ color: "whitesmoke" }}>
          <Form.Label>
            Marketer Plan Discount (%)
          </Form.Label>
          <Form.Control
            name='discount'
            placeholder='Discount (%)'
            value={input.discount}
            onChange={(event) => handleFormChange(index, event)}
          />
        </Form.Group> */}
        
                                {/* <Button variant="danger" type='button' onClick={() => removeFields(index)} disabled={inputFields.length<=1}>Remove</Button>{' '}
          <Button variant="primary" type='button' onClick={addFields}   disabled={inputFields.some(input => !input.country || !input.amount)}>Add More..</Button> */}
                                {index === inputFields.length - 1 && ( // Render only for the last input field
                                  <>
                                    <Button variant="danger" disabled={inputFields.length<=1} type='button' onClick={() => removeFields(index)}>Remove</Button>{' '}
                                    <Button variant="primary" className="my-0"  disabled={inputFields.some(input => !input.country || !input.amount)} type='button' onClick={addFields}>Add More..</Button>
                                  </>
                                )}
                              </div>
        ))}
    
                        </Form.Group>
               </Col>

                {/* </>
                    );
                  })} */}
              </Row>
            </Container>
            <Row className="justify-content-md-center mt-10">
              <Col className="" md="auto">
                <Button className="backBtn"
                  variant="primary"
                  type="submit"
                  // onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        {/*  <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  //getAdminPlanById: (data: any) => dispatch(getAdminPlanById(data)),
  //addAdminPlans:(data: any) => dispatch(addAdminPlans(data))
  updateAdminPlan: (data: any) => dispatch(updateAdminPlan(data)),
});

const mapStateToProps = (state: any) => {
  return {
    adminBoxPlanDataById:(state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.adminBoxPlanDataById)
        ? state.AdminBoxPlanReducer.adminBoxPlanDataById : null,
    updateAdminBoxPlanData: (state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.updateAdminBoxPlanData)
        ? state.AdminBoxPlanReducer.updateAdminBoxPlanData : null,
    state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePlanModal);
