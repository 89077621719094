import { put, call } from "redux-saga/effects";
import { COMMON_MESSAGE, ADMIN_BLOG } from "../../constant/index";
import { generateReadableError } from "../../../hooks/messages";
import { deleteAdminBlogService, getAddAdminBlogService, getBlogDetailByIdService, getBlogFilterListService, getBlogListService, updateAdminBlogService } from "../../services/admin/adminBlogService";

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: "",
    page: "",
    data: [],
  },
};


//ADD_ADMIN_FOLDER function

export function* fetchAddAdminBlog({ payload }) {

  console.log(payload)
  try {
    yield put({
      type: ADMIN_BLOG.SET_ADD_ADMIN_BLOG_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getAddAdminBlogService, payload);
    yield put({
      type: ADMIN_BLOG.SET_ADD_ADMIN_BLOG_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Blog",
        data: generateReadableError(error),
      },
    });
  }
}
//fetchGetMarketerList


export function* fetchGetBlogList({ payload }) {

try {
  yield put({
    type: ADMIN_BLOG.SET_GET_BLOG_LIST_TO_STATE,
    data: null,
  });
  yield put(clearErrorMessage);
  const data = yield call(getBlogListService, payload);
  
  yield put({
    type: ADMIN_BLOG.SET_GET_BLOG_LIST_TO_STATE,
    data,
  });
} catch (error) {
  yield put({
    type: COMMON_MESSAGE,
    data: {
      status: "error",
      page: "admin Blog",
      data: generateReadableError(error),
    },
  });
}
}

//filter

export function* fetchGetBlogFilterList({ payload }) {

  try {
    yield put({
      type: ADMIN_BLOG.SET_GET_BLOG_FILTER_LIST_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getBlogFilterListService, payload);
    
    yield put({
      type: ADMIN_BLOG.SET_GET_BLOG_FILTER_LIST_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Blog",
        data: generateReadableError(error),
      },
    });
  }
  }
  

//fiter

//fetchDeleteAdminBlog


export function* fetchDeleteAdminBlog({ payload }) {

  try {
    yield put({
      type: ADMIN_BLOG.SET_DELETE_ADMIN_BLOG_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(deleteAdminBlogService, payload);
    
    yield put({
      type: ADMIN_BLOG.SET_DELETE_ADMIN_BLOG_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Blog",
        data: generateReadableError(error),
      },
    });
  }
  }

  //fetchGetBlogDetailById
  export function* fetchGetBlogDetailById({ payload }) {
console.log('payload receving',payload)
    try {
      yield put({
        type: ADMIN_BLOG.SET_GET_BLOG_DETAIL_BY_ID_TO_STATE,
        data: null,
      });
      yield put(clearErrorMessage);
      const data = yield call(getBlogDetailByIdService,parseInt( payload));
      
      yield put({
        type: ADMIN_BLOG.SET_GET_BLOG_DETAIL_BY_ID_TO_STATE,
        data,
      });
    } catch (error) {
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: "error",
          page: "admin Blog",
          data: generateReadableError(error),
        },
      });
    }
    };

    export function* fetchUpdateAdminBlog({ payload }) {

      try {
        yield put({
          type: ADMIN_BLOG.SET_UPDATE_ADMIN_BLOG_TO_STATE,
          data: null,
        });
        yield put(clearErrorMessage);
        const data = yield call(updateAdminBlogService, payload);
        
        yield put({
          type: ADMIN_BLOG.SET_UPDATE_ADMIN_BLOG_TO_STATE,
          data,
        });
      } catch (error) {
        yield put({
          type: COMMON_MESSAGE,
          data: {
            status: "error",
            page: "admin Blog",
            data: generateReadableError(error),
          },
        });
      }
      };


 







