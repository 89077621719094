import {put, call} from 'redux-saga/effects';
import {
  checkLoginService,
  checkRegisterService,
  forgotPasswordService,
  checkOtpService,
  changePasswordService,
  getDetailsByUsername,
  validateOTP_ToVerifyUser,
  resendOTP_ToVerifyUser,
  authoriseServiceForUser,
  verifyUser_service,
} from '../services/authService';
import {AUTH, COMMON_MESSAGE} from '../constant/index';
import {generateReadableError} from '../../hooks/messages';
const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }}

export function* loginUser({payload}) {
  try {
    yield put({type: AUTH.LOGIN_SUCCESS, data:null});
    yield put(clearErrorMessage);
    const data = yield call(checkLoginService, payload);
    yield put({type: AUTH.LOGIN_SUCCESS, data});
    // localStorage.setItem('userData', JSON.stringify(data));

    if(data.statusCode === 403){
      yield put({
        type: COMMON_MESSAGE,
        data: data,
      });
    }else{
      yield put({
        type: COMMON_MESSAGE,
        data: data,
      });
    }
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'login',
        data: generateReadableError(error),
      },
    });
  }
}

export function* authoriseUser({payload}) {
  try {
    yield put({type: AUTH.SET_LOGIN_OTP_RESPONSE, data:null});
    yield put(clearErrorMessage);
    const data = yield call(authoriseServiceForUser, payload);
    yield put({type: AUTH.SET_LOGIN_OTP_RESPONSE, data});
    localStorage.setItem('userData', JSON.stringify(data));
    
    yield put({
      type: COMMON_MESSAGE,
      data: data,
    });
    
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: '',
        data: generateReadableError(error),
      },
    });
  }
}

export function* registerUser({payload}) {
  try {
    yield put(clearErrorMessage);
    const response = yield call(checkRegisterService, payload);
    yield put({type: AUTH.REGISTER_SUCCESS, data: response});
    yield put({
      type: COMMON_MESSAGE,
      data: response,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'register',
        data: generateReadableError(error),
      },
    });
  }
}

export function* forgotPassword({payload}) {
  try {
    yield put(clearErrorMessage);
    const response = yield call(forgotPasswordService, payload);
   
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'forgot_password',
        // data: 'Password reset instructions have been sent to mail',
        data:'OTP sent to your registered e-mail and mobile number',
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'forgot_password',
        data: 'No account associated with the provided email address',
      },
    });
  }
}

export function* checkOtp({payload}) {
  try {
    yield put(clearErrorMessage);
    const response = yield call(checkOtpService, payload);
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'check_otp',
        data: response,
      },
    }
    );
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'check_otp',
        data: generateReadableError(error),
      },
    });
  }
}

export function* changePassword({payload}) {
  try {
    yield put(clearErrorMessage);
    const response = yield call(changePasswordService, payload);
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'change_password',
        data: response,
      },
    }
    );
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'change_password',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchByUsername({payload}) {
  try {
    yield put({type: AUTH.GET_DETAILS_USERNAME, data:null});
    yield put(clearErrorMessage);
    const data = yield call(getDetailsByUsername, payload);
    yield put({type: AUTH.SET_DETAILS_USERNAME, data});
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'username_api',
        data: 'username fetch success',
      },
    });

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'username_api',
        data: generateReadableError(error),
      },
    });
  }
}

export function* checkOtpToVerifyUser({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(validateOTP_ToVerifyUser, payload);
    yield put({type: AUTH.SET_RESPONSE_ON_VALID_OTP, data});
    localStorage.setItem('userData', JSON.stringify(data));
    yield put({
      type: COMMON_MESSAGE,
      data: data,
    });

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'user_verification',
        data: generateReadableError(error),
      },
    });
  }
}

export function* getResendOtpToVerifyUser({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(resendOTP_ToVerifyUser, payload);
    yield put({type: AUTH.SET_RESPONSE_ON_VALID_OTP, data});
    yield put({
      type: COMMON_MESSAGE,
      data: {
        isActive : 0,
        message  : "OTP sent successfully",
        status   : "success",
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: '',
        data: generateReadableError(error),
      },
    });
  }
}

export function* verifyUser({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(verifyUser_service, payload);
    yield put({type: AUTH.GET_RESPONSE_USER_FOR_BOX_RELEASE_FROM_DIFF_EMAIL, data});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'login',
        data: generateReadableError(error),
      },
    });
  }
}

