import Api from "../apiCall";
import { API_URLS } from "../../constant/index";

export const getAddAdminBlogService = async (params) => {
  const result = await Api.post(API_URLS.ADD_ADMIN_BLOG_URL, params);
  return result;
};

export const getBlogListService = async (params) => {
    const limit=100;
    const data = { limit: 100};
    const url = `${API_URLS.GET_BLOG_LIST_URL}?${new URLSearchParams(data).toString()}`;
  const result = await Api.get(url);
  return result;
};
export const getBlogFilterListService = async (params)=>{
  const category= params;
  const data = { limit: 100, category: category };
      const url = `${API_URLS.FILTER_CATEGORY_BLOG_URL}?${new URLSearchParams(data).toString()}`;
      const result = await Api.get(url);
      return result
}

export const deleteAdminBlogService = async (params) => {
  const deleteBlogId = params.id;
  let url = API_URLS.DELETE_ADMIN_BLOG_URL;
  let newurl = url.replace("{id}", deleteBlogId);
  const result = await Api.delete(newurl, params);
  return result;
};
export const getBlogDetailByIdService = async (params) => {
  const blogDetailById = params;
  let url = API_URLS.GET_BLOG_DETAIL_BY_ID_URL;
  let newurl = url.replace("{id}", blogDetailById);
  const result = await Api.get(newurl, params);
  return result;
};
export const updateAdminBlogService = async (params) => {
  const formData = params;
  console.log(formData)
  const updateBlogId = formData.get('id');
  let url = API_URLS.UPDATE_ADMIN_BLOG_URL;
  let newurl = url.replace("{id}", updateBlogId);
  const result = await Api.patch(newurl, formData);
  return result;
};
export const filterCategoryBlogService = async (params) => {
  let url = API_URLS.FILTER_CATEGORY_BLOG_URL;
  const result = await Api.get(url, params);
  return result;
};





