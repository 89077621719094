// import { upload } from "@testing-library/user-event/dist/upload";
import React, { useEffect, useState }        from "react";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import InputGroup                            from 'react-bootstrap/InputGroup';
import { connect }                           from "react-redux";
import { Link, useNavigate }                       from "react-router-dom";
import { getUserName }                       from "../../redux/actions/authAction";
import { cityList, stateList }               from "../../redux/actions/countryApiAction";
import { editProfileFormValidation }         from "../../validations/editProfile-form";
import { BASE_URLS, COMMON_MESSAGE, USER_PROFILE } from "../../redux/constant";
import Layout                                from "../../layout/Layout";
import defaultImg                            from "../../assets/images/no-image-available.png";
import { getCountryDialCode }                from "../../redux/actions/webAction";
import DisplayModal                          from "../box/uploaded_folder/display-modal";
import { getEditProfile, getKinDetails, getProfile, uploadProfileImage,getOTP_ToEditPhone,verifyOTP_ToEditPhoneNo } from "../../redux/actions/profileAction";

const EditProfile = (props: any) => {
  const navHome                                   = useNavigate();
  const navBack                                   = useNavigate();
  const [stateID,           setStateID]           = useState([]);
  const [cityId,            setCityId]            = useState([]);
  const [userDetails,       setUserDetails]       = useState<any>({});
  const [cities,            setCities]            = useState([]);
  const [cityValue,         setCityValue]         = useState(0);
  const [stateValue,        setStateValue]        = useState(0);
  const [planDetails,       setPlanDetails]       = useState(null as any);
  const [loading,           setLoading]           = useState(false);
  const [loader,            setLoader]            = useState(true);
  const [image,             setImage]             = useState({ preview: "", raw: "" });
  const [profileImage,      setProfileImage]      = useState("");
  const [profileImageError, setProfileImageError] = useState("");
  const [modalShow,         setModalShow]         = useState<boolean>(false);
  const [modalLoader,       setModalLoader]       = useState<boolean>(false);
  const [countryCodeErr,    setCountryCodeErr]    = useState('');
  const [mobileErr,         setMobileErr]         = useState('');
  const [otpErr,            setOTPErr]            = useState('');
  const [modalForm,         setModalForm]         = useState({country_code:'',mobile:''});
  const [modalFormOTP,      setModalFormOTP]      = useState('');
  const [successFlag,       setSuccessFlag]       = useState<boolean>(false);
  const [notification,      setNotification]      = useState({bgcolor:'',message:''});
  const [OTPFormFlag,       setOTPFormFlag]       = useState<boolean>(false);
  let   [editProfileForm,   setEditProfileForm]   = useState({ kinEmail: "", kinEmailOpt: "", kinName: "", kinNameOpt: "",kinCountryDialCode:"", kinPhoneNumber: "",kinCountryDialCodeOpt:"", kinPhoneNumberOpt: "", description: "", userImg: "", first_name: "", last_name: "", dob: "", identity_number: "",sucideExlude:0, /*life_work: "", quote: "", what_i_lived_for: "",*/ kinId: null,  kinIdOpt: null, city: 0, });
  let   [errors,            setErrors]            = useState({ kinEmail: "", kinEmailOpt: "", dob: "", kinName: "", kinNameOpt: "",kinCountryDialCode:"", kinPhoneNumber: "",kinCountryDialCodeOpt:"", kinPhoneNumberOpt: "", first_name: "", last_name: "", identity_number: "", city:""  });
  let   [isSubmitted,       setIsSubmitted]       = useState(false);
  let   [isValid,           setIsValid]           = useState(true);
  let   [newArray,          setNewArray]          = useState("");
  let   initFlag = 0;
  let   planExpDate: any;
  const [counter,           setCounter]           = useState(0);
  const [resendOTP_Flag,    setResendOTP_Flag]    = useState(false);
  let   [sExclusion,        setSExclusion]        = useState(false);
  let   [sExclusionError,   setSExclusionError]   = useState(false);
  const [is2FAEnabled, setIs2FAEnabled] = useState(true);
  const [isEmailEnabled, setIsEmailEnabled] = useState(true);
  const [isSMSEnabled, setIsSMSEnabled] = useState(true);

  const handle2FAToggle = () => {
    setIs2FAEnabled(!is2FAEnabled);
    if (!is2FAEnabled) {
      // If 2FA is enabled, enable both email and SMS by default
      setIsEmailEnabled(true);
      setIsSMSEnabled(true);
    } else {
      // If 2FA is disabled, disable both email and SMS
      setIsEmailEnabled(false);
      setIsSMSEnabled(false);
    }
  };

  const handleEmailToggle = () => {
    
    
    if ( is2FAEnabled && isSMSEnabled) {
      setIsEmailEnabled(!isEmailEnabled);
    }
  };

  const handleSMSToggle = () => {
    
    if ( is2FAEnabled && isEmailEnabled) {
      setIsSMSEnabled(!isSMSEnabled);
    }
  };

  const selectCity = (e: any) => {
    e.preventDefault();
    setCounter(counter+1);
    setEditProfileForm({ ...editProfileForm, city: e.target.value });
    setCityValue(e.target.value);  
  };

  const selectState = (e: any) => {
    e.preventDefault();
    setStateValue(e.target.value);
    props.cityList({ id: e.target.value });
  };

  const handleChange = (event: any) => {
    setCounter(counter+1);
    let targetValue  = event.target.value;
    targetValue.trim();
    setEditProfileForm({ ...editProfileForm, [event.target.name]: targetValue});
  };

  useEffect(() => {
    if(counter){
      const validationErrors = editProfileFormValidation(editProfileForm);
      isValid = Object.keys(validationErrors).length === 0;
      setIsValid(isValid);
      setErrors(validationErrors);
    }
  }, [counter]);
  
  useEffect(() => {
    if (props.profileData) {
      props.getCountryDialCode();
      setUserDetails(props.profileData);
      props.profileData && props.profileData.countryId && props.stateList({ id: props.profileData.countryId });
      props.profileData && props.profileData.stateId   && props.cityList({ id: props.profileData.stateId });
      setStateValue(props.profileData.stateId);
      setCityValue(props.profileData.cityId);
      props.profileData && props.profileData.id && props.getKinDetails({ id: props.profileData.id });
      props.profileData && props.profileData.sucideExclude && props.profileData.sucideExclude === 1 && setSExclusion(!sExclusion);
      setIs2FAEnabled(props.profileData.is2FAEnabled)
      setIsEmailEnabled(props.profileData.isEmailEnabled)
      setIsSMSEnabled(props.profileData.isSMSEnabled)
      setSExclusion(true)
      const profileData = props.profileData ? props.profileData : " ";
      var dATE = profileData && profileData.dob && (profileData.dob).split('/');
      editProfileForm.first_name       = profileData && profileData.firstName        ? profileData.firstName        : "";
      editProfileForm.last_name        = profileData && profileData.lastName         ? profileData.lastName         : "";
      editProfileForm.dob              = profileData && profileData.dob              ? ((dATE.length === 3) ? (dATE[2]+'-'+dATE[1]+'-'+dATE[0]) : profileData.dob) : "";
      editProfileForm.description      = profileData && profileData.description      ? profileData.description      : "";
      editProfileForm.userImg          = profileData && profileData.userImg          ? profileData.userImg          : "";
      editProfileForm.identity_number  = profileData && profileData.identity_number  ? profileData.identity_number  : "";
      editProfileForm.sucideExlude     = profileData && profileData.sucideExlude     ? profileData.sucideExlude     : 0;
      // editProfileForm.life_work        = profileData && profileData.life_work        ? profileData.life_work        : "";
      // editProfileForm.quote            = profileData && profileData.quote            ? profileData.quote            : "";
      // editProfileForm.what_i_lived_for = profileData && profileData.what_i_lived_for ? profileData.what_i_lived_for : "";
      editProfileForm.city             = profileData && profileData.cityId           ? profileData.cityId           : "";
      
      setEditProfileForm(editProfileForm);
      if (profileData.userImg) {
        // setProfileImage( `${BASE_URLS.BASE_URL}/uploads/users/profile-photo/${profileData.userImg}` );
        setProfileImage(`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${profileData.userImg}`)
      }
      setTimeout(() =>{
        setLoader(false);
      },1000)
    }
    setImage({ preview: "", raw: "" });
  }, [props.profileData]);
  
  useEffect(() => {
    if (props.kinDetailsData) {
      let kinData = props.kinDetailsData ? props.kinDetailsData : [];
      setEditProfileForm({ ...editProfileForm,
        kinEmail              : kinData && kinData[0] && kinData[0].email       ? kinData[0].email       : "",
        kinEmailOpt           : kinData && kinData[1] && kinData[1].email       ? kinData[1].email       : "",
        kinId                 : kinData && kinData[0] && kinData[0].id          ? kinData[0].id          : null,
        kinName               : kinData && kinData[0] && kinData[0].name        ? kinData[0].name        : "",
        kinNameOpt            : kinData && kinData[1] && kinData[1].name        ? kinData[1].name        : "",
        kinIdOpt              : kinData && kinData[1] && kinData[1].id          ? kinData[1].id          : null,
        kinCountryDialCode    : kinData && kinData[0] && kinData[0].countryCode ? kinData[0].countryCode : null,
        kinCountryDialCodeOpt : kinData && kinData[1] && kinData[1].countryCode ? kinData[1].countryCode : null,
        kinPhoneNumber        : kinData && kinData[0] && kinData[0].phoneNumber ? kinData[0].phoneNumber.startsWith(kinData[0].countryCode && kinData[0].countryCode) ? kinData[0].phoneNumber.replace(kinData[0].countryCode && kinData[0].countryCode,'') : (kinData[0].phoneNumber ? kinData[0].phoneNumber : '') : '',
        kinPhoneNumberOpt     : kinData && kinData[1] && kinData[1].phoneNumber ? kinData[1].phoneNumber.startsWith(kinData[1].countryCode && kinData[1].countryCode) ? kinData[1].phoneNumber.replace(kinData[1].countryCode && kinData[1].countryCode,'') : (kinData[1].phoneNumber ? kinData[1].phoneNumber : '') : '',
      });
    }
  }, [props.kinDetailsData]);

  const redirectToHome = (e: any) => {
    if (isSubmitted) {
      if (e === 1) {
        localStorage.removeItem("message");
        navHome("/homepage", { state: { message: "Profile updated successfully",messageType:'success' } });
      } else {
        navBack("/edit-profile");
      }
    }
  };

  useEffect(() => {
    if (props.profileData) {
      initFlag = props.profileData && props.profileData.verified ? props.profileData.verified : 0;
      redirectToHome( props.profileData && props.profileData.verified ? props.profileData.verified : 0 );
    }
  }, [props.profileData]);

  useEffect(() => {
    setStateID(props.stateData);
  }, [props.stateData]);

  useEffect(() => {
    if (props.editProfileData) {
      if (props.editProfileData !== initFlag) {
        navHome("/select-plans");
      }
    }
  }, [props.editProfileData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const validationErrors = editProfileFormValidation(editProfileForm);
    isValid = Object.keys(validationErrors).length === 0;
    
    if (isValid) {
        if(sExclusion){
      let userData = {
        id: userDetails && userDetails.id ? userDetails.id : "",
        user: {
          username        : editProfileForm.first_name + " " + editProfileForm.last_name,
          firstName       : editProfileForm.first_name,
          lastName        : editProfileForm.last_name,
          verified        : 1,
          description     : editProfileForm.description,
          dob             : editProfileForm.dob,
          identity_number : editProfileForm.identity_number,
          sucideExlude    : editProfileForm.sucideExlude,
          // life_work       : editProfileForm.life_work,
          // quote           : editProfileForm.quote,
          // what_i_lived_for: editProfileForm.what_i_lived_for,
          cityId          : Number(cityValue),
          stateId         : stateValue,
          is2FAEnabled,
        isEmailEnabled,
         isSMSEnabled,
        },
      

        kin: [
          {
            name       : editProfileForm.kinName,
            email      : editProfileForm.kinEmail,
            countryCode: editProfileForm.kinCountryDialCode,
            phoneNumber: editProfileForm.kinCountryDialCode+editProfileForm.kinPhoneNumber,
            id         : editProfileForm.kinId,
          },
          {
            name       : editProfileForm.kinNameOpt,
            email      : editProfileForm.kinEmailOpt,
            countryCode: editProfileForm.kinCountryDialCodeOpt,
            phoneNumber: editProfileForm.kinCountryDialCodeOpt+editProfileForm.kinPhoneNumberOpt,
            id         : editProfileForm.kinIdOpt,
          },
        ],
      };
    
      setIsSubmitted(true);
      props.getEditProfile(userData);
      setSExclusionError(false);
    }else{
      setSExclusionError(true);
    }
    } else {
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
      setCityId(props.cityData);
  }, [props.cityData]);

  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      setProfileImageError("");
      setProfileImage(URL.createObjectURL(e.target.files[0]));
      setImage({ preview: URL.createObjectURL(e.target.files[0]), raw: e.target.files[0] });
    }
  };

  const formData = new FormData();
  const handleUpload = async (e: any) => {
    if (image && image.raw) {
      e.preventDefault();
      setProfileImageError("");
      const userId = userDetails && userDetails.id ? userDetails.id : "";
      formData.append("userImg", image.raw);
      formData.append("id", userId);
      setLoading(true);
      props.uploadProfileImage(formData);
      setImage({ preview: "", raw: "", });
    } else {
      setProfileImageError("Profile picture not selected");
    }
  };

  const handleClearImg = (e: any) => {
    setProfileImage("");
    setImage({...image,raw:''});
  };

  useEffect(() => {
    setPlanDetails(props.currentPlanDetailsData);
    if ( props.currentPlanDetailsData && props.currentPlanDetailsData.planExpiryDate ) {
      planExpDate = props.currentPlanDetailsData.planExpiryDate;
      let subStrDate = JSON.stringify(planExpDate).slice(1, 11);
      setNewArray(subStrDate);
    }
  }, [props.currentPlanDetailsData]);

  useEffect(() => {
    if (props.message && props.message.page == "edit_profile_picture") {
      setLoading(false);
    }
  }, [props.message]);
  
// minimum Age 18 or 18+
  var minimumAge18 = new Date();
  minimumAge18.setFullYear(minimumAge18.getFullYear() - 18 );
// minimum Age 18 or 18+  
  
const openEditMobilePopup = () =>{
  setModalShow(true);
  setModalForm({country_code:'',mobile:''});
  setModalFormOTP('');
  setCountryCodeErr('');
  setMobileErr('');
  setModalLoader(false);
  setSuccessFlag(false);
  setOTPFormFlag(false);
  props.clearMessages(null);
}

const closeEditMobilePopup = () =>{
  setModalShow(false);
  setModalForm({country_code:'',mobile:''});
  setCountryCodeErr('');
  setMobileErr('');
  setModalLoader(false);
  setSuccessFlag(false);
  setOTPFormFlag(false);
  props.clearMessages(null);
}

const handleCountryDialCode = (e:any) =>{
  setEditProfileForm({...editProfileForm,[e.target.name]:e.target.value});
}

const handleModalOnChange = (e:any) =>{
  var letters = /^[A-Za-z]+$/;
  if(e.target.value.match(letters)){
    setModalForm({...modalForm,mobile:''});
  }else{
    setModalForm({...modalForm,mobile:e.target.value});
  }

  if(e.target.name === 'country_code'){
    setModalForm({...modalForm,country_code:e.target.value});
  }
}
const handleEditMobileNumberAction = (e:any,requestType:string) =>{
  e.preventDefault();
  let OldPhoneNo = props.profileData && props.profileData.mobile ? props.profileData.mobile : "" ;
  let payload = {"mobile":modalForm.country_code + modalForm.mobile};
  if(requestType === 'getOTP'){
    setNotification({bgcolor:'',message:''});
    if(!modalForm.country_code && !modalForm.mobile){
      setCountryCodeErr('Country dial code is required');
      setMobileErr('Phone number is required');
    }else if(!modalForm.country_code){
      setCountryCodeErr('Country dial code is required');
    }else if(!modalForm.mobile){
      setMobileErr('Phone number is required');
    }else{
      setCountryCodeErr('');
      setMobileErr('');
      if(OldPhoneNo === payload.mobile){
        setMobileErr('Phone number already exists');
      }else{
        setCountryCodeErr('');
        setMobileErr('');
        setModalLoader(true);
        props.getOTP_ToEditPhone(payload);
      }
    }
  }else if(requestType === 'verifyOTP'){
    let payload = {
      "mobile" : modalForm.country_code+modalForm.mobile,
      "otp"    : modalFormOTP,
    }
    if(payload.otp){
      setModalLoader(true);
      props.verifyOTP_ToEditPhone(payload);
    }else{
      setOTPErr('OTP is required');
    }
  }else if(requestType === 'resendOTP'){
    setModalLoader(true);
    setModalFormOTP('');
    props.getOTP_ToEditPhone(payload);
  }
}

useEffect(() =>{
if(props.getOTP_ToEditPhoneNo && props.getOTP_ToEditPhoneNo.code === 200 && props.getOTP_ToEditPhoneNo.status === 'Success'){
  setModalLoader(false);
  setSuccessFlag(true);
  setOTPFormFlag(true);
  setNotification({bgcolor:'success',message:props.getOTP_ToEditPhoneNo.message.replace('send','sent')});
  props.clearOTP(null);
  setTimeout(()=>{
    setResendOTP_Flag(true);
  },15*60*1000)
}
},[props.getOTP_ToEditPhoneNo])

const handleOTP_OnChange = (e:any) =>{
  var letters = /^[A-Za-z]+$/;
  if(e.target.value.match(letters)){
    setOTPErr('')
    setModalFormOTP('');
  }else if(e.target.value){
    setOTPErr('');
    setModalFormOTP(e.target.value)
  }else{
    setModalFormOTP('');
    setOTPErr('OTP is required');
  }
}

useEffect(() =>{
  if(props.verifyOTP_ToEditPhoneNo && props.verifyOTP_ToEditPhoneNo.code === 404 && props.verifyOTP_ToEditPhoneNo.status === 'error'){
    setSuccessFlag(true);
    setOTPFormFlag(true);
    setNotification({bgcolor:'danger',message:props.verifyOTP_ToEditPhoneNo.message});
    props.clearVerifyOTP(null);
    setModalLoader(false);
  }else if(props.verifyOTP_ToEditPhoneNo && props.verifyOTP_ToEditPhoneNo.code === 200){
    setSuccessFlag(true);
    setOTPFormFlag(false);
    setNotification({bgcolor:'success',message:props.verifyOTP_ToEditPhoneNo.message.replace('no.','number')});
    props.clearVerifyOTP(null);
    props.getProfile();
    setModalLoader(false);
  }

  if(props.message && props.message.status === 'error'){
    setModalFormOTP('');
    setSuccessFlag(true);
    setOTPFormFlag(true);
    setNotification({bgcolor:'danger',message:props.message.data});
    setModalLoader(false);
  }
},[props.verifyOTP_ToEditPhoneNo,props.message])


const handleCheckboxSE= (e:any)=>{
  if (e.target.checked){
    setSExclusion(true)
    setSExclusionError(false);
    setEditProfileForm({...editProfileForm,sucideExlude:1});
  }else{
    setSExclusion(false)
    setSExclusionError(true);
    setEditProfileForm({...editProfileForm,sucideExlude:0});
  }
}
//---------------------------------------------------------------------------------------//

  return (
    <Layout>
      <Container className={loader ? `text-center` : ``}>
        <div className="myProfile p-3 mb-3" style={{background: "linear-gradient(268.67deg, #1A202E 1.01%, rgba(26, 32, 46, 0.97) 78.02%, rgba(26, 32, 46, 0.65) 98.78%)"}}>
          <Container>
            <h3 className="mb-5" style={loader ? {textAlign:'left'} : {}}>My Profile</h3>
            {loader ? 
              <div className="lds-dual-ring text-center">
                <span className="text-warning">Loading... please wait!</span>
              </div>
            :
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="formRow">
                      <Form.Label htmlFor="">Profile Picture </Form.Label>
                      <div className="imageInput">
                        <label htmlFor="upload-button">
                          {profileImage ? 
                            ( <img src={profileImage} alt="" width={"150"} height={"150"} style={{ borderRadius: "10px" }} /> ) 
                          : 
                            ( <>
                                <div className="image"><img src={defaultImg} width={"150"} height={"150"} style={{ borderRadius: "10px" }} /></div> 
                                <small className="text-light" style={{fontSize:'12px',marginLeft:'18px'}}>Click on above icon</small>
                              </>
                            )
                          }
                        </label>
                        
                        {loading && (
                          <div className="progress" style={{ width: "150px" }}>
                            <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} style={{ width: "100%" }} ></div>
                          </div>
                        )}
                      </div>
                      <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleImageChange} />
                      <br />
                      <Button style={{color:'#fff'}} className="uploadBtn" size="sm" onClick={handleUpload} type="button" > Upload </Button>
                      <Button style={{ marginLeft: "1rem", color: "#fff" }} className="clearBtn" size="sm" onClick={handleClearImg} type="button" > Clear </Button>
                      {profileImageError && ( <><br/><span className="text-danger">{profileImageError}</span></> )}
                    </Form.Group>
                  </Col>
                  
                  <Col md={6}>
                    {planDetails && planDetails.boxPlans ? (
                      <div className="currentPlanStatus">
                        <div key={planDetails.id} style={{}}>
                          <p className="text-warning">
                            <Form.Group className="formRow">
                              <Row>
                                <Col>
                                  <InputGroup className="rounded">
                                    <Form.Control title={planDetails.boxPlans.planType.toUpperCase()+(newArray ? ' : '+new Date(newArray).toLocaleDateString() : '')} type="text" disabled={true} style={{ color:'#fff', cursor: "not-allowed",background:'rgba(239, 161, 26, 0.13)',height:'35px',fontSize:'14px',border:'1px solid #E79E1F'}} name="identity_number" value={planDetails.boxPlans.planType && planDetails.boxPlans.planType.length > 20 ? planDetails.boxPlans.planType.toUpperCase() +''+'...'+(newArray ? ' : '+new Date(newArray).toLocaleDateString() : '') : planDetails.boxPlans.planType.toUpperCase()+(newArray ? ' : '+new Date(newArray).toLocaleDateString() : '')}  />
                                    <InputGroup.Text title={planDetails.boxPlans.planType.toUpperCase()} style={{ cursor: "not-allowed",height:'35px',fontWeight:'800'}} className="bg-success text-light">CURRENT PLAN</InputGroup.Text>                 
                                  </InputGroup>
                                </Col>
                              </Row>
                            </Form.Group>
                          </p>
                        </div>
                      </div> ) 
                    : 
                      ( "" )
                    }
                  </Col>
                </Row>
                
                <Form.Group className="formRow mt-4">
                  <Row>
                    <Col>
                      <Form.Label>First Name *</Form.Label>
                      <Form.Control type="text" autoComplete="off" id="editProfile_FName" name="first_name" placeholder="Please enter your first name here..." onChange={handleChange} value={  editProfileForm && editProfileForm.first_name ? editProfileForm.first_name : "" } onInput={(e: React.ChangeEvent<HTMLInputElement>) => { e.target.value = e.target.value.trimStart(); }} />
                      {errors.first_name && ( <span className="text-danger">{errors.first_name}</span> )}
                    </Col>
                    <Col>
                      <Form.Label>Last Name *</Form.Label>
                      <Form.Control type="text" id="editProfile_LName" name="last_name" placeholder="Please enter your last name here..." onChange={handleChange} onInput={(e: React.ChangeEvent<HTMLInputElement>) => { e.target.value = e.target.value.trimStart(); }} value={ editProfileForm && editProfileForm.last_name ? editProfileForm.last_name : "" } />
                      {errors.last_name && ( <span className="text-danger">{errors.last_name}</span> )}
                    </Col>
                  </Row>
                </Form.Group>
              
                <Form.Group className="formRow">
                  <Row>
                    <Col>
                      <Form.Label>Date of Birth *</Form.Label>
                      <Form.Control type="date" style={{ cursor: "pointer" }} id="editProfile_DOB" name="dob" placeholder="dd/mm/yyyy" max={minimumAge18.toISOString().substring(0,10)} onChange={handleChange} value={ editProfileForm && editProfileForm.dob ? editProfileForm.dob  : "" } required />
                      {errors.dob && ( <span className="text-danger">{errors.dob}</span> )}
                    </Col>
                    <Col>
                      <Form.Label>Identity Number *</Form.Label>
                      {/* onInput={(e: React.ChangeEvent<HTMLInputElement>) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(  0,  e.target.maxLength  ); }} */}
                      {/* onWheel={(e) => e.currentTarget.blur()}  */}
                      <Form.Control type="text" name="identity_number" id="editProfile_IdentityNo" style={editProfileForm && editProfileForm.identity_number ? {textTransform:'uppercase'} : {}} placeholder="Please enter Identity number here..." onChange={handleChange} value={ editProfileForm && editProfileForm.identity_number ? editProfileForm.identity_number : "" }  maxLength={20} min="7" />
                      {errors.identity_number && ( <span className="text-danger">{errors.identity_number}</span>  )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="formRow">
                  <Row>
                    <Col>
                      <Form.Label>Email id</Form.Label>
                      <InputGroup className="rounded">
                        <Form.Control type="text" id="emailId" name="emailId" disabled={true} style={{ color:'lightgrey', cursor: "not-allowed",backgroundColor:'grey'}} value={ props.profileData && props.profileData.email ? props.profileData.email  : "" } />
                        {/* <InputGroup.Text style={{ cursor: "not-allowed",backgroundColor:'#fff',color:"green",height:'50px',fontWeight:'800'}}>Verified</InputGroup.Text>                 
                        <InputGroup.Text style={{ cursor: "not-allowed",backgroundColor:'green',color:"#fff",height:'50px',width:'35px',fontWeight:'800'}}>&#x2713;</InputGroup.Text> */}
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label>Mobile Number</Form.Label>
                      <InputGroup className="rounded">
                        <Form.Control type="text" disabled={true} style={{ color:'lightgrey', cursor: "not-allowed",backgroundColor:'grey' }} name="identity_number" value={ props.profileData && props.profileData.mobile ? props.profileData.mobile : "" }  />
                        <InputGroup.Text className="pointer" style={{backgroundColor:'#fff',color:"green",height:'50px',fontWeight:'800'}} onClick={openEditMobilePopup}><u>Edit</u></InputGroup.Text>
                        {/* <InputGroup.Text style={{ cursor: "not-allowed",backgroundColor:'green',color:"#fff",height:'50px',width:'35px',fontWeight:'800'}}>&#x2713;</InputGroup.Text>                  */}
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="formRow">
                  <Row>
                    <Col>
                      <Form.Label>State *</Form.Label>
                      <Form.Select onChange={selectState} value={stateValue}  className="mb-4" name="select_state" id="select_state">
                        <option hidden>Select State</option>
                        {stateID && stateID.map((state: any) => (
                          <option value={state.id} key={state.id}> {state.name} </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>City *</Form.Label>
                      <Form.Select onChange={selectCity} value={cityValue} name="select_city" id="select_city">
                        <option hidden>Select City</option>
                        {cityId && cityId.map((city: any) => (
                          <option value={city.id} key={city.id}> {city.name} </option>
                        ))}
                      </Form.Select>
                      {errors.city && ( <span className="text-danger">{errors.city}</span> )}  
                    </Col>
                 
                  </Row>
                  <Col>
                    <Form.Label>Sign In Options</Form.Label>

                    <div className="abc">
                      <div className="main-chk d-flex" >
                      <Form.Check
                       className="col-3 h62"
                          type="checkbox"
                          label="2 Factor Authentication*"
                          
                          onChange={handle2FAToggle}
                          checked={is2FAEnabled}
                        />
                        <label htmlFor="check2" className="m-3 h48">
                          {is2FAEnabled ? <text style={{ marginLeft: "-2%", color: "#fff" }}>It is recommended that you keep two factor authentication activated to ensure the security of your account. You can choose to receive otp's via email, phone or both. </text> : <text style={{ marginLeft: "-2%", color: "#fff" }}>You can Directly Signin with your login credentials as it is unchecked now</text>}
                        </label>

                        
                      </div>

                      {is2FAEnabled && (

                        <div className="mt-2">

                          <Form.Check
                            type="checkbox"
                            label="Email"
                            onChange={handleEmailToggle}
                            checked={isEmailEnabled}
                          />
                          <Form.Check
                            type="checkbox"
                            label="SMS"
                            onChange={handleSMSToggle}
                            checked={isSMSEnabled}
                          />
                        </div>
                      )}
                    </div>
                  </Col>

                </Form.Group>

                {/* SUICIDE EXCLUSION */}
                <Form.Group className="inputCheck_editProfile text-light me-2">
                  <Form.Check type="checkbox" id="check2" defaultChecked={editProfileForm.sucideExlude ? true : false} onClick={(e:any)=>{ handleCheckboxSE(e)}} />
                  <label htmlFor="check2" className="m-0">
                    <u style={{marginLeft:"1%",color:"#fff"}}><Link target={"_blank"} to="/suicide-exclusion" className="text-light">Suicide Exclusion</Link></u><br/>
                    <text style={{marginLeft:"-2%",color:"#fff"}}>I accept that my boxes will not be released should my passing be as a result of suicide.</text>
                  </label>
                </Form.Group>
                {(!sExclusion && sExclusionError) && <span className="text-danger">Suicide Exclusion is required</span>}
                
                {/* <hr className="mt-3" style={{border:'0.5px solid #E79E1F'}}/>    
                <Form.Group className="formRow">
                  <Form.Label style={{fontWeight: '700', textTransform: 'uppercase'}}>Public Box Info</Form.Label>
                  <div><small className="formRow text-center" style={{fontSize:'20px', fontWeight: '700',  color: '#E79E1F'}}>The information below is optional and will be displayed in your Public Box, viewable on the BeyondBox Box Records page and our social media accounts.</small></div>
                </Form.Group>
                <Form.Group className="formRow">
                  <Form.Label>Life’s work</Form.Label>
                  <Form.Control as="textarea" name="life_work" id="editProfile_lifesWork" rows={3} placeholder="Optional info for Box Records Profile" onChange={handleChange} value={ editProfileForm && editProfileForm.life_work ? editProfileForm.life_work : "" } style={{ height: "auto" }} />
                </Form.Group>

                <Form.Group className="formRow">
                  <Form.Label>Quote</Form.Label>
                  <Form.Control as="textarea" name="quote" id="editProfile_quote" rows={3} placeholder="Optional info for Box Records Profile" onChange={handleChange} value={ editProfileForm && editProfileForm.quote ? editProfileForm.quote : "" } style={{ height: "auto" }} />
                </Form.Group>

                <Form.Group className="formRow">
                  <Form.Label>What I lived for</Form.Label>
                  <Form.Control as="textarea" name="what_i_lived_for" id="editProfile_whatILivedFor" rows={3} placeholder="Optional info for Box Records Profile" onChange={handleChange} value={ editProfileForm && editProfileForm.what_i_lived_for ? editProfileForm.what_i_lived_for : "" } style={{ height: "auto" }} />
                </Form.Group> */}

                <hr className="mt-3" style={{border:'0.5px solid #E79E1F',marginTop:'20px'}}/>           
                
                {/* KIN DETAILS STARTS HERE */}
                <Form.Group>
                  <small className="formRow text-center" style={{fontSize:'20px', fontWeight: '700',  color: '#E79E1F'}}>
                    ( Please nominate a loved one or trustee below, who will notify us of your passing and provide the required verification in order for us to activate the release of your created boxes. )
                  </small>
                  <Row>
                    <Form.Label className="mt-3">Kin/Trustee Details</Form.Label>
                    <Col md={3}>
                      <Form.Label>Name *</Form.Label>
                      <Form.Control type="text" name="kinName" id="editProfile_kinName" placeholder="Please enter name" onChange={handleChange} onInput={(e: React.ChangeEvent<HTMLInputElement>) => { e.target.value = e.target.value.trimStart(); }} value={ editProfileForm && editProfileForm.kinName ? editProfileForm.kinName : "" } />
                      {errors.kinName && ( <span className="text-danger">{errors.kinName}</span> )}
                    </Col>
                    <Col md={4}>
                      <Form.Label>Email *</Form.Label>
                      <Form.Control type="text" name="kinEmail" id="editProfile_kinEmail" placeholder="Please enter email" onChange={handleChange} value={ editProfileForm && editProfileForm.kinEmail ? editProfileForm.kinEmail : "" } />
                      {errors.kinEmail && ( <span className="text-danger">{errors.kinEmail}</span> )}
                    </Col>
                    <Col md={5}>
                      <Form.Label>Phone Number *</Form.Label>
                      <Row>
                        <Col md={6}>
                        <Form.Select name="kinCountryDialCode" onChange={handleCountryDialCode} value={editProfileForm.kinCountryDialCode}>
                          <option hidden>Select</option>
                          {props.countryDialCodeList && props.countryDialCodeList.map((dailCode: any) => (
                            <option value={dailCode.phonecode} title={dailCode.nicename} key={dailCode.id}> {dailCode.nicename && dailCode.nicename.length > 30 ? dailCode.nicename.substring(0,30)+'...' : dailCode.nicename} ({dailCode.phonecode && dailCode.phonecode}) </option>
                          ))}
                        </Form.Select>
                        {errors.kinCountryDialCode && ( <span className="text-danger">{errors.kinCountryDialCode}</span> )}
                        </Col>
                        <Col md={6}>
                        <Form.Control type="number" name="kinPhoneNumber" id="editProfile_kinPhoneNumber" placeholder="Please enter phone number" onChange={handleChange} value={ editProfileForm && editProfileForm.kinPhoneNumber ? editProfileForm.kinPhoneNumber : "" } />
                        {errors.kinPhoneNumber && ( <span className="text-danger">{errors.kinPhoneNumber}</span> )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Row>
                    <Form.Label>Kin/Trustee  <small className="text-light">(Optional)</small></Form.Label>
                    <Col md={3}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text"  name="kinNameOpt" id="editProfile_kinNameOpt"  placeholder="Please enter name" onChange={handleChange} onInput={(e: React.ChangeEvent<HTMLInputElement>) => {  e.target.value = e.target.value.trimStart(); }} value={ editProfileForm && editProfileForm.kinNameOpt ? editProfileForm.kinNameOpt : "" } />
                      {errors.kinNameOpt && ( <span className="text-danger">{errors.kinNameOpt}</span> )}
                    </Col>
                    <Col md={4}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="text" name="kinEmailOpt" id="editProfile_kinEmailOpt" placeholder="Please enter email" onChange={handleChange} value={ editProfileForm && editProfileForm.kinEmailOpt  ? editProfileForm.kinEmailOpt : "" } />
                      {errors.kinEmailOpt && ( <span className="text-danger">{errors.kinEmailOpt}</span> )}
                    </Col>
                    <Col md={5}>
                      <Form.Label>Phone Number</Form.Label>
                      <Row>
                        <Col md={6}>
                        <Form.Select name="kinCountryDialCodeOpt" onChange={handleCountryDialCode} value={editProfileForm.kinCountryDialCodeOpt}>
                          <option hidden>Select</option>
                          {props.countryDialCodeList && props.countryDialCodeList.map((dailCode: any) => (
                            <option value={dailCode.phonecode} title={dailCode.nicename} key={dailCode.id}> {dailCode.nicename && dailCode.nicename.length > 30 ? dailCode.nicename.substring(0,30)+'...' : dailCode.nicename} ({dailCode.phonecode && dailCode.phonecode})</option>
                          ))}
                        </Form.Select>
                        {errors.kinCountryDialCodeOpt && ( <span className="text-danger">{errors.kinCountryDialCodeOpt}</span> )}
                        </Col>
                        <Col md={6}>
                      <Form.Control type="number" name="kinPhoneNumberOpt" id="editProfile_kinPhoneNumberOpt" placeholder="Please enter phone number" onChange={handleChange} value={ editProfileForm && editProfileForm.kinPhoneNumberOpt ? editProfileForm.kinPhoneNumberOpt  : "" } min="0" onWheel={(e) => e.currentTarget.blur()} />
                      {errors.kinPhoneNumberOpt && ( <span className="text-danger"> {errors.kinPhoneNumberOpt} </span>  )}
                      </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                {/* KIN DETAILS ENDS HERE */}

                {props.message && props.message.page !== 'edit_profile_picture' && ( <div className={ props.message && props.message.status === "success" ? "text-success text-center" : "text-danger text-center" } > {props.message.data} </div> )}
                <br />
                <div className="d-flex justify-content-center">
                  <Button className="darkBtn" onClick={handleSubmit} type="submit">Update</Button>
                </div>
              </Form>
            }
            <DisplayModal backdrop="static" show={modalShow} onHide={closeEditMobilePopup} name='Update Phone Number'>
              <Form className="formRow">
                <hr style={{border:'0.5px solid #E79E1F'}}/> 
                {modalLoader ? 
                  <div className="lds-dual-ring text-center">
                    <span className="text-warning">Loading... please wait!</span>
                  </div>
                  :
                  !successFlag ?
                    <Row>
                      <Col lg={3}>
                        <Form.Label className="pt-2" style={{color:'#E79E1F'}}>Phone Number :</Form.Label>
                      </Col>  
                      <Col lg={4} className="mb-lg-0 mb-2">
                        <Form.Select name="country_code" value={modalForm.country_code} onChange={handleModalOnChange}>
                          <option hidden>Select dial code</option>
                          {props.countryDialCodeList && props.countryDialCodeList.map((dailCode: any) => (
                            <option value={dailCode.phonecode} title={dailCode.nicename} key={dailCode.id}> {dailCode.nicename && dailCode.nicename.length > 10 ? dailCode.nicename.substring(0,10)+'...' : dailCode.nicename} ({dailCode.phonecode && dailCode.phonecode})</option>
                          ))}
                        </Form.Select>
                        {countryCodeErr && <p className="text-danger text-center">{countryCodeErr}</p>}
                      </Col>
                      <Col lg={5}>
                        <Form.Control type="text" name="mobile" placeholder="Please enter phone number" value={modalForm.mobile} onChange={handleModalOnChange} maxLength={13}/>
                        {mobileErr && <p className="text-danger text-center">{mobileErr}</p>}
                      </Col>
                    </Row>
                  :
                    successFlag &&
                      <>
                        <Row className="justify-content-center">
                          {notification.message && 
                            <Col lg={5}>
                              <p className={`rounded p-1 bg-${notification.bgcolor} text-center text-light`}>{notification.message}</p>
                            </Col>
                          }
                        </Row>
                        { OTPFormFlag &&
                        <>
                        <Row>
                          <Col lg={3} className="justify-content-lg-end d-flex">
                            <Form.Label className="pt-2" style={{color:'#E79E1F'}}>Phone Number :</Form.Label>
                          </Col>  
                          <Col lg={8} className="text-warning pt-2" style={{display:'flex', justifyContent:'left'}}>
                            <Form.Label>{'('+modalForm.country_code+')'+' '+modalForm.mobile}</Form.Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3} className="justify-content-lg-end d-flex">
                            <Form.Label className="pt-2" style={{color:'#E79E1F'}}>OTP :</Form.Label>
                          </Col>  
                          <Col lg={8}>
                            <Row className="align-items-end">
                              <Col lg={8} >
                                <Form.Control style={{width:'100%'}} type="text" name="OTP" placeholder="Please enter OTP here..." maxLength={4} onChange={handleOTP_OnChange} value={modalFormOTP}/>
                              </Col>
                              {resendOTP_Flag &&
                              <Col lg={4} className="text-left">
                                <Form.Label className="text-light m-0 pointer" onClick={(e) => handleEditMobileNumberAction(e,'resendOTP')}><u>Resend OTP</u></Form.Label>
                              </Col>
                              }
                            </Row>
                            {otpErr && <span className="text-danger">{otpErr}</span>}
                          </Col>
                        </Row>
                        </>
                        }
                      </>          
                    }
                    <hr style={{border:'0.5px solid #E79E1F'}}/>  
                    <Row className="justify-content-end">
                      {/* <Col md={8}></Col> */}
                      <Col xs="auto" className="pe-0">
                        <Button type="submit" className="backBtn" style={modalLoader || (successFlag && !OTPFormFlag) ? {display:"none"} : {border:'0.5px solid #E79E1F'}} onClick={(e) => handleEditMobileNumberAction(e,(successFlag ? 'verifyOTP' : 'getOTP'))}>{successFlag ? 'Update' : 'Continue'}</Button>
                      </Col>
                      <Col xs="auto">
                        <Button className="backBtn" style={{border:'0.5px solid #E79E1F'}} onClick={closeEditMobilePopup}>{!OTPFormFlag && successFlag ? 'Close' : 'Cancel'}</Button>
                      </Col>
                    </Row>
                  </Form>
                </DisplayModal>
              </Container>
            </div>
          </Container>
        </Layout>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  cityList                : (data: any) => dispatch(cityList(data)),
  stateList               : (data: any) => dispatch(stateList(data)),
  getProfile              : (data: any) => dispatch(getProfile(data)),
  getUserName             : (data: any) => dispatch(getUserName(data)),
  getEditProfile          : (data: any) => dispatch(getEditProfile(data)),
  uploadProfileImage      : (data: any) => dispatch(uploadProfileImage(data)),
  getKinDetails           : (data: any) => dispatch(getKinDetails(data)),
  getCountryDialCode      : (data: any) => dispatch(getCountryDialCode(data)),
  getOTP_ToEditPhone      : (data: any) => dispatch(getOTP_ToEditPhone(data)),
  verifyOTP_ToEditPhone   : (data: any) => dispatch(verifyOTP_ToEditPhoneNo(data)),
  clearMessages           : (data: any) => dispatch({type:COMMON_MESSAGE,data}),
  clearOTP                : (data: any) => dispatch({type:USER_PROFILE.SET_OTP_TO_EDIT_PHONE_NO,data}),
  clearVerifyOTP          : (data: any) => dispatch({type:USER_PROFILE.SET_OTP_VERIFICATION_TO_EDIT_PHONE_NO,data}),
});

const mapStateToProps = (state: any) => {
  return {
    cityData               : state.CountryReducer       && state.CountryReducer.cityData                    ? state.CountryReducer.cityData                    : null,
    stateData              : state.CountryReducer       && state.CountryReducer.stateData                   ? state.CountryReducer.stateData                   : null,
    profileData            : state.ProfilePageReducer   && state.ProfilePageReducer.profileData             ? state.ProfilePageReducer.profileData             : null,
    message                : state.CommonMessageReducer && state.CommonMessageReducer.message               ? state.CommonMessageReducer.message               : null,
    usernameData           : state.AuthReducer          && state.AuthReducer.usernameData                   ? state.AuthReducer.usernameData                   : null,
    countryDataId          : state.CountryReducer       && state.CountryReducer.countryDataId               ? state.CountryReducer.countryDataId               : null,
    editProfileData        : state.ProfilePageReducer   && state.ProfilePageReducer.editProfileData         ? state.ProfilePageReducer.editProfileData         : null,
    kinDetailsData         : state.ProfilePageReducer   && state.ProfilePageReducer.kinDetailsData          ? state.ProfilePageReducer.kinDetailsData          : [],
    currentPlanDetailsData : state.PlansReducer         && state.PlansReducer.currentPlanDetailsData        ? state.PlansReducer.currentPlanDetailsData        : null,
    countryDialCodeList    : state.webReducer           && state.webReducer.countryDialCodeList             ? state.webReducer.countryDialCodeList             : null,
    getOTP_ToEditPhoneNo   : state.ProfilePageReducer   && state.ProfilePageReducer.getOTP_ToEditPhoneNo    ? state.ProfilePageReducer.getOTP_ToEditPhoneNo    : null, 
    verifyOTP_ToEditPhoneNo: state.ProfilePageReducer   && state.ProfilePageReducer.verifyOTP_ToEditPhoneNo ? state.ProfilePageReducer.verifyOTP_ToEditPhoneNo : null, 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
