import { BOX_API,COMMON_MESSAGE } from "../constant";

export const getBoxCover = (data) => {
  return {
    type: BOX_API.GET_BOX_COVER_PHOTO,
    payload: data,
  };
};

export const getBoxCoverUploadedByAdmin = (data) => {
  return {
    type: BOX_API.GET_BOX_COVER_PHOTO_UPLOADED_BY_ADMIN,
    payload: data,
  }
}

export const selectBoxCover = (data) => {
  return {
    type: BOX_API.SELECT_BOX_COVER_PHOTO,
    payload: data,
  };
};
export const getUserDetailsByEmail = (data) => {
  return {
    type: BOX_API.GET_USER_BY_EMAIL,
    payload: data,
  };
};
export const getBoxIcon = (data) => {
  return {
    type: BOX_API.GET_BOX_ICON,
    payload: data,
  };
};
export const createBox = (data) => {
  return {
    type: BOX_API.CREATE_BOX,
    payload: data,
  };
};
export const getBoxList = (data) => {
  return {
    type: BOX_API.GET_BOX_LIST,
    payload: data,
  };
};
export const getPublicBoxList = (data) => {
  return {
    type: BOX_API.GET_PUBLIC_BOX_LIST,
    payload: data,
  };
};

export const getReceivedBoxList = (data) => {
  return {
    type: BOX_API.GET_RECEIVED_BOX_LIST,
    payload: data,
  };
};

export const getChildReceivedBoxList = (data) => {
  return {
    type: BOX_API.GET_CHILD_RECEIVED_BOX_LIST,
    payload: data,
  };
};

// Action objects for Created box page:-
export const getBoxDetails = (data) => {
  return {
    type: BOX_API.GET_CREATED_BOX_DETAILS,
    payload: data,
  };
};

export const uploadBoxFiles = (data) => {
  return {
    type: BOX_API.UPLOAD_BOX_FILES,
    payload: data,
  };
};

// Action objects for folder inside Created box folder:-
export const getUploadedFilesByBoxIdandFolderId = (data) => {
  return {
    type: BOX_API.GET_UPLOADED_FILES_DETAILS,
    payload: data,
  };
};

// Delete uploaded files by its id:-
export const deleteUploadedFilesByFileId = (data) => {
  return {
    type: BOX_API.DELETE_UPLOADED_FILES,
    payload: data,
  };
};

//schedule box

export const scheduleBox = (data) => {
  return {
    type: BOX_API.SCHEDULE_BOX,
    payload: data,
  };
};

export const getBoxById = (data) => {
  return {
    type: BOX_API.GET_BOX_BY_ID,
    payload: data,
  };
};
export const clearBoxById = (data) => {
  return {
    type: BOX_API.CLEAR_BOX_BY_ID_TO_STATE,
    payload: data,
  };
};
// Edit box
export const updateBox = (data) => {
  return {
    type: BOX_API.UPDATE_BOX,
    payload: data,
  };
};

// Clear redux variable on edit box
export const clearBoxData = (data) => {
  return {
    type: BOX_API.SET_UPDATE_BOX_TO_STATE,
    payload: data,
  };
};

// Upload image on create / edit box page
export const uploadCoverImageByUser = (data) => {
  return {
    type: BOX_API.UPLOAD_BOXCOVER_IMAGE_BY_USER,
    payload: data,
  };
};

// Remove box
export const deleteBox = (data) => {
  return {
    type: BOX_API.DELETE_BOX,
    payload: data,
  };
};

// Box Preview
export const listBoxContent = (data) => {
  return {
    type: BOX_API.LIST_BOX_CONTENT,
    payload: data,
  };
};

// Recieved Box Preview
export const getReceivedBoxDetails = (data) => {
  return {
    type: BOX_API.GET_RECEIVED_BOX_DETAILS,
    payload: data,
  };
};

export const getUserById = (data) => {
  return {
    type: BOX_API.GET_USER_BY_ID,
    payload: data,
  };
};

// Public box action with and without keyword
export const boxRecordsListingWith_n_WithoutSearchKeyword = (data) => {
  return {
    type: BOX_API.GET_BOX_RECORDS,
    payload: data,
  };
};

// Public box detail content
export const getPublicBoxContent = (data) => {
  return {
    type: BOX_API.GET_PUBLIC_BOX_DETAIL_CONTENT,
    payload: data,
  };
};

// Created box recipient update action
export const updateRecipient = (data) => {
  return {
    type: BOX_API.SEND_RECIPIENT_DETAILS_FOR_UPDATE,
    payload: data,
  };
};

// Clear Common Message variable for Box
export const clearCommoneMessage = (data) => {
  return {
    type: COMMON_MESSAGE,
    payload: data,
  };
};

// Category wise scheduler action:---
export const categoryWiseSchedulerByFileId = (data) => {
  return {
    type: BOX_API.SET_CATEGORYWISE_SCHEDULER_ON_UPLOADED_FILES,
    payload: data,
  };
};

// Message View Page:---
export const updateMessage = (data) => {
  return {
    type: BOX_API.UPDATE_MESSAGE,
    payload: data,
  };
};

export const getUserDetailsByName = (data) => {
  return {
    type: BOX_API.GET_USER_BY_NAME,
    payload: data,
  };
};

export const uploadCoverPhotoByUser = (data) => {
  return {
    type: BOX_API.UPLOAD_COVERPHOTO_BY_USER,
    payload: data,
  };
};

export const saveLinks = (data) => {
  return {
    type: BOX_API.SAVE_AUDIO_OR_VIDEO_LINKS,
    payload: data,
  };
};

export const getRelationalData = (data) => {
  return {
    type: BOX_API.GET_RELATIONSHIP_MASTER_DATA,
    payload: data,
  };
};

export const newRecipientProfilePic = (data) => {
  return {
    type: BOX_API.UPLOAD_RECIPIENT_PROFILE_PIC,
    payload: data,
  }
}

export const removeScheduler = (data) => {
  return {
    type: BOX_API.REMOVE_FILE_SCHEDULER,
    payload: data,
  }
}

// RELEASE BOXES FOR DIFF EMAIL
export const releaseBoxes = (data) => {
  return {
    type: BOX_API.SUBMIT_RELEASE_BOX_CONFIRMATION,
    payload: data,
  }
}


