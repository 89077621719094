import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../../layout/Layout";
import { connect } from "react-redux";
import { cancelPlan, getAllPlan, getCurrentPlan, getPlans, selectFreePlan } from "../../../redux/actions/plansAction";
import selected from '../../../assets/images/selected.png';
import upcoming from '../../../assets/images/upcoming.png';
import { LIFE_TIME_PLAN } from "../../../redux/constant";
var converter = require('number-to-words');


  const PlansWithpromoCode = (props: any) => {
  const navToPayment                   = useNavigate();
  const datePickr                      = React.createRef<HTMLInputElement>();
  const [planDetails,  setPlanDetails] = useState([]);
  const [userDetails,  setUserDetails] = useState<any>({});
  const [allPlans,     setAllPlans]    = useState<any>([]);
  const [currentPlan,  setCurrentPlan] = useState(null as any);
  const [upcomingPlan, setUpcomingPlan]= useState(null as any);
  const discountPlanMessage            ="<div style='color:red;font-size:20px'>You are about to select the Lifetime membership plan.<br/> This plan is free and has Unlimited features.<br/> Are you sure you would like to continue ?</div>";
  const paidPlanMessage                = "<div style='color:red;font-size:20px'>Do you really want to choose this plan?<br/> This will activate, once your current paid plan expires.</div>";
  const freePlanMessage                = "<div style='color:red;font-size:20px'>You are about to select the Family membership plan.<br/> This plan is free but has limited features.<br/> Are you sure you would like to continue ?</div>";
  const cancelMessage                  = "<div style='font-size:20px'>Cancelled successfully!</div>";  
  const MySwal                         = withReactContent(Swal);
  let   userData                       = { payment: { boxPlansId: 0, planCode: "", subscriptionId: "", amount: 0, status: "", planisActive: 0, } };
  let   planAmount: any, planType: any, planId: any, user_FName:any,user_LName:any, user_Email:any,user_Id:any,planduration:any,plan_frequency:any,currPlan_token:any,discUsdAmount:any,paypalPlanId:any ;
  const [totalSpace,setTotalSpace] = useState(null);
  const[country,setCountry]=useState('')
  ////
 

  ///
  useEffect(()=>{
    if(props.setCountryFromApi!=null)
      {
        setCountry(props.setCountryFromApi)
      }
      else{
        if(props.profileData)
          {
            setCountry(props.profileData.countryName)
          }
       
      }
    
      },[props.setCountryFromApi,props.profileData])
  
const [filteredData, setFilteredData] = useState([]);
let arr :any = [];

const updatePlanPrice = (id:any, newPriced:any, newCurrd:any,usdAmount:any,discountedPlanPrice:any,discountedUsdAmount:any,paypalPlanId:any) => {
console.log(discountedPlanPrice)
  setPlanDetails((prevPlans:any) => {
    if(prevPlans)
      {
        return prevPlans.map((plan:any )=> {
          if (plan.id === id) {
            return { ...plan, planPrice: newPriced, planCurr: newCurrd ,usdAmount:usdAmount,discountedPlanPrice:discountedPlanPrice,discountedUsdAmount,paypalPlanId:paypalPlanId};
          }
          return plan;
        });
      }
    
  });

};



  
function filterDataForCountry(data:any, countryIn:any) {

  data.forEach((item:any) => {
    const currencyData=item.currencyCode;
   
    if(currencyData)
      {    
        const jsonData =JSON.parse(currencyData)
        if(jsonData)
          {
            
            jsonData.forEach((value:any) => {
             console.log(value)
              if(value.country===countryIn.trim().toUpperCase())
              arr.push({id :item.id,amount:value.amount,curr:value.curr,country:value.country,usdAmount:value.usdAmount,discountPlanPrice:value.discountedAmount,discountedUsdAmount:value.discountedUsdAmount,planId:value.discount_p})
            });
          }
      }
     
  });

 
  setFilteredData(arr);
 
}

//

  useEffect(() => {
    props.getPlans();
    if(props.cancelPlanDetailsData && !localStorage.getItem('cancelPlan')){
      Swal.fire({
        title              : `${props.cancelPlanDetailsData.planCode}`,
        html               : cancelMessage,
        confirmButtonText  : "OK",
        confirmButtonColor : "green",
        showCloseButton    : true,
        width              : '600px'
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem('cancelPlan','success');
        }
      })
    }
  }, [props.cancelPlanDetailsData]);

  
  // useEffect(() => {
  //   if(props.masterPlanData){
  //     setPlanDetails(props.masterPlanData);
  //   }
  // }, [props.masterPlanData]);

  useEffect(() => {
    if (props.masterPlanData &&(props.profileData&& props.profileData.fullDiscount!=null)) {
      const filteredPlanData = props.masterPlanData.filter(
       ( plan:any )=> plan.planCode !== "100-PER-DISCOUNT"
      );
      setPlanDetails(filteredPlanData);
      if (country &&props.masterPlanData.length!=0){
      
        filterDataForCountry(props.masterPlanData,country)
      }
    } else if (props.masterPlanData && props.masterPlanData.length > 0)  {

       const lifeTimePlan=props.masterPlanData.filter((plans:any)=>plans.planCode==="100-PER-DISCOUNT")
      setPlanDetails(lifeTimePlan);
    }
  }, [props.masterPlanData, props.profileData]);
  useEffect(() => {
    if(props.profileData && props.profileData.verified){
      props.getAllPlan({userId:props.profileData.id});
      setUserDetails(props.profileData);
    }
  }, [props.profileData,props.cancelPlanDetailsData]);

  useEffect(() =>{
    if(props.allPlansDetail){
      setAllPlans(props.allPlansDetail)
    }
  },[props.allPlansDetail]);

  useEffect(() => {
    if (props.currentPlanDetailsData) {
      setCurrentPlan(props.currentPlanDetailsData);
    }
  }, [props.currentPlanDetailsData]);

  useEffect(()=>{
    if(allPlans && currentPlan){
      setUpcomingPlan(allPlans.find((item:any) => item.planCode !== 'FREE' && item.planCode !== currentPlan.planCode));
    }
  },[allPlans,currentPlan])

  const handleSubmit = (e: any) => {
    let planName   = e.target.name;
    planAmount     = e.target.value;
    planType       = e.target.dataset.plan_id;
    planId         = e.target.id;
    user_FName     = props.profileData.firstName;
    user_LName     = props.profileData.lastName;
    user_Email     = props.profileData.email;
    user_Id        = props.profileData.id;
    currPlan_token = currentPlan && currentPlan.payfastToken;
    planduration   = e.target.dataset.plan_duration;
    discUsdAmount=e.target.dataset.discount_usd_amount;
    paypalPlanId=e.target.dataset.paypalplanid;
    const paypalAmount= country==='India'?discUsdAmount:planAmount
    const currentDate = new Date();
    plan_frequency = (
        planduration === 'Daily'      ? '1' : 
        planduration === 'Weekly'     ? '2' : 
        planduration === 'Monthly'    ? '3' :
        planduration === 'Quarterly'  ? '4' :
        planduration === 'Biannually' ? '5' : 
        planduration === 'Yearly'    && '6' 
      );    
      const objJson={name_first:user_FName,name_last:user_LName,email_address:user_Email,m_payment_id:planId,custom_str1:props.profileData?.id,custom_str2:currPlan_token,update_time:currentDate.toISOString(),currency:'USD',amount_gross:paypalAmount,amount_fee:paypalAmount,amount_net:paypalAmount,item_name:planName,item_description:'Subscription'}
    if (planName !== 'Family Free' &&  planAmount > 0) {
      // Swal.fire({
      //   title              : `${planName} - R${planAmount}`,
      //   html               : paidPlanMessage,
      //   showDenyButton     : true,
      //   showCancelButton   : false,
      //   confirmButtonText  : "YES",
      //   confirmButtonColor : "green",
      //   denyButtonText     : `NO`,
      //   showCloseButton    : true,
      //   width              : '600px'
      // }).then((result) => {
      //   if (result.isConfirmed) {
        console.log(paypalPlanId) 
          navToPayment("/complete-payment",  
        
          { state: 
              { 
                amount     : country&&country==='India'?discUsdAmount:planAmount, 
                itemName   : planName, 
                id         : planId, 
                name_first : user_FName,
                name_last  : user_LName, 
                email_addr : user_Email, 
                usrId      : user_Id,
                planToken  : currPlan_token, 
                frequency  : plan_frequency,
                paypalPlanId:paypalPlanId,
                custom_id:JSON.stringify(objJson)
              } 
            }
          );
      //   }
      // });
    }else {
      if(allPlans.length === 0){
      Swal.fire({
        title              : `${planName} - R${planAmount}`,
        html               : planId==='1'?freePlanMessage:discountPlanMessage,
        showDenyButton     : true,
        showCancelButton   : false,
        confirmButtonText  : "YES",
        confirmButtonColor : "green",
        denyButtonText     : `NO`,
        showCloseButton    : true,
        width              : '650px'
      }).then((result) => {
        if (result.isConfirmed) {
         setFreePlan();
        }
      });
    }else{
      setFreePlan();
    }
    }
  };
 
  const setFreePlan = () => {
    if (planAmount < 1 ) {
      if(planId==='1' || planId===1)
      {
        userData.payment.boxPlansId     = planId;
        userData.payment.planCode       = "FREE";
        userData.payment.amount         = planAmount;
        userData.payment.subscriptionId = "free-plan";
        userData.payment.status         = "COMPLETED";
        userData.payment.planisActive   = 1;
      }

      // else{
      //   userData.payment.boxPlansId     = planId;
      //   userData.payment.planCode       = "100-PER-DISCOUNT";
      //   userData.payment.amount         = planAmount;
      //   userData.payment.subscriptionId = "discount-plan";
      //   userData.payment.status         = "COMPLETED";
      //   userData.payment.planisActive   = 1;
      // }

      props.selectFreePlan(userData);
      props.getPlans();
    }
  };
   
// DATE COMPONENT----------------------
  const DateInput = () =>{
    return(
      <div>
        <div>Enter your <span style={{color:"red"}}>Date of birth</span> for confirmation:</div>
        <input required={true} ref={datePickr}  type="date" max={new Date().toISOString().slice(0, 10)} className="form-control" style={{marginLeft:"18%",width:"64%"}}/>
      </div>
    )
  }

// DATE COMPONENT----------------------

async function preCnfrm () {
  const profileDetails = (localStorage.getItem('profileData') ? localStorage.getItem('profileData') : ''); 
  const userStoredDOB = (profileDetails ? JSON.parse(profileDetails).dob : '')
    return await new Promise(function (resolve, reject) {
        setTimeout(function () {
            if (datePickr.current?.value === '') {
                reject('Date of birth is required!');
            } else if(datePickr.current?.value !== userStoredDOB){
              reject('Enter valid DOB for your confirmation!');
            }else {
                resolve(true)
            }
        }, 1000)
    }).catch(err => {
        MySwal.showValidationMessage('<div style="color:red">'+err+'</div>')
        return false
    })
}

  const cancelPlan = (eventName:string,eventData:any) => {
    localStorage.removeItem('cancelPlan')
    let payLoad = {
        userId    : (eventData && eventData.userId ? eventData.userId : null),
        paymentId : (eventData && eventData.id     ? eventData.id     : null) 
    }
    MySwal.fire({
      title             : "<span style='color:black;font-weight:800;'>Do you want to cancel this  <small style='"+(eventName === 'current' ? 'color:red' : eventName === 'upcoming' ? 'color:green' : '')+"'>("+eventName+")</small> <br/><span style='"+(eventName === 'current' ? 'color:green' : eventName === 'upcoming' ? 'color:red' : '')+"'>"+(eventData && eventData.boxPlans && eventData.boxPlans.planType)+"</span> subscription?</span>",
      showDenyButton    : true,
      showCancelButton  : false,
      confirmButtonText : "YES",
      denyButtonText    : `NO`,
      confirmButtonColor: "green",
      showCloseButton   : true,
      width             : '600px',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        MySwal.fire({
          title               : "Do you really want to cancel this plan?",
          html                : <DateInput/>,
          showDenyButton      : true,
          showCancelButton    : false,
          confirmButtonText   : 'CONFIRM',
          denyButtonText      : `CANCEL`,
          showLoaderOnConfirm : true,
          confirmButtonColor  : "green",
          cancelButtonColor   : "red",
          width               : '600px',
          allowOutsideClick   : false,
          allowEscapeKey      : false,
          preConfirm          : preCnfrm,
        }).then((result) => {
          if (result.isConfirmed) {
            props.cancelPlan(payLoad);
          }
        })
      }
    });
  };



   //


  useEffect(()=>{
    if (props.masterPlanData && !(props.profileData &&props.profileData.fullDiscount)) {
      const filteredPlanData = props.masterPlanData.filter(
       ( plan:any )=> plan.planCode !== "100-PER-DISCOUNT"
      );
    
      setPlanDetails(filteredPlanData);
    }
    else if (props.masterPlanData && props.masterPlanData.length > 0)  {

      const lifeTimePlan=props.masterPlanData.filter((plans:any)=>plans.planCode==="100-PER-DISCOUNT")
     setPlanDetails(lifeTimePlan);
    }
    else
    {
      setPlanDetails(props.masterPlanData)
    }
  

  if(filteredData &&filteredData.length!=0)
    {
    
      filteredData.forEach((item:any )=> {
     
        updatePlanPrice(item.id, item.amount,item.curr,item.usdAmount,item.discountPlanPrice,item.discountedUsdAmount,item.planId);
      });
    }


//


},[  filteredData,props.masterPlanData])

// useEffect(()=>{
//   if(props.profileData&& props.profileData.fullDiscount){
//     planAmount=0;
//     planId=LIFE_TIME_PLAN;
//   }
//   if(props.currentPlanDetailsData && props.currentPlanDetailsData.status !== 'COMPLETED'&&props.profileData.fullDiscount){
//     userData.payment.boxPlansId     = planId;
//     userData.payment.planCode       = "100-PER-DISCOUNT";
//     userData.payment.amount         = planAmount;
//     userData.payment.subscriptionId = "discount-plan";
//     userData.payment.status         = "COMPLETED";
//     userData.payment.planisActive   = 1;
  
//     props.selectFreePlan(userData);
//     props.getPlans();
   
//   }
//  },[props.profileData,props.currentPlanDetailsData])
//---------------------------------------------------------------------------------//
return (
  <Layout> 
    <div className="welcome welcome-content-frame">
      <Container>
        <div className="row">
          <div className="col-md-12">
              <Row className="justify-content-between">
                <div className="col-md-auto col-sm-12 order-md-2 text-right d-flex justify-content-end">
                  <Link to={'#'} style={{textDecoration:"none"}} className="backBtn d-inline-flex" onClick={() => navToPayment(-1)}>Back</Link>
                </div>
                <div className="col-md-auto col-sm-12">
                  <h3>Hi {userDetails && userDetails.firstName ? userDetails.firstName.charAt(0).toUpperCase() + userDetails.firstName.slice(1) :""},</h3>
                </div>
              </Row>
             {props.profileData&& props.profileData.fullDiscount===true?<p style={{maxWidth:"none"}}>Welcome to your BeyondBox Family Plus membership.You now have access to all the features BeyondBox has to offer. </p>: <p style={{maxWidth:"none"}}>
                Please select from one of the plans detailed below. A third of all profits generated from our paid plans have been pledged toward helping existing charities and those in need. You can view our Foundation page to find out more about who we help.
                Thank you for your support. 
              </p>}
          </div>
        </div>
      </Container>
    </div>{/* Welcome content-frame// */}
    <div className="plans">
      <Container>
        {
          props.message && ( <div className={ props.message && props.message.status === "success" ? "text-success" : "text-danger" } > {props.message.data} </div> )
        }
        <div className="planList">
          {planDetails && planDetails.length !== 0 && planDetails.map((plan: any) => {
          
              const currentPlanClass   = (currentPlan && plan && plan.planCode === currentPlan.planCode ? "active-plan" : "");
              const upcomingPlanClass  = (upcomingPlan &&currentPlan &&  upcomingPlan.planCode !== 'FREE' && upcomingPlan.planCode !== currentPlan.planCode && upcomingPlan.planCode === plan.planCode)  ? 'upcoming-plan' : ''; 
              const planDisc           = (plan.planCode !== 'FREE' ? plan.marketerPlanDiscount : 0);
              const planActPrice       = (plan.planCode !== 'FREE' ? plan.planPrice : 0);
              const planPriceAfterDisc = (plan.planCode !== 'FREE' ? plan.discountedPlanPrice : 0);
              return (
                  // <div className={`text-warning ${currentPlanClass !== 'active-plan' && upcomingPlanClass !== 'upcoming-plan'? `mt-4` : `mb-4`}`} key={plan.id}> 
                  <div className={`text-warning planWraper ${currentPlanClass !== 'active-plan' && upcomingPlanClass !== 'upcoming-plan'? `mt-4` : `mb-4`}`} key={plan.id}> 
                  <div className="text-center">{currentPlanClass === 'active-plan' ? <div className="text-light bg-success rounded-top w-75" style={{marginLeft:"13%"}}>CURRENT PLAN</div> : upcomingPlanClass === 'upcoming-plan' ? <div className="text-light bg-danger rounded-top w-75" style={{marginLeft:"13%"}}>UPCOMING PLAN</div> : ''}</div>
                  <div className={`listItem ${currentPlanClass}`} style={currentPlanClass === 'active-plan' ? {} : {border: "2px solid grey",borderRadius:"20px"}} key={plan.id}>
                    <div className="listHead" style={currentPlanClass === 'active-plan' ? {background:"rgba(239, 161, 26, 0.13)",height: '265.53px'} : {height: "265.53px"}}>
                      <h3>{plan.planType}</h3>
                      <p><span>Membership</span><br/>
                          <small className="text-warning">{currentPlanClass && currentPlan.planCode !== 'FREE' && currentPlan.planCode !== '100-PER-DISCOUNT' ? `( Valid Upto: ${ new Date(currentPlan.planExpiryDate).toLocaleDateString()} )` : upcomingPlanClass ? `( Start from: ${new Date(upcomingPlan.planStartDate).toLocaleDateString()} )` : <span>&nbsp;</span>}</small>
                      </p>
                      <div className={`rate`} style={{paddingTop:'5px'}}>
                      { props.profileData&& props.profileData.fullDiscount===false&&  <Row>
                          <Col>
                                <Row>
                                    <Col className="planRateCol">
                                         <label>Discount:</label>
                                         <span className="rightTxt">{Number(planDisc)}<b className="text-warning">%</b></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="planRateCol">
                                        <label>Standard Price:</label>
                                        <span className="rightTxt"><b className="text-warning">{plan.planCurr}</b>{Number(planActPrice).toFixed(2)}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="planRateCol">
                                        <label>Reduced Price:</label>
                                        <span className="rightTxt"><b className="text-warning">{plan.planCurr}</b>{Number(planPriceAfterDisc).toFixed(2)}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                        <span>{plan.planDuration !== 'Unlimited' && plan.planDuration}</span>
                     </div>
                    </div>
                    <ul>
                      <li>{converter.toWords(plan.publicBoxes).charAt(0).toUpperCase() + converter.toWords(plan.publicBoxes).slice(1)} public box</li>
                      <li style={{lineHeight:"15px"}}>{(plan.privateBoxes !== 'Unlimited') ? converter.toWords(plan.privateBoxes).charAt(0).toUpperCase() + converter.toWords(plan.privateBoxes).slice(1) : plan.privateBoxes } private boxes
                        <br/><small style={{fontSize:"13px",color:"#E79E1F"}}>{plan.planCode === 'FREE' ? '( One recipient per box )' :'( Up to five recipients per box )'}</small>                      
                      </li>
                      <li style={{lineHeight:"15px"}}>{(plan.recievedBoxes !== 'Unlimited') ? converter.toWords(plan.recievedBoxes).charAt(0).toUpperCase() + converter.toWords(plan.recievedBoxes).slice(1) : plan.recievedBoxes } received boxes
                        <br/><small style={{fontSize:"13px",color:"#E79E1F"}}>{plan.planCode === 'FREE' ? '( Access received box content for 12 months )' : '( Unlimited access to received box content )' }</small>
                      </li>
                      {/* ${(Number(plan.planStorage) / 1000000000) * 1000} MB storage plan */}
                      {plan.planCode !== 'FREE' && <li>{`${(Number(plan.planStorage) / 1000000000)} GB storage plan`}</li>}
                      {plan.planCode === 'FREE' ? <li>Box Content – Text Messages</li> : <li>Box Content – Text Messages, Video, Audio, Documents, Images</li>}   
                      {plan.planCode !== "FREE" && <li>Cancel any time</li>}
                    </ul>
                    {currentPlanClass === 'active-plan' ? <div className="text-center text-success font-weight-bold">
                      <img src={selected} alt="" style={{marginTop:"-140px",opacity:"0.4",maxWidth: "50%"}}/></div> 
                      : 
                      upcomingPlanClass === 'upcoming-plan' ? <div className="text-center text-success font-weight-bold">
                        <img src={upcoming} alt="" style={{marginTop:"-150px",marginBottom:"0px",opacity:"0.4",maxWidth: "50%"}}/></div> 
                      : 
                      <></>
                    }
                    <div className="text-center mt-auto">
                      {
                      (
                        (allPlans && allPlans.length === 0 && currentPlan && currentPlan.planIsActive === 0)
                        ||
                        (allPlans && allPlans.length === 0 && plan && plan.planCode !== 'FREE' && plan.planCode !== '100-PER-DISCOUNT')
                        ||
                        (allPlans && allPlans.length !== 0 && currentPlanClass !== 'active-plan' && upcomingPlanClass !== 'upcoming-plan' && plan && currentPlan && plan.planCode !== currentPlan.planCode && plan.planCode !== 'FREE')
                      )?   
                          <Button className="chooseBtn" onClick={handleSubmit} name={plan.planType} value={Number(planPriceAfterDisc).toFixed(2)} id={plan.id} data-plan_id={plan.plan_id} data-plan_duration={plan.planDuration} data-discount_usd_amount={plan.discountedUsdAmount} data-paypalPlanId={plan.paypalPlanId}> SELECT </Button> 
                        :
                          currentPlanClass === 'active-plan' && currentPlan && currentPlan.planCode !== 'FREE' && currentPlan.planCode !== '100-PER-DISCOUNT'? <Button className={`chooseBtn text-danger`} onClick={()=>cancelPlan('current',currentPlan)}>CANCEL</Button> 
                        : 
                          upcomingPlanClass === 'upcoming-plan' && currentPlan && currentPlan.planCode !== 'FREE' ? <Button className={`chooseBtn text-danger`} onClick={()=>cancelPlan('upcoming',upcomingPlan)}>CANCEL</Button>
                        :  
                          <></>
                          // <Button className="chooseBtn" onClick={handleSubmit} name={plan.planType} value={plan.planPrice} id={plan.id} data-plan_id={plan.plan_id}>UPGRADE</Button>
                      }
                    </div>
                  </div>
                  </div>
                );
              })}
          </div>
        </Container>
      </div>
    </Layout>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  getPlans       : (data: any) => dispatch(getPlans(data)),
  getAllPlan     : (data: any) => dispatch(getAllPlan(data)),
  selectFreePlan : (data: any) => dispatch(selectFreePlan(data)),
  cancelPlan     : (data: any) => dispatch(cancelPlan(data)),
});

const mapStateToProps = (state: any) => {
  return {
    profileData            : (state.ProfilePageReducer && state.ProfilePageReducer.profileData)      ? state.ProfilePageReducer.profileData      : null,
    masterPlanData         : (state.PlansReducer       && state.PlansReducer.planDetailsData)        ? state.PlansReducer.planDetailsData        : null,
    currentPlanDetailsData : (state.PlansReducer       && state.PlansReducer.currentPlanDetailsData) ? state.PlansReducer.currentPlanDetailsData : null,
    allPlansDetail         : (state.PlansReducer       && state.PlansReducer.allPlanDetail)          ? state.PlansReducer.allPlanDetail          : null,
    cancelPlanDetailsData  : (state.PlansReducer       && state.PlansReducer.cancelPlanDetailsData)  ? state.PlansReducer.cancelPlanDetailsData  : null,    
    setCountryFromApi : state.CountryReducer && state.CountryReducer.setCountryFromApi ? state.CountryReducer.setCountryFromApi : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansWithpromoCode)
