import React from "react";
// import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";
import img from '../../assets/images/about-us.png';
import { Link } from "react-router-dom";

const AboutUs = (props: any) => {
  let userData                            = localStorage.getItem("profileData");
  return (
    <Layout>
      <div className="background-img">
       <div className="container-width mt-3 mb-3">
        <div className="row ">
          <div className="col-xl-6 text-center">
            {/* desktop only */}
            <img className="mt-85 mb-2 d-none d-xl-block " src={img} alt="about us" />
          </div>
          <div className="col-xl-6">
              <h2 className="heading-title text-center text-xl-start"> About Us </h2>
               {/* ipad/mobile only */}
              <img className="img-fluid my-3 mx-auto rounded-circle border border-primary d-block d-xl-none" src={img} alt="about us" />
              <div className="aboutUsMainContent text-white">
                <div className="mobile-card-box">
                  <p>At BeyondBox, we believe that true connections defy the constraints of time. Our digital sanctuary was born from a deeply personal journey of love, loss, and the enduring power of legacy. Founded after my wife and I experienced the profound loss of six cherished
                  family members, including both sets of parents, within three years, BeyondBox is our heartfelt response to the universal need for closure, peace, and the assurance that our feelings for those we love transcend our physical presence. BeyondBox empowers you to share your most treasured thoughts, guidance, love, and vital information with your loved ones, ensuring that your voice continues to offer comfort and direction in the wake
                  of your absence. It's about connecting beyond your time here, creating a space where every message of hope and every expression of love finds its way to those who matter most, even when you can no longer share those messages in person.
                  </p>
                </div>
                <div className="mobile-card-box">
                  <p>Choosing BeyondBox means embracing the opportunity to capture and immortalize your life's most significant moments and stories. It's a testament to the belief that although life is fleeting, love and wisdom endure. Our platform offers a seamless blend of technology
                      and compassion, ensuring that your legacy comprising love, life lessons, laughter and light is preserved for future generations to treasure. Our journey through grief to the creation of BeyondBox has instilled in us, a profound understanding of the importance of legacy and connection. We are committed to providing a service that not only safeguards your memories and wisdom but also fosters a supportive community that appreciates the beauty of shared memories and the comfort they will bring.
                  </p>
                </div>
                <div className="mobile-card-box">
                  <p>BeyondBox stands as a beacon of hope and resilience, dedicated to ensuring that your presence is felt, your stories are heard, and your love is known, forever. We invite you to join the BeyondBox family, a community united by the desire to make every moment
                  count and to ensure that our words and love echo through the ages. By choosing BeyondBox, you're not just preserving memories; you're declaring that your legacy will be one of enduring love and guidance. Thank you for considering BeyondBox as the guardian of your legacy. We are honored to accompany you on this journey, ensuring that your message, your essence, and your love continue to impact those you hold dear, connecting beyond your time here.
                  </p>
                </div>
              </div>
             {!userData && <div className="text-center">
                <Link to="/signup" className="button-secondary mt-3 mb-2 "><span> Join BeyondBox</span></Link>
          </div>}
          </div>
        </div>
      </div>
      </div>
    </Layout>
  );
};

export default AboutUs;