import {COUNTRY_DATA} from "../constant/index";

const initialState = {
    loading : false,
    countryData : null,
    stateData : null, 
    cityData: null,
    Location:null,
    stateLocation:null,
    countryLoction:null,
    setCountryFromApi:null,
}
const CountryReducer = (state = initialState, action) => {
  
    switch (action.type) {    

      case COUNTRY_DATA.SET_COUNTRIES_TO_STATE:
        return { ...state, countryData: action.data, loading: false, };
      
      case COUNTRY_DATA.SET_STATES_TO_STATE:
        return { ...state, stateData: action.data, loading: false, };
      
      case COUNTRY_DATA.SET_CITIES_TO_STATE:
        return { ...state, cityData: action.data, loading: false, };
      
      case COUNTRY_DATA.SET_COUNTRY_ID:
        return{ ...state, countryDataId:action.data, loading: false, }
      
      case COUNTRY_DATA.SET_LOCATION:
        return{ ...state, Location:action.data, loading: false, }    
      
      case COUNTRY_DATA.SET_STATE_LOCATION:
        return{ ...state, stateLocation:action.data, loading: false, }      
      
      case COUNTRY_DATA.SET_COUNTRY_LOCATION:
        return{ ...state, countryLocation:action.data, loading: false, }        
        case COUNTRY_DATA.SET_COUNTRY_FROM_API:
          return{...state, setCountryFromApi:action.payload, loading: false,}
      default:
        return state;
    }
  };

   export default CountryReducer;