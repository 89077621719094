import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams }                 from "react-router-dom";
import Layout                         from "../../layout/Layout";
import boxImg                         from "../../assets/images/no-image-available.png";
import { connect }                    from "react-redux";
import { BASE_URLS }                  from "../../redux/constant";
import { listBoxContent, getBoxById, clearBoxById } from "../../redux/actions/boxAction";
import { Container, Dropdown, DropdownButton, Button } from "react-bootstrap";
import DisplayModal from "./uploaded_folder/display-modal";
import pdf from '../../assets/images/pdf.png';
import docx from '../../assets/images/docx.png';
import file from '../../assets/images/file.jpg';
import Axios from 'axios';
import NotAuthorized from "../frontend/error_pages/not_authorised";
import { GoLocation } from "react-icons/go";
import quote                      from "../../assets/images/quote.png";
import { getLocation, getUserCountry, getUserState } from "../../redux/actions/countryApiAction";

var FileSaver = require('file-saver');

  const ViewDetailsList = (props: any) => {
  const redirectTo                    = useNavigate();  
  const { id }                        = useParams<any>();
  const [contentList, setContentList] = useState<any>([]);
  const [loader,      setLoader]      = useState<boolean>(false);
  const [docLoader,   setDocLoader]   = useState<boolean>(false);
  const [modalShow,   setModalShow]   = useState<boolean>(false);
  const [modalId  ,   setModalId]     = useState<number>(0);
  const handleClose                   = () => setModalShow(false);
  const handleShow                    = (e:any) => {setModalShow(true);setModalId(e)};
  const [fileId,      setFileId]      = useState(0);
  const [authFlag,    setAuthFlag]    = useState(false);
  const [usrLocation,                          setUsrLocation]                         = useState({city:'',state:'',country:''});
  const [readMoreContent_forQuote,             setReadMoreContent_forQuote]            = useState('');
  const [readMoreContent_forLife_Work,         setReadMoreContent_forLife_Work]        = useState('');
  const [readMoreContent_forWhat_I_Lived_For,  setReadMoreContent_forWhat_I_Lived_For] = useState('');
  let boxId:any = id;
 
  const readMore = (type:any,value:any) =>{
    if(type === 'quote'){ setReadMoreContent_forQuote(value); }
    if(type === 'life_work'){ setReadMoreContent_forLife_Work(value); }
    if(type === 'what_i_lived_for'){ setReadMoreContent_forWhat_I_Lived_For(value); }
  }

// READ LESS ACTION -----------------------  
  const readLess = (type:any) => {
    if(type === 'quote'){ setReadMoreContent_forQuote(''); }
    if(type === 'life_work'){ setReadMoreContent_forLife_Work(''); }
    if(type === 'what_i_lived_for'){ setReadMoreContent_forWhat_I_Lived_For(''); }
  }
  useEffect(() => {
    if (boxId) {
      setLoader(true);
      props.getBoxById({id:boxId})
    }
  }, [boxId]);

  useEffect(() => {
  
      if(props.boxByIdData&&props.boxByIdData.id)
      {
        setLoader(true);
        
        props.listBoxContent({ 'id': boxId ,'isPublic':props.boxByIdData.isPublic});
        props.clearBoxById()
      }     
  }, [props.boxByIdData]);
  useEffect(() => {
   
    if (props.listBoxDetails && boxId) {
      setLoader(false);
      setContentList(props.listBoxDetails);
    
    }
  }, [props.listBoxDetails]);

  const handleDownload = (filePath:any,fileId:number) =>{
    setFileId(fileId);
    setDocLoader(true);
    const userData     = JSON.parse(localStorage.getItem('userData')||'{}');
    const access_token = (userData && userData.Token)?userData.Token:'';
    if (access_token) {
      const config = { headers: {'Authorization': 'Bearer '+access_token} }
      let url      = `${BASE_URLS.BASE_URL}/user-box-files/resource/download?key=${filePath}`;
      Axios.get(url,config).then(function (response:any) {
        const link = document.createElement('a');
        link.href  = response.data;
        document.body.appendChild(link);
        link.click();
        setDocLoader(false);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  const extractVideoId = (url:any) => {
    let videoId               = '';
    const videoUrlPattern     = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
    const shortenedUrlPattern = /youtu\.be\/([a-zA-Z0-9_-]+)/;
    if (videoUrlPattern.test(url)) {
      videoId = url.match(videoUrlPattern)[1];// Extract video ID from standard video URL
    } else if (shortenedUrlPattern.test(url)) {
      videoId = url.match(shortenedUrlPattern)[1];// Extract video ID from shortened URL
    }
    return videoId;
  };

  useEffect(() =>{
    if(props.message && props.message.status == 'error' && props.message.page == 'box-preview'){
      if(props.message.data == 'Unauthorized'){
        setAuthFlag(true);
      }else{
        setAuthFlag(false);
      }
    }else{
      setAuthFlag(false);
    }
  },[props.message])

  useEffect(() =>{
    if(props.listBoxDetails){
      if(props.listBoxDetails[0] && props.listBoxDetails[0].UserboxesBTuser && props.listBoxDetails[0].UserboxesBTuser.id){
        let userId = props.listBoxDetails[0].UserboxesBTuser.id;
        props.getLocation(userId);//for city
        props.getUserState(userId);//for state
        props.getUserCountry(userId);//for country
      }
      setLoader(false);
    }
  },[props.listBoxDetails])
  
  useEffect(() =>{
    
    let country = '';
    let state   = '';
    let city    = '';

    if(props.getUserCountryLocation && props.getUserCountryLocation.name){
      country = (props.getUserCountryLocation.name ? props.getUserCountryLocation.name : 'N/A')
      if(props.getUserStateLocation && props.getUserStateLocation.name){
        state = (props.getUserStateLocation.name ? props.getUserStateLocation.name : 'N/A')
        if(props.getUserLocation && props.getUserLocation.name){
          city = (props.getUserLocation.name ? props.getUserLocation.name :'N/A')
        }
      }
    }
    setUsrLocation({...usrLocation,country,state,city});
  },[props.getUserLocation,props.getUserStateLocation,props.getUserCountryLocation])
//------------------------------------------------------------------------------------------------//  

  return (
    <Layout>
      <div className="boxes createBox box-details">
        {authFlag ?
        <NotAuthorized/>
        :
        <Container>
          <div className={loader ? `text-center boxListFolder` : `boxListFolder`}>
            <div className="userDetails">
              <div className="img">
                <img src={props.boxByIdData && props.boxByIdData.coverPicture?`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${props.boxByIdData.coverPicture}`:boxImg} alt="" />
              </div>
              <div className="userName">{props.boxByIdData && props.boxByIdData.boxName ? props.boxByIdData.boxName.toUpperCase() : ''}</div>
            </div>
            {loader ? 
              <div className="lds-dual-ring">
                <span className="text-warning">Loading... please wait!</span>
              </div>
            :
            <>
            <div className="row justify-content-end floatingBtnBlock">
              <div className="col-auto">
                {/* <div className="fliterBtn">
                  <DropdownButton id="dropdown-basic-button" title="Filter" align="end" >
                    <Dropdown.Item href="#/action-1"><img src={alphabetIcon} alt="" /> Alphabetically</Dropdown.Item>
                    <Dropdown.Item href="#/action-2"><img src={dateIcon} alt="" /> Date Created</Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><img src={editedIcon} alt="" /> Last edited</Dropdown.Item>
                  </DropdownButton>
                </div> */}
                <Link to={'#'} style={{textDecoration:"none"}}>
                  <Button className="backBtn" onClick={() => redirectTo(-1)}>Back</Button>
                </Link>
              </div>
            </div>
            <div>
              {props.listBoxDetails && props.listBoxDetails.map((boxItemContent:any)=>{
         
              return(
               
                props.boxByIdData.isPublic === 1&&<div>
                    {/* public box layout start*/}
                  <div className="p-3" key={boxItemContent.id}>
                    {boxItemContent.UserboxesBTuser &&
                      <div>
                        <div className="row">
                          <div className="col-md-9 pt-3">
                            { boxItemContent.quote &&
                              <div>
                                <label className="text-warning">Quote &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</label>
                                {readMoreContent_forQuote ?  
                                  <span className="text-light d-block "><p>{readMoreContent_forQuote} {boxItemContent.quote.length > 50 && <small className="text-warning pointer read_more_or_less max-width-50 text-end"  onClick={() => readLess('quote')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Read Less</u> </small> } </p> </span>
                                :
                                  <span className="text-light d-block "> {boxItemContent.quote.length > 50 ? boxItemContent.quote.substring(0,50)+'...' : boxItemContent.quote} {boxItemContent.quote.length > 50 && <small className="text-warning pointer read_more_or_less max-width-50 text-end" onClick={() => readMore('quote',boxItemContent.quote)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u></small> }</span>
                                }
                              </div>
                            }
                      
                            {boxItemContent.life_work  &&
                              <div>
                                <label className="text-warning">Life's work &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</label>
                                {readMoreContent_forLife_Work ?  
                                  <span className="text-light d-block"><p>{readMoreContent_forLife_Work} {boxItemContent.quote.length > 50 && <small className="text-warning pointer read_more_or_less max-width-50 text-end" onClick={() => readLess('life_work')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read Less</u></small> } </p> </span>
                                :
                                  <span className="text-light d-block"> {boxItemContent.life_work.length > 50 ? boxItemContent.life_work.substring(0,50)+'...' : boxItemContent.life_work} {boxItemContent.life_work.length > 50 && <small className="text-warning pointer read_more_or_less max-width-50 text-end" onClick={() => readMore('life_work',boxItemContent.life_work)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u></small> }</span>
                                }
                              </div>
                            }
                                  
                            {boxItemContent.what_i_lived_for &&
                              <div>
                                <label className="text-warning">What I lived for :&nbsp;&nbsp;</label>
                                {readMoreContent_forWhat_I_Lived_For ?  
                                  <span className="text-light d-block"><p>{readMoreContent_forWhat_I_Lived_For} {boxItemContent.quote.length > 50 && <small className="text-warning pointer read_more_or_less max-width-50 text-end" onClick={() => readLess('what_i_lived_for')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read Less</u></small> } </p> </span>
                                :
                                  <span className="text-light d-block"> {boxItemContent.what_i_lived_for.length > 50 ? boxItemContent.what_i_lived_for.substring(0,50)+'...' : boxItemContent.what_i_lived_for} {boxItemContent.what_i_lived_for.length > 50 && <small className="text-warning pointer read_more_or_less max-width-50 text-end" onClick={() => readMore('what_i_lived_for',boxItemContent.what_i_lived_for)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u></small> }</span>
                                }
                              </div>
                            }
                          </div>
                          <div className="col-md-3 text-light mt-3 mt-md-0">
                            <GoLocation className="text-warning text-right ms-auto"/>
                            <div>
                              <label className="text-warning">City&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</label>
                              <span title={usrLocation.city}>{usrLocation.city ? (usrLocation.city.length > 20 ? usrLocation.city.substring(0,20) + '...' : usrLocation.city) : <small className="text-warning">Loading..<span className="blink">.</span></small> }</span>
                            </div>
                            <div>
                              <label className="text-warning">State&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</label>
                              <span title={usrLocation.state}>{usrLocation.state ? (usrLocation.state.length > 20 ? usrLocation.state.substring(0,20) + '...' : usrLocation.state) : <small className="text-warning">Loading..<span className="blink">.</span></small> }</span>
                            </div>
                            <div>
                              <label className="text-warning">Country :&nbsp;&nbsp;</label>
                              <span title={usrLocation.country}>{usrLocation.country ? (usrLocation.country.length > 20 ? usrLocation.country.substring(0,20) + '...' : usrLocation.country) : <small className="text-warning">Loading..<span className="blink">.</span></small> }</span>
                            </div>
                          </div>
                        </div>
                        {boxItemContent && boxItemContent.userBoxFiles && boxItemContent.userBoxFiles.length !== 0 && boxItemContent.userBoxFiles.map((item:any) =>{
                          return(
                            <div className="publicMessage"><br/>
                              <img src={quote} alt="" style={{marginBottom:"30px"}}/> 
                                <p className="text-light" style={{opacity:"0.65",marginLeft:'0.5%', display: 'inline',wordBreak:'break-word'}}>{item.boxMessage && item.boxMessage}</p>
                              <img src={quote} alt="" style={{transform:"scaleX(-1)",marginBottom:"30px",marginLeft:'0.5%'}}/>
                            </div>
                          )})
                        }
                      </div>
                    }
                  </div>
                </div>
              )
            })}
            
          </div>
    {/* public box layout ends */}
            <div className="docBox">
              {contentList && contentList.length !== 0 ?
                <>
                {contentList.map((item: any, index: any) => {
                  if ( contentList && contentList[index] && contentList[index].boxFileType == "Images" ) {
                    return (
                      <>
                        <DisplayModal show={modalId == item.id && modalId !== 0 ? modalShow:false} onHide={handleClose} name={item && item.boxFileTitle ? item.boxFileTitle : '' }>
                          <img width="100%" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.boxFileName}`} alt="" />
                        </DisplayModal>
                        <div className="rowBox">
                          <div className="rowWrap">
                            <img className='rounded pointer' onClick={() => handleShow(item.id)} width="400" height="350px" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.boxFileName}`} alt="" />
                          </div>
                        </div>
                      </>
                    );
                  } else if ( contentList &&  contentList[index] && contentList[index].boxFileType == "Audio" ) {
                    return (
                      <div className="rowBox">
                        <div className="rowWrap">
                          <audio controls className="audio" style={{width:'400px'}}>
                            {/* {!item.links ? */}
                              <source src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.boxFileName}`} />
                            {/* :
                              <source src={`${item.links}`} />
                            } */}
                          </audio>
                        </div>
                      </div>
                    );
                  } else if ( contentList &&  contentList[index] && contentList[index].boxFileType == "Messages") {
                    
                    return (
                      props.boxByIdData.isPublic === 1 ? <div className="rowBox rounded msgWrap" >
                        <div className="rowWrap">
                          <div className='messageBoxDetails' key={item.id}>
                            <div className="userDetailsBlock">
                              <div className="title">
                                <span className='text-light'>{item.boxFileTitle}</span>
                              </div>
                            </div>
                            <div className="messageTextView">
                              <p className="text-light" style={item.boxMessage && item.boxMessage.length > 50 ? {  height: "100px", overflowY: "scroll", wordBreak:'break-word'} : {  wordBreak:'break-word'}}>{item.boxMessage}</p>
                            </div>
                          </div>
                        </div>
                      </div> :
                        <div className="rowBox rounded msgWrap" >
                          <div className="rowWrap">
                            <div className='messageBoxDetails' key={item.id}>
                              <div className="userDetailsBlock">
                                <div className="title">
                                  <span className='text-light'>{item.boxFileTitle}</span>
                                </div>
                              </div>
                              <div className="messageTextView">
                                <p className="text-light" style={item.boxMessage && item.boxMessage.length > 50 ? {  height: "100px", overflowY: "scroll",wordBreak:'break-word' } : { wordBreak:'break-word'}}>{item.boxMessage}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                    
                    
                  } else if (contentList && contentList[index] && contentList[index].boxFileType == "Documents" ) {
                    var fileExt = item.boxFileName.split('.').pop();
                    return (
                      <div className="rowBox">
                        <div className="rowWrap">
                          {
                            docLoader && fileId === item.id ?
                            <span className='text-warning text-center lds-dual-ring mb-4' style={{fontSize:"14px",marginLeft: "11%",borderLeft: "1px solid",borderRight: "1px solid",borderRadius: "14px"}}>File downloading<br/>please wait...</span>
                          :
                          <img width={320} height={250} src={fileExt === 'pdf' ? pdf : fileExt === 'docx' ? docx : file } alt="" onClick={()=>handleDownload(item.boxFileName,item.id)} style={{cursor:'pointer'}}/>
                          }
                        </div>
                      </div>
                    );
                  } else if ( contentList && contentList[index] && contentList[index].boxFileType == "Videos" ) {
                    // var URL = item && item.links && item.links
                    // var vID = extractVideoId(URL);
                    return (
                      <div className="rowBox">
                        <div className="rowWrap">
                          {(item.boxFileName !== null && item.links === null) ?
                            <video width="400" height="350"  controls={true} key={item.id} className="rounded">
                              <source src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.boxFileName}` }/>
                            </video>
                          :
                          <></>
                            // <iframe width="350" height="350" src={ vID ? `https://www.youtube.com/embed/${vID}` : item.links} allowFullScreen title='video'></iframe>
                          }
                        </div>
                      </div>
                    );
                  }
                })}
                </>
               :
               <p className='text-warning text-center'></p> 
              }
            </div>
            </>
            }
          </div>
        </Container>
        }
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  listBoxContent : (data: any) => dispatch(listBoxContent(data)),
  getBoxById     : (data: any) => dispatch(getBoxById(data)),
  clearBoxById :()=>dispatch(clearBoxById()),
   getLocation         : (data: any) => dispatch(getLocation(data)),
  getUserState        : (data: any) => dispatch(getUserState(data)),
  getUserCountry      : (data: any) => dispatch(getUserCountry(data)),
});

const mapStateToProps = (state: any) => {
  return {
    listBoxDetails : state.BoxReducer && state.BoxReducer.listBoxDetails ? state.BoxReducer.listBoxDetails : null,
    boxByIdData    : state.BoxReducer && state.BoxReducer.boxByIdData    ? state.BoxReducer.boxByIdData    : null,
    message        : (state.CommonMessageReducer && state.CommonMessageReducer.message)   ? state.CommonMessageReducer.message   : null,
    getUserLocation         : state.CountryReducer && state.CountryReducer.Location        ? state.CountryReducer.Location        : null,
    getUserStateLocation    : state.CountryReducer && state.CountryReducer.stateLocation   ? state.CountryReducer.stateLocation   : null,
    getUserCountryLocation  : state.CountryReducer && state.CountryReducer.countryLocation ? state.CountryReducer.countryLocation : null,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewDetailsList);
