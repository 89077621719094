// import { useEffect } from 'react';
// import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
// import { CreateSubscriptionActions, PayPalButtonsComponentOptions } from "@paypal/paypal-js/types/components/buttons";
// import { selectPaidPlan } from '../../redux/actions/plansAction';
// import { connect } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import { SUBSCRIPTION_PLAN } from '../../redux/constant';

// const PaypalWindow = (props: any) => {

//   const [{ isPending }] = usePayPalScriptReducer();

//   let userData = { "boxPlansId": 0, "orderId": "", "subscriptionId": "" }

//   const handleApprove = async(data:any, actions:any) => {
//     // userData.orderId = data && data.orderID;
//     //   userData.subscriptionId = data && data.subscriptionID;
//     //   setPlan();
//       return actions.order.capture().then(function(details:any) {
//         console.log(details)
// //         userData.orderId = details && details.orderID;
// //         userData.subscriptionId = details && details.id;
// //         setPlan();
// //  console.log(details)
//       });
//   };

//   const paypalbuttonTransactionProps: PayPalButtonsComponentOptions = {
//     style: { layout: "vertical" },

//     createOrder: async()=>{
//       return fetch("https://api-m.paypal.com/v2/checkout/orders", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({

//           "purchase_units": [
//             {
//               "reference_id": "defasdsdsdsdult",
//               "amount": {
//             "currency_code": 'USD', // Make sure the currency code is correct
      
//                 // "currency_code": paypalSubscrition.currencyCode.currencyCode,
//                 "value": '2.00', // Replace '0.00' with a default value if paypalSubscrition.recurring_amount is not available
//                 "breakdown": {
//                   "item_total": {
//             "currency_code": 'USD', // Make sure the currency code is correct
      
//                     // "currency_code": paypalSubscrition.currencyCode.currencyCode,
//                     "value":  '2.00', // Same as above
//                   }
//                 }
//               },
//               "custom_id": JSON.stringify({}) ,
//               "items": [   {
//                 "name": "T-Shirt",
//                 "description": "Green XL",
//                 "quantity": "1",
//                 "unit_amount": {
//                     "currency_code": "USD",
//                     "value": "200.00"
//                 },
//                 "email":"sudhanshu.evontech@gmail.com",
//                 "userId:":'1689',
//                 "billingDate":"2023-06-08"

//             }]
//             }
//           ],

//           // "purchase_units": [
//           //   {
//           //     "reference_id": "d9f80740-38f0-11e8-b467-0ed5f89f718b",
//           //     "amount": {
//           //       "currency_code": "USD",
//           //       "value": "100.00"
//           //     }
//           //   }
//           // ],
//           // cart: [
//           //   {
//           //     sku: "YOUR_PRODUCT_STOCK_KEEPING_UNIT",
//           //     quantity: "YOUR_PRODUCT_QUANTITY",
//           //   },
//           // ]
//         })
//       })
//       .then((response) => response.json())
//       .then((order) => order.id);
//     },
//     onApprove: async (data: any, actions: any) => {
//       userData.orderId = data && data.orderID;
//       userData.subscriptionId = data && data.subscriptionID;
//       setPlan();
//     },

//     createSubscription: async(data:any, actions:any)=> {
//       return actions.subscription.create({
//          //plan_id: '123',
//          'plan_id': 'P-2UF78835G6983425GLSM44MA'
//       });
//     }
//   }


//   const setPlan = () => {
//     if (props.planId) {
//       userData.boxPlansId = props.planId;
//       props.selectPaidPlan(userData)
//     }
//   }

//   const navToCreateBox = useNavigate();
//   const navToBox = () => {
//     navToCreateBox('/homepage')
//   }


//   useEffect(() => {
//     if (props.selectPaidPlanDetailsData) {
//       let paymentStatus = props.selectPaidPlanDetailsData ? props.selectPaidPlanDetailsData.status : 0;
//       let planAmt = props.selectPaidPlanDetailsData ? props.selectPaidPlanDetailsData.amount : 0;
//       if (paymentStatus && (Number(props.amount) === Number(planAmt))) {
//         Swal.fire({
//           title: 'Payment successful',
//           showDenyButton: false,
//           showCancelButton: false,
//           confirmButtonText: 'OK',
//           icon: 'success',
//           confirmButtonColor: 'green'
//         }).then((result) => {
//           if (result.isConfirmed) {
//             props.clearPropsData();
//             navToBox();
//           }
//         })
//       } else {
//         navToCreateBox('/complete-payment', { state: { amount: props.amount, type: props.type, id: props.id } })
//       }
//     }
//   }, [props.selectPaidPlanDetailsData])

//   //------------------------------------------------------------//

//   return (
//     <>
//       {isPending ? <h2>Load Smart Payment Button...</h2> : null}
//       {/* <PayPalButtons {...paypalbuttonTransactionProps} /> */}
//       {/* <PayPalButtons   createOrder={(data, actions) => {
//           return 
//           actions.order.create({
//             purchase_units: [{
//               amount: {
//                 value: '100.00' // The total amount to be paid
//               }
//             }]
//           });
//         }}/> */}
//         <PayPalButtons createOrder={(data:any,actions:any)=>{
//           return  actions.order.create({
//             purchase_units: [{
//               amount: {
//                 value: '400.00' // The total amount to be paid
//               }
//             }]
//           });
          
//         }
  
//         }
//         onApprove={handleApprove}
//         ></PayPalButtons>
//     </>
//   );
// }

// const mapDispatchToProps = (dispatch: any) => ({
//   selectPaidPlan: (data: any) => dispatch(selectPaidPlan(data)),
//   clearPropsData: () => dispatch({ type: SUBSCRIPTION_PLAN.SET_SELECTED_PAID_SUBSCRIPTION_PLAN_TO_STATE, data: null })
// });

// const mapStateToProps = (state: any) => {
//   return {
//     selectPaidPlanDetailsData: (state.PlansReducer && state.PlansReducer.selectPaidPlanDetailsData) ? state.PlansReducer.selectPaidPlanDetailsData : null,
//     message: (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(PaypalWindow);
import { useEffect } from 'react';
// import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
 import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { PayPalButtonsComponentOptions } from "@paypal/paypal-js/types/components/buttons";
import { selectPaidPlan } from '../../redux/actions/plansAction';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SUBSCRIPTION_PLAN } from '../../redux/constant';

const PaypalWindow = (props: any) => {

  
  const [{isPending }] = usePayPalScriptReducer();
  let userData         = { "boxplansid": 0, "orderid": "", "subscriptionid": "" ,"custom_id":""}
  
  const paypalbuttonTransactionProps: any = {
    style: { layout: "vertical" },
       
   
  
    createSubscription: async(data:any, actions:any)=> {
      return actions.subscription.create({
        plan_id:props.paypalPlanId,
        
      });
    },
    
    onApprove: async (data: any, actions: any) => {
    
      userData.orderid        = data && data.orderID;
      userData.subscriptionid = data && data.subscriptionID;
      
      setPlan();
    },
  };

  const setPlan = () => {
    if (props.planId) {
      userData.boxplansid = props.planId;
      userData.custom_id=props.custom_id;
      props.selectPaidPlan(userData) 
    }
  }

  const navToCreateBox = useNavigate();
  const navToBox=()=>{
    navToCreateBox('/homepage')
  }
 
  
  useEffect(() => {
    if (props.selectPaidPlanDetailsData) {
      let paymentStatus = props.selectPaidPlanDetailsData ? props.selectPaidPlanDetailsData.status : 0;
      let planAmt       = props.selectPaidPlanDetailsData ? props.selectPaidPlanDetailsData.amount : 0;
      if(paymentStatus && (Number(props.amount) === Number(planAmt))){
        Swal.fire({
          title             : 'Payment successful',
          showDenyButton    : false,
          showCancelButton  : false,
          confirmButtonText : 'OK',
          icon              : 'success',
          confirmButtonColor: 'green'
        }).then((result) => {
          if (result.isConfirmed) {
            props.clearPropsData();
            navToBox();
          } 
        })
      }else {
        navToCreateBox('/complete-payment', { state: { amount: props.amount, type: props.type, id: props.id } })
      }
    }
  }, [props.selectPaidPlanDetailsData])

//------------------------------------------------------------//

  return (
    <>
      {isPending ? <h2>Load Smart Payment Button...</h2> : null}
      <PayPalButtons {...paypalbuttonTransactionProps} />
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  selectPaidPlan: (data: any) => dispatch(selectPaidPlan(data)),
  clearPropsData: ()          => dispatch({type:SUBSCRIPTION_PLAN.SET_SELECTED_PAID_SUBSCRIPTION_PLAN_TO_STATE,data:null})
});

const mapStateToProps = (state: any) => {
  return {
    selectPaidPlanDetailsData: (state.PlansReducer && state.PlansReducer.selectPaidPlanDetailsData) ? state.PlansReducer.selectPaidPlanDetailsData : null,
    message: (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalWindow);