import { ADMIN_BLOG } from "../../constant";

export const addAdminBlog = data => {
  
    return {
      type:ADMIN_BLOG.ADD_ADMIN_BLOG,
      payload: data,
    };
  };
  export const getBlogFilterList = data => {
    return{
      type:ADMIN_BLOG.GET_BLOG_FILTER_LIST,
      payload:data,
    }
  }
  export const getBlogList = data => {
    return{
      type:ADMIN_BLOG.GET_BLOG_LIST,
      payload:data,
    }
  }
  
  export const deleteAdminBlog= data => {
    return{
      type:ADMIN_BLOG.DELETE_ADMIN_BLOG,
      payload:data,
    }
  }
  
  export const getBlogDetailById = data => {
    console.log("data",data)
    return{
      type:ADMIN_BLOG.GET_BLOG_DETAIL_BY_ID,
      payload:data,
    }
  }

export const updateAdminBlog = data => {
  return{
    type:ADMIN_BLOG.UPDATE_ADMIN_BLOG,
    payload:data,
  }
}